import { createContext, useContext, useMemo, useState } from "react"

export const TransactionsDataContext = createContext({})

const TransactionsDataProvider = props => {
    const [refresh, setRefresh] = useState({
        INCOMES: null,
        EXPENSES: null
    })

    function refreshData(key) {
        setRefresh(prev => ({
            ...prev,
            [key]: new Date().getTime()
        }))
    }

    function getRefreshed(key) {
        return refresh[key]
    }

    const exportedData = useMemo(() => {
        return {
            refreshData,
            getRefreshed
        }
    }, [refresh])

    return <TransactionsDataContext.Provider value={exportedData} {...props} />
}

export const useTransactionsDataContext = () => useContext(TransactionsDataContext)

export default TransactionsDataProvider