import { useState } from "react"

function useCategoryChange() {
    const [selectedId, setSelectedId] = useState(0)

    function handleCategoryChange(e) {
        const { value } = e.target

        setSelectedId(value)
    }

    function getSelectedCategoryId() {
        return selectedId
    }

    return {
        handleCategoryChange,
        getSelectedCategoryId
    }
}

export default useCategoryChange