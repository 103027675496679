//hooks
import { Tooltip } from "@mui/material"
import useFetchCoords from "../hooks/useFetchCoords"

//providers
import { useFilterDataContext } from "../providers/filter"

//components
import Activities from "./Activities"
import Leaflet from "./Leaflet"

//images
import leftArrowIcon from "assets/images/track/icons/left-arrow.svg"

function Map() {
  const filter = useFilterDataContext()
  const { getUser, setUser } = filter

  const { getData, loading } = useFetchCoords()

  function isMapWide() {
    if (getUser()) {
      return false
    }

    return true
  }

  return (
    <div className={`content-holder ${isMapWide() ? "wide-map" : ""}`}>
      <aside>
        <div className="head">
          <Tooltip title="Към всички служители">
            <button
              className="btn-secondary"
              onClick={() => {
                setUser(null)
              }}
            >
              <img src={leftArrowIcon} alt="" />
            </button>
          </Tooltip>
          <b>Хронология на {getUser()?.name}</b>
        </div>
        <Activities data={getData()} loading={loading} />
      </aside>
      <main>
        <Leaflet data={getData()} loading={loading} />
      </main>
    </div>
  )
}

export default Map
