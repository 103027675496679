import { createPortal } from "react-dom"
import { useEffect, useRef } from "react"

//images
import attachIcon from "assets/images/schedule/icons/attach.svg"
import uploadIcon from "assets/images/schedule/icons/upload.svg"
import micIcon from "assets/images/schedule/icons/mic-white.svg"

//libraries
import { Tooltip } from "@mui/material"

//hooks
import useUploadImages from "../hooks/useUploadImages"
import useUploadAudioRecord from "../hooks/useUploadAudioRecord"

//components
import Gallery from "components/app/components/Gallery"
import AudioRecord from "./AudioRecord"
import Remove from "components/app/components/Remove"
import ViewAudioRecords from "./ViewAudioRecords"

//helpers
import { getUrlData } from "helpers/url"

//providers
import { useAppContext } from "providers/app"

function UploadedFiles({ viewFromHistory }) {
  const app = useAppContext()

  const galleryRef = useRef()
  const removeImageRef = useRef()
  const removeRecordRef = useRef()
  const viewAudioRecordsRef = useRef()

  const imageIndex = getUrlData().remove_image_index
  const recordIndex = getUrlData().remove_record_index

  const {
    uploadImages,
    getFirstImages,
    removeImage,
    getImages,
    prepareRemoveImage,
  } = useUploadImages()
  const {
    uploadRecords,
    getFirstRecords,
    removeRecord,
    getRecords,
    prepareRemoveRecord,
  } = useUploadAudioRecord()

  useEffect(() => {
    if (imageIndex) removeImageRef.current.open()
  }, [imageIndex])

  useEffect(() => {
    if (recordIndex) removeRecordRef.current.open()
  }, [recordIndex])

  function openGallery(index) {
    galleryRef.current.open(index)
  }

  function handleRemoveImage() {
    removeImage(imageIndex)

    removeImageRef.current.close()

    app.handleMessage().success().show("Изображението е премахнато успешно!")
  }

  function handleRemoveRecord() {
    removeRecord(recordIndex)
    removeRecordRef.current.close()

    app.handleMessage().success().show("Записът е премахнат успешно!")
  }

  function viewMoreAudioRecords() {
    viewAudioRecordsRef.current.open()
  }

  return (
    <div className="uploaded-files-holder">
      {createPortal(
        <>
          <ViewAudioRecords
            audioRecordsList={getRecords}
            prepareRemoveRecord={prepareRemoveRecord}
            ref={viewAudioRecordsRef}
            viewFromHistory={viewFromHistory}
          />
          <Remove
            ref={removeImageRef}
            elementsContent={{
              heading: "Изтриване на изображението",
            }}
            accept={handleRemoveImage}
          />
          <Remove
            ref={removeRecordRef}
            elementsContent={{
              heading: "Изтриване на аудио записът",
            }}
            accept={handleRemoveRecord}
          />
          <Gallery
            ref={galleryRef}
            getImages={getImages}
            prepareRemoveImage={prepareRemoveImage}
            viewFromHistory={viewFromHistory}
          />
        </>,
        document.body
      )}

      <h6>
        <img src={attachIcon} alt="" />
        Файлове (опционално)
      </h6>
      {!viewFromHistory ? (
        <div className="select row">
          <Tooltip title="Прикачване на изображения">
            <button>
              <img src={uploadIcon} alt="" />
              <input
                type="file"
                multiple
                accept="image/png, image/jpg, image/jpeg"
                title=""
                onChange={uploadImages}
                name="images"
              />
            </button>
          </Tooltip>
          <Tooltip title="Прикачване на аудо записи">
            <button>
              <img src={micIcon} alt="" />
              <input
                type="file"
                multiple
                accept="audio/mp3"
                title=""
                onChange={uploadRecords}
                name="records"
              />
            </button>
          </Tooltip>
        </div>
      ) : (
        <></>
      )}
      {getFirstImages().length ? (
        <ul className="images">
          {getFirstImages().map((img, i) => (
            <li
              key={img.id || `index-${i}`}
            >
              <img
                src={img.url}
                alt=""
                onClick={() => {
                  openGallery(i)
                }}
              />
              {i === 1 && getImages().length > 2 ? (
                <div className="more">+{getImages().length - 1}</div>
              ) : !viewFromHistory ? (
                <Tooltip title="Премахване">
                  <span
                    className="remove"
                    onClick={() => {
                      prepareRemoveImage(i)
                    }}
                  ></span>
                </Tooltip>
              ) : (
                <></>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <></>
      )}
      {getFirstRecords().length ? (
        <>
          <ul className="audio-records">
            {getFirstRecords().map((rec, i) => (
              <AudioRecord
                key={rec.id || `index-${i}`}
                url={rec.url}
                removeRecord={() => {
                  prepareRemoveRecord(i)
                }}
                viewFromHistory={viewFromHistory}
              />
            ))}
          </ul>
          {getRecords().length > 2 ? (
            <span className="link" onClick={viewMoreAudioRecords}>
              Виж всички
            </span>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      {!getFirstImages().length && !getFirstRecords().length ? (
        <p>Прикачете изображения или гласови съобщения</p>
      ) : (
        <></>
      )}
    </div>
  )
}

export default UploadedFiles
