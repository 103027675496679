import { Tooltip } from "@mui/material";
import { ENDPOINTS } from "constants/endpoints";
import { forwardRef } from "react";
import loadingImage from "assets/images/app/loader.svg"
import useEditFinance from "../hooks/useEditFinance";
import editIcon from "assets/images/schedule/icons/edit.svg"

function TableRow({ tr, validation, handleChange, prepareRemove, loadingFinance, tableInput, switchInput }) {
    return (

        tr.option ? <td className="finance-table"> {tr.option === 'delete' ? <span
            className="remove"
            onClick={() => {
                tr.type === 'revenue'
                    ? prepareRemove(
                        tr.id,
                        "INCOMES",
                        `${ENDPOINTS.SHARED.REMOVE_INCOME}?revenue_id=${tr.id}`,
                        "Премахване на прихода"
                    )
                    : prepareRemove(
                        tr.id,
                        "EXPENSES",
                        `${ENDPOINTS.SHARED.REMOVE_EXPENSE}?expenses_id=${tr.id}`,
                        "Премахване на разхода"
                    )
            }}
        ></span> : <span></span>}</td> :
            tr.task ? <td key={`task-${tr.id}`} style={{ width: '20%' }} className={`${tr.id && validation.includes(tr.id.toString()) ? 'validate' : ''} finance-table finance-table-body`}>
                <Tooltip title={tr.task}>{
                    loadingFinance == tr.id && tr.id != null ?
                        <img src={loadingImage} height={'8%'} width={'8%'} alt="" /> :
                        <input
                            onBlur={handleChange}
                            data-finance-type={tr.type}
                            data-finance-name={'description'}
                            data-finance-id={tr.id}
                            name="task"
                            className={`finance-table-body search-by-name finance-table-description-input`}
                            defaultValue={tr.task} />}
                </Tooltip>
            </td> :
                <Tooltip title={tr.value}>
                    <td key={tr.id} className={`${tr.id && validation.includes(tr.id.toString()) ? 'validate' : ''} finance-table`} style={{ width: '4%', backgroundColor: tr.type == 'revenue' ? "#D0F0E6" : "#F7DCDF", textAlign: 'right' }}>
                        {tr.id ?
                            loadingFinance == tr.id ?
                                <img src={loadingImage} height={'40%'} width={'40%'} alt="" /> :
                                <input
                                data-input-index={tr.index ? tr.index : null}
                                ref={tr.index ? el => tableInput.current[tr.index] = el : null}
                                onKeyDown={switchInput}
                                    readOnly={tr.disabled ? true : false}
                                    onBlur={handleChange} style={{ color: 'black' }}
                                    data-finance-type={tr.type} data-finance-id={tr.id}
                                    data-finance-name={'finance'}
                                    name="finance"
                                    className={`finance-table-body search-by-name finance-table-input`}
                                    defaultValue={(tr.value).toFixed(2)} /> :
                            <input className="finance-table-body search-by-name finance-table-input" disabled />}
                    </td>
                </Tooltip>

    )


}

export default forwardRef(TableRow)