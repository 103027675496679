//hooks
import useFetch from "hooks/useFetch"

//providers
import { useAppContext } from "providers/app"
import { useTransactionsDataContext } from "../providers/transactions"

function useRemoveTransactions(endpoint, removeRef, type) {
    const app = useAppContext()
    const transactionsData = useTransactionsDataContext()

    const { fetch, loading } = useFetch()

    function handleRemove() {
        fetch({ endpoint, method: 'delete' })
            .then(() => {
                removeRef.current.close()
                app.handleMessage().success().show(`${type === 'EXPENSES' ? 'Разхода' : 'Прихода'} премахнат успешно!`)
                transactionsData.refreshData(type)
            })
    }

    return {
        handleRemove,
        loading
    }
}

export default useRemoveTransactions