import { useEffect, useRef, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useFormDataContext } from "../providers/form"

function useFetchSettlements() {
    const formData = useFormDataContext()
    const { fetch, loading } = useFetch()

    const [settlements, setSettlements] = useState([])

    const searchTimeoutRef = useRef(null)

    useEffect(() => {
        fetchTasks()
    }, [formData.getFieldForClient("clientLocation")])

    function fetchTasks() {
        clearTimeout(searchTimeoutRef.current)
        searchTimeoutRef.current = setTimeout(() => {
            if (formData.getFieldForClient("clientLocation").length < 2) {
                setSettlements([])

                return
            }

            fetch({
                endpoint: `${ENDPOINTS.ADMIN.GET_SETTLEMENTS}?name=${formData.getFieldForClient("clientLocation")}`,
                method: "get",
            })
                .then((res) => {
                    setSettlements(res.data)
                })
        }, 300)
    }

    function getSettlements() {
        return settlements

        //Тестови данни
        // return [{
        //     id: 1,
        //     name: "София",
        // }, {
        //     id: 2,
        //     name: "Пловдив",
        // }, {
        //     id: 3,
        //     name: "Варна",
        // }].filter(s => s.name.includes(formData.getFieldForClient('clientLocation')))
    }

    function inputSettlement(settlementName) {
        formData.setFieldsFormats("clientLocation", settlementName)
        formData.setFieldsFormats("clientLocationId", null)
    }

    function isSingleSettlementInListSameAsInput() {
        if (getSettlements.length > 1) return false

        const lastSettlement = getSettlements().at(-1)

        if (removeEmptySpaces(lastSettlement.name) === removeEmptySpaces(formData.getFieldForClient("clientLocation"))) {
            return true
        }
    }

    function removeEmptySpaces(string) {
        return string.replace(/\s/g, '')
    }

    return {
        getSettlements,
        loading,
        inputSettlement,
        isSingleSettlementInListSameAsInput
    }
}

export default useFetchSettlements