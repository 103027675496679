//plugins
import { MapContainer } from "react-leaflet/MapContainer"
import { TileLayer } from "react-leaflet/TileLayer"
import { Popup, Marker, Tooltip } from "react-leaflet"
import moment from "moment-timezone"
import L from "leaflet"

//styles
import "leaflet/dist/leaflet.css"

//providers
import { useFilterDataContext } from "../providers/filter"

//components
import Loader from "components/app/components/Loader"

//images
import workerIcon from "assets/images/track/icons/worker.svg"

//helpers
import { parseName } from "helpers/substring"

function GetIcon() {
  return L.icon({
    iconUrl: workerIcon,
    iconSize: 30,
  })
}

function Leaflet({ data, loading }) {
  const filter = useFilterDataContext()
  const { getHour, setUser, getUser } = filter

  function getCenter() {
    return [getHour()?.latitude || 43.081306, getHour()?.longitude || 25.633174]
  }

  function getPosition(userData) {
    if (getUser() || !userData?.coordinates[0]) {
      return getCenter()
    }

    return [
      userData?.coordinates[0]?.latitude,
      userData?.coordinates[0]?.longitude,
    ]
  }

  function getBounds() {
    if (!getData().length) return [getCenter()]

    let bounds = []

    if (!getUser()) {
      data.map((d) => {
        bounds = [...bounds, getPosition(d)]
      })

      return bounds
    }

    return [getPosition(getUser())]
  }

  function getParsedHourAndDAte(uglyHour) {
    const prittyData = moment(uglyHour, "YYYY-MM-DDTHH:mm:ss").format(
      "DD.MM.YYYY"
    )
    const prittyHour = moment(uglyHour, "YYYY-MM-DDTHH:mm:ss").format("HH:mm")

    return {
      prittyHour,
      prittyData,
    }
  }

  if (loading) {
    return <Loader />
  }

  function shourMarkerBeVisible(user) {
    if (!getUser()) {
      return true
    }

    if (user.coordinates.length) {
      return true
    }

    return false
  }

  function getData() {
    return data.filter((d) => {
      if (shourMarkerBeVisible(d)) {
        return true
      }
    })
  }

  return (
    <MapContainer
      style={{
        height: "100%",
        width: "100%",
      }}
      bounds={getBounds()}
      key={[data, getBounds()]}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {getData().map((u) => (
        <Marker
          key={`user-${u.id}`}
          position={getPosition(u)}
          icon={GetIcon()}
          eventHandlers={{
            click: () => {
              setUser(u)
            },
          }}
        >
          <Popup direction="bottom" offset={[0, 20]} opacity={1} permanent>
            <div>
              <b>{u.name}</b> беше тук в{" "}
              <b>{getParsedHourAndDAte(getHour()?.updated_at).prittyHour} ч.</b>{" "}
              на{" "}
              <b>{getParsedHourAndDAte(getHour()?.updated_at).prittyData} г.</b>
            </div>
            <div>
              Тел. номер за контакти:{" "}
              <b>
                <a
                  href={`tel:${u.number}`}
                  style={{ textDecoration: "underline" }}
                  title="Набиране"
                >
                  {u.number}
                </a>
              </b>
            </div>
          </Popup>
          <Tooltip direction="bottom" offset={[0, 18]} opacity={1} permanent>
            {parseName(u.name)}
          </Tooltip>
        </Marker>
      ))}
    </MapContainer>
  )
}

export default Leaflet
