import { Link } from "react-router-dom"

//images
import phoneIcon from "assets/images/users/icons/phone.svg"

//libraries
import { Tooltip } from "@mui/material"

//components
import Options from "./Options"

//providers
import { useAppContext } from "providers/app"
import { useAuthDataContext } from "providers/auth"

//helpers
import { getNameFirstDigits } from "../helpers/name"

function User({ data }) {
  const app = useAppContext()
  const auth = useAuthDataContext()

  const { name, number, role_id, id, deleted_at } = data
  const role = app.handleRoles().getRoleByRoleId(role_id)

  return (
    <li>
      {auth.handleAuth().isHeadAdmin() ? (
        <Options userId={id} deactivated={deleted_at} />
      ) : (
        <></>
      )}
      <Tooltip title="Преглед на профила">
        <Link to={`/user/${id}`}>
          <div className="picture">{getNameFirstDigits(name)}</div>
          <b>{name}</b>
          <p>{role?.name}</p>
          <div className="phone">
            <img src={phoneIcon} alt="" />
            {number}
          </div>
        </Link>
      </Tooltip>
    </li>
  )
}

export default User
