import { createContext, useContext, useState } from "react"

//helpers
import { getUrlData } from "helpers/url"

//hooks
import useFetchUsers from "../hooks/useFetchUsers"
import useFetchDeactivatedUsers from "../hooks/useFetchDeactivatedUsers"

export const UsersDataContext = createContext({})

const UsersDataProvider = props => {
    const [search, setSearch] = useState(getUrlData('name')?.name?.[0] || '')

    const { getUsers, loading, refetchData } = useFetchUsers(search)
    const { loadingDeactivatedUsers, getDeactivatedUsers, refetchDeactivatedUsersData } = useFetchDeactivatedUsers(search)

    function handleSearch() {
        function get() {
            return search
        }
        function set(e) {
            const { value } = e.target
            setSearch(value)
        }

        return {
            get,
            set
        }
    }

    function refetchAllData() {
        refetchData()
        refetchDeactivatedUsersData()
    }

    const exportedData = {
        getUsers,
        loading,
        refetchData,
        refetchAllData,
        handleSearch,
        loadingDeactivatedUsers,
        getDeactivatedUsers
    }

    return <UsersDataContext.Provider value={exportedData} {...props} />
}

export const useUsersDataContext = () => useContext(UsersDataContext)

export default UsersDataProvider