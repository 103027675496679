
import { createContext, useContext, useEffect, useState } from 'react'

//libraries
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'

//providers
import { useAuthDataContext } from './auth'
import { prod } from 'helpers/mode'

export const SocketContext = createContext({})

const { REACT_APP_URL_DEV, REACT_APP_URL_PROD, REACT_APP_WH_HOST_PROD, REACT_APP_WH_HOST_DEV } = process.env
const channes = {
    private: {},
    presence: {},
}
const SocketProvider = props => {
    const auth = useAuthDataContext()
    const token = auth.handleAuth().get().token()

    const [listener, setListener] = useState(null)

    useEffect(() => {
        if (token && !listener) {
            const echo = new Echo({
                Pusher: Pusher,
                broadcaster: 'pusher',
                cluster: 'mt1',
                key: "uslugivt",
                forceTLS: true,
                authEndpoint: 'https://uslugivt-service.danielstandard.com/api/broadcasting/auth',
                httpHost: 'service.docuela.com',
                wsHost: 'service.docuela.com',
                wsPort: 8888,
                wssPort: 8888,
                disableStats: true,
                auth: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            })

            setListener({
                echo
            })
        }
    }, [token])

    return <SocketContext.Provider value={{
        listener,
        channes
    }} {...props} />
}

export const useSocketContext = () => useContext(SocketContext)

export default SocketProvider