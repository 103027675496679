import { useState } from "react"

//images
import replyIcon from "assets/images/schedule/icons/reply.svg"

//libraries
import { Tooltip } from "@mui/material"

//hooks
import useCreateComment from "../hooks/useCreatComment"

function CommentReplyField({ taskId, commentId }) {
  const [showField, setShowField] = useState(false)
  const [replyFieldValue, setReplyFieldValue] = useState("")
  const { createReply, loading } = useCreateComment(setReplyFieldValue)

  function toggleField() {
    if (!showField) setReplyFieldValue("")

    setShowField(!showField)
  }

  function handleChange(e) {
    const { value } = e.target

    setReplyFieldValue(value)
  }

  return (
    <div className="reply">
      {showField ? (
        <form className="reply-fields" onSubmit={createReply}>
          <input type="hidden" name="oder_id" value={taskId} />
          <input type="hidden" name="comment_id" value={commentId} />
          <div className="col">
            <textarea
              name="reply"
              placeholder="Въведи отговор"
              autoFocus={true}
              value={replyFieldValue}
              onChange={handleChange}
            ></textarea>
            <div className="buttons">
              <button
                className="btn-secondary"
                onClick={toggleField}
                type="button"
              >
                Отказ
              </button>
              <Tooltip
                title={
                  !replyFieldValue.length
                    ? "Необходимо е да въведете коментар"
                    : ""
                }
              >
                <div>
                  <button
                    className={`btn-primary 
                    ${!replyFieldValue.length ? "disabled" : ""}
                    ${loading ? "loading" : ""}
                    `}
                  >
                    Изпрати
                  </button>
                </div>
              </Tooltip>
            </div>
          </div>
        </form>
      ) : (
        <button className="btn-reply" onClick={toggleField}>
          <img src={replyIcon} alt="" />
          Отговори
        </button>
      )}
    </div>
  )
}

export default CommentReplyField
