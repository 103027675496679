export function getGETParam(params = {}) {
    let url = ""

    Object.values(params).map((p, i) => {
        if (p?.length) {
            url += `${url.length === 0 ? "?" : "&"}${Object.keys(params)[i]}=${p}`
        }
    })

    return url
}

export function getUrlData() {
    const data = {}
    const href = decodeURIComponent(window.location.href).replace(/\+/g, '%20')

    const hashes = href.slice(href.indexOf('?') + 1).split('&')
    let hash

    for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i]?.split('=')
        if (hash[1]) {
            data[hash[0]] = hash[1].split(',').map(h => h)
        }
    }

    return data
}

export function getUrlExeptPassedParam(param) {
    let params = ""

    Object.keys(getUrlData()).map((u, i) => {
        if (!u.includes(param)) {
            params += `${params.length === 0 ? "?" : "&"}${u}=${Object.values(
                getUrlData()
            )[i].toString()}`
        }
    })

    return params
}