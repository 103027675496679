export const ENDPOINTS = {
    AUTH: {
        LOGIN: 'auth/admin/login',
        GET_USER: 'auth/get-user',
        SET_USER: 'auth/update-user',
        CHANGE_PASSWORD: 'auth/change-password'
    },
    GET_ROLES: 'get-roles',
    ADMIN: {
        GET_PROTOCOL: 'download-protocol',
        GET_WORKER: 'get-worker',
        GET_USER: 'get-user',
        GET_WORKERS: 'get-workers',
        CREATE_ADVANCE: 'draw-advance',
        GET_WORKER_BALANCE: 'salary-for-user',
        GET_WORKER_PAYMENTS: 'advances-for-user',
        REMOVE_WORKER_PAYMENT: 'delete-advance',
        UPDATE_WORKER_SALARY: 'update-salary',
        CREATE_TASK: 'store-work-order',
        UPDATE_TASK: 'update-work-order',
        REMOVE_TASK: 'delete-work-order',
        GET_TASK: 'get-work-order',
        REMOVE_USER: 'delete-user',
        CREATE_USER: 'create-user',
        UPDATE_USER: 'update-user',
        GET_WORKER_TRANSACTIONS: 'worker-reference',
        GET_WORKER_TRANSACTIONS_COLUMN: 'worker-reference-column',
        GET_FINANCE_TRANSACTIONS: 'finance-reference',
        GET_WORKERS_WITH_TASKS: 'task-table',
        GET_CLIENTS: 'get-clients',
        GET_ALL_CLIENTS: 'get-all-clients',
        REMOVE_IMAGE: 'delete-photo',
        REMOVE_AUDIO: 'delete-audio',
        GET_FREE_WORKERS: 'free-workers',
        CREATE_EXTRA_WORKER: 'store-pt-worker',
        REMOVE_EXTRA_WORKER: 'delete-pt-worker',
        GET_SETTLEMENTS: 'get-settlements',
        IMPORT_FILE: 'import-xml',
        CREATE_INVOICE: 'invoice/create',
        GET_CLIENT: 'get-client',
        FILL_INVOICE: 'invoice/fill',
        GET_ORDERS: 'orders/get',
        MAKE_INVOICE: 'invoice/make',
        AWAITING_COUNT: 'awaiting-count',
        EDIT_FINANCE: 'finance/edit',

    },
    SHARED: {
        GET_SCHEDULE: 'task-schedule',
        GET_CATEGORIES: 'get-categories',        
        GET_SUBCATEGORIES: 'get-favours-by-cat',
        GET_SUBCATEGORY_FIELDS: 'get-service-notes',
        GET_USER_ACTIVE_TASKS: 'get-active-tasks',
        GET_EXPENSE: 'get-exp',
        GET_EXPENSE_CATEGORIES: 'get-types',
        GET_INCOMES: 'get-rev',
        GET_INCOME_CATEGORIES: 'get-rev-types',
        REMOVE_INCOME: 'delete-rev',
        REMOVE_EXPENSE: 'delete-exp',
        CREATE_INCOME: 'store-rev',
        CREATE_EXPENSE: 'store-exp',
        GET_COMMENTS: 'get-comment',
        GET_STATUSES: 'get-status',
        CREATE_COMMENT: 'comment',
        CREATE_REPLY: 'store-reply',
        REMOVE_COMMENT: 'delete-comment',
        REMOVE_REPLY: 'delete-reply',
        GET_NOTIFICATIONS: 'notifications',
        GET_UNSEEN_NOTIFICATIONS: 'notification-alert',
        GET_PAYMENT_METHODS: 'get-payment-methods',
        GET_FINANCES: 'finance/get',
        GET_FINANCES_TABLE: 'finance/table', 
    }
}