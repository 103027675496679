//images
import descriptionIcon from "assets/images/schedule/icons/description.svg"

//providers
import { useFormDataContext } from "../providers/form"

function ExtraDescription({ handleValidations, viewFromHistory }) {
  const formData = useFormDataContext()
  const fieldValue = formData.getFieldForClient("description")

  function handleChange(e) {
    const { name, value } = e.target

    formData.setFieldsFormats(name, value)
  }

  function validate(fieldName) {
    return handleValidations().validate(fieldName)
  }

  return (
    <div className="extra-description-holder">
      <h6>
        <img src={descriptionIcon} alt="" />
        Допълнително описание (опционално)
      </h6>
      <textarea
        placeholder="Въведи допълнително описание..."
        value={fieldValue}
        onChange={handleChange}
        name="description"
        className={viewFromHistory ? "cursor-default" : ""}
        readOnly={viewFromHistory}
      ></textarea>
      {validate("description").message ? (
        <p className="error-text">{validate("description").message}</p>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ExtraDescription
