import { useNavigate } from "react-router-dom"

//hooks
import usePrevParams from "hooks/usePrevParams"
import useFetch from "hooks/useFetch"

//providers
import { useFormDataContext } from "../providers/form"

//constants
import { ENDPOINTS } from "constants/endpoints"

function useUploadImages() {
    const navigate = useNavigate()

    const { getPrevParams } = usePrevParams()
    const { fetch } = useFetch()

    const formData = useFormDataContext()
    const imagesList = formData.getFieldForClient("images")

    function uploadImages(e) {
        const { name, files } = e.target

        const images = [...getImages()]

        for (let i = 0; i < files.length; i++) {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(files[i])
            fileReader.onload = () => {
                const url = fileReader.result
                images.push({
                    id: null,
                    url,
                })

                formData.setFieldsFormats(name, images)
            }
        }
    }

    function getImages() {
        return imagesList
    }

    function getFirstImages() {
        const images = []
        if (getImages()[0]) images.push(getImages()[0])
        if (getImages()[1]) images.push(getImages()[1])

        return images
    }

    function prepareRemoveImage(index) {
        navigate({
            search: `${getPrevParams()}remove_image_index=${index}`,
        })
    }

    function removeImage(index) {
        const imageId = getImages()[index].id || null

        if (imageId) {
            fetch({ endpoint: `${ENDPOINTS.ADMIN.REMOVE_IMAGE}/${imageId}`, method: 'delete' })
        }
        const updatedImagesList = getImages().filter((_, i) => i !== Number(index))

        formData.setFieldsFormats('images', updatedImagesList)
    }

    return {
        uploadImages,
        getFirstImages,
        removeImage,
        prepareRemoveImage,
        getImages
    }
}

export default useUploadImages