import { Routes, Route, useLocation } from 'react-router-dom'
import { createPortal } from 'react-dom'
import { Fragment, useEffect, useRef } from 'react'

//components (partials)
import SuccessMessage from './components/app/components/SuccessMessage'
import ErrorMessage from './components/app/components/ErrorMessage'
import Header from 'components/app/components/Header'
import AddEdit from 'components/schedule/components/AddEdit'

//component (pages)
import Login from 'components/login'
import Schedule from 'components/schedule'
import SplashScreen from 'components/app/SplashScreen'
import Users from 'components/users'
import User from 'components/users/User'
import Statistics from 'components/statistics'
import History from 'components/history'
import TrackUsers from 'components/track'
import Finances from 'components/finances'
import Invoices from 'components/invoices'

//styles
import 'assets/scss/app.scss'

//providers
import { useAuthDataContext } from 'providers/auth'
import { useAppContext } from 'providers/app'
import ScheduleDataProvider from 'components/schedule/providers/schedule'
import UsersDataProvider from 'components/users/providers/users'
import FormDataProvider from 'components/schedule/providers/form'
import FilterDataProvider from 'components/track/providers/filter'
import FinanceDataProvider from 'components/finances/providers/finances'

//helpers
import { getUrlData } from 'helpers/url'
import { useSocketNotification } from "helpers/socket"

function App() {
  const addEditRef = useRef()

  const location = useLocation()

  const auth = useAuthDataContext()
  const app = useAppContext()
  const isLogged = auth.handleAuth().get().isLogged()

  useSocketNotification({
    channel: `App.Models.User`,
    channelId: auth.handleAuth().get().user()?.id,
    callback: (payload) => {
      app.handleNewNotification().set(payload)
    },
  })

  function getRoutes() {
    return <>
      <Routes>
        {!isLogged ?
          <Route
            path="*"
            exact
            element={<Login />}
          />
          :
          <Fragment>
            <Route
              path="/"
              exact
              element={<ScheduleDataProvider><Schedule /></ScheduleDataProvider>}
            />
            <Route
              path="/history"
              exact
              element={<History />}
            />
            <Route
              path="/finances"
              exact
              element={<FinanceDataProvider><Finances /></FinanceDataProvider>}
            />
             <Route
              path="/invoices"
              exact
              element={<FinanceDataProvider><Invoices /></FinanceDataProvider>} //Промени FinanceDataProvider на InvoiceDataProvider като го добавиш
            />
            <Route
              path="/users"
              exact
              element={<UsersDataProvider><Users /></UsersDataProvider>}
            />
            <Route
              path="/user/:userId"
              exact
              element={<UsersDataProvider><User /></UsersDataProvider>}
            />
            <Route
              path="/statistics"
              exact
              element={<Statistics />}
            />
            <Route
              path="/track-users"
              exact
              element={<FilterDataProvider><TrackUsers /></FilterDataProvider>}
            />
          </Fragment>
        }
      </Routes>
    </>
  }

  function getMessages() {
    const messages = <>
      <SuccessMessage />
      <ErrorMessage />
    </>

    return createPortal(messages, document.body)
  }

  const taskId = getUrlData().edit_task_id
  useEffect(() => {
    if (location.pathname === '/') return
    if (taskId && addEditRef.current) addEditRef.current.open()
  }, [taskId])

  function getTaskForm() {
    if (location.pathname !== '/') {
      return (
        <FormDataProvider>
          <AddEdit ref={addEditRef} />
        </FormDataProvider>
      )
    }

    return <></>
  }

  return (
    app.initialLoading ?
      <SplashScreen />
      :
      <>
        {isLogged ?
          <Header />
          :
          <>
          </>
        }
        {getRoutes()}
        {getMessages()}
        {getTaskForm()}
      </>
  )
}

export default App
