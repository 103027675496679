import { useState } from "react"

function useMenu() {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    function handleOpen(e) {
        setAnchorEl(e.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    return {
        open,
        anchorEl,
        handleOpen,
        handleClose
    }
}

export default useMenu