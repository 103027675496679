import { useRef } from "react"
import { createPortal } from "react-dom"

//hooks
import useFetchWorkersSchedule from "../hooks/useFetchWorkersSchedule"

//images
import addIcon from "assets/images/schedule/icons/add.svg"

//components
import Loader from "components/app/components/Loader"
import AddHour from "./AddHour"

//constants
import { CATEGORIES_LIST } from "constants/categories"
import {
  CLIENT_DATE_SHORTEN_AND_HOUR_FORMAT,
  SERVER_DATE_AND_HOUR_FORMAT,
  SERVER_HOUR_FORMAT,
} from "constants/formats"

//libraries
import moment from "moment-timezone"

//helpers
import { parseName } from "helpers/substring"
import { isEndHourToday, isStartHourToday } from "../helpers/hours"

function WorkersSchedule({ getSelectedDate, handleClose }) {
  const addHourRef = useRef()

  const { getSchedule, loading } = useFetchWorkersSchedule(getSelectedDate)

  function formatDateToHour(date) {
    return moment(date, SERVER_DATE_AND_HOUR_FORMAT).format("HH:mm") || ""
  }

  function formatDateToDateAndHour(date) {
    return (
      moment(date, SERVER_DATE_AND_HOUR_FORMAT).format(
        CLIENT_DATE_SHORTEN_AND_HOUR_FORMAT
      ) || ""
    )
  }

  const START_WORK_HOUR = moment("08:00", SERVER_HOUR_FORMAT)
  const END_WORK_HOUR = moment("20:00", SERVER_HOUR_FORMAT)

  function getBusyHeight(startHour, endHour) {
    const defaultHeight = 65
    if (!startHour || !endHour) {
      return defaultHeight
    }

    let a, b

    if (
      !isEndHourToday(endHour, getSelectedDate()) &&
      !isStartHourToday(startHour, getSelectedDate())
    ) {
      a = START_WORK_HOUR
      b = END_WORK_HOUR
    } else {
      a =
        isEndHourToday(endHour, getSelectedDate()) &&
        !isStartHourToday(startHour, getSelectedDate())
          ? START_WORK_HOUR
          : moment(formatDateToHour(startHour), SERVER_HOUR_FORMAT)
      b = isEndHourToday(endHour, getSelectedDate())
        ? moment(formatDateToHour(endHour), SERVER_HOUR_FORMAT)
        : END_WORK_HOUR
    }

    return (
      defaultHeight *
        (Math.floor(b.diff(a, "minutes") / 30) +
          1 +
          (b.diff(a, "minutes") % 30 !== 0 ? 1 : 0)) -
        (isEndHourToday(endHour, getSelectedDate()) ? defaultHeight : 0) ||
      defaultHeight
    )
  }

  function getBusyNegativeMarginTop(startHour) {
    if (!isStartHourToday(startHour, getSelectedDate())) {
      const a = START_WORK_HOUR
      const b = formatDateToHour(startHour)

      return (
        Math.floor(moment(b, SERVER_HOUR_FORMAT).diff(a, "minutes") / 30) * -66
      )
    }

    return 0
  }

  function areHoursOnDifferentDays(startHour, endHour) {
    if (
      moment(endHour, SERVER_DATE_AND_HOUR_FORMAT).diff(startHour, "hours") > 8
    ) {
      return true
    }

    return false
  }

  function getHoursAsArray() {
    return Object.keys(getSchedule()?.[0]?.tasks || {}) || []
  }

  function addHour(hourIndex, user) {
    user["shortName"] = parseName(user.name)

    addHourRef.current.open({
      user,
      startHour: getHoursAsArray()[hourIndex],
      selectedDate: getSelectedDate(),
    })
  }

  return (
    <>
      {createPortal(
        <AddHour ref={addHourRef} added={handleClose} />,
        document.body
      )}

      {loading ? (
        <Loader />
      ) : (
        <ul className="weekly-tasks-list">
          <li className="small">
            <div className="worker small"></div>
            <ul className="small">
              {getHoursAsArray().map((t, i) => (
                <li key={`index-${i}`}>{t}</li>
              ))}
            </ul>
          </li>
          {getSchedule().map((s, i) => (
            <li key={`index-${i}`}>
              <div className="worker">
                <b>
                  {parseName(s.user.name)}
                  {s.user.work_order_count ? (
                    <span>{s.user.work_order_count}</span>
                  ) : (
                    <></>
                  )}
                </b>
              </div>
              <ul className="tasks">
                {Object.values(s.tasks).map((l, i) => (
                  <li className="day" key={`${l.id}-${i}`}>
                    {l.id ? (
                      <div
                        className="busy"
                        style={{
                          height: getBusyHeight(
                            Object.values(s.tasks)[i]?.start,
                            l.end
                          ),
                          marginTop: getBusyNegativeMarginTop(l.start),
                        }}
                      >
                        <div className="top">
                          <span>
                            {areHoursOnDifferentDays(l.start, l.end)
                              ? formatDateToDateAndHour(l.start)
                              : `${formatDateToHour(l.start)}ч.`}
                            {l.end
                              ? ` - ${
                                  areHoursOnDifferentDays(l.start, l.end)
                                    ? formatDateToDateAndHour(l.end)
                                    : `${formatDateToHour(l.end)}ч.`
                                }`
                              : ""}
                          </span>
                          <img
                            src={CATEGORIES_LIST[l.category.tag].icon}
                            alt=""
                          />
                        </div>
                        <p>{l.favours.service_name}</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <button
                      className="add"
                      onClick={() => {
                        addHour(i, s.user)
                      }}
                    >
                      <img src={addIcon} alt="" />
                      Добави час
                    </button>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default WorkersSchedule
