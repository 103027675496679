//hooks
import useFetch from "hooks/useFetch"

//providers
import { useAppContext } from "providers/app"

function useEditAddress(successCB) {
    const app = useAppContext()

    const { fetch, loading, validations, handleValidations } = useFetch()
    function handleSubmit(e) {
        e.preventDefault()
        const data = new FormData(e.target)

        fetch({ endpoint: `update-location`, method: 'post', data })
            .then(() => {
                successCB()
                app.handleMessage().success().show('Адреса беше редактиран успешно')
            })
    }

    return {
        handleSubmit,
        loading,
        handleValidations,
        handleValidations
    }
}

export default useEditAddress