//constants
import { CATEGORIES_ARR } from "./categories"

export const CATEGORY_COLORS = {
    [CATEGORIES_ARR[0]]: {
        TIME: '#A9D1CD',
        ICON: '#C5E1DE',
        BACKGROUND: '#E1F1F1'
    },
    [CATEGORIES_ARR[1]]: {
        TIME: '#D7C4A2',
        ICON: '#EFDEC0',
        BACKGROUND: '#FDF1DC'
    },
    [CATEGORIES_ARR[2]]: {
        TIME: '#9DBEDD',
        ICON: '#BAD5EE',
        BACKGROUND: '#DBEEFF'
    },
    [CATEGORIES_ARR[3]]: {
        TIME: '#DAAAAA',
        ICON: '#F4C8C8',
        BACKGROUND: '#FDDCDC'
    }
}