import { forwardRef, useImperativeHandle, useState } from "react"

//hooks
import useOpenWindow from "hooks/useOpenWindow"

//providers
import { useAppContext } from "providers/app"
import { useFormDataContext } from "../providers/form"

//constants
import { CATEGORY_COLORS } from "constants/colors"

//libraries
import { Tooltip } from "@mui/material"

function SelectCategoryAndService(_, ref) {
  const app = useAppContext()
  const formData = useFormDataContext()
  const initialCategoryState = formData.getSelectedCategory() || null
  const initialSubcategoryState = formData.getSelectedSubcategory() || null

  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedSubcategory, setSelectedSubcategory] = useState(null)
  const [step, setStep] = useState(1)

  const { handleClose, handleOpen, isOpened } = useOpenWindow()

  useImperativeHandle(ref, () => ({
    open: openWindow,
    close: closeWindow,
  }))

  function openWindow() {
    handleOpen()
    initData()
  }

  function closeWindow() {
    handleClose()
    initData()
  }

  function initData() {
    setStep(1)
    setSelectedCategory(initialCategoryState)
    setSelectedSubcategory(initialSubcategoryState)
  }

  function goBack() {
    setStep(1)
  }

  function handleSave() {
    formData.setFieldsFormats("category", selectedCategory.id)
    formData.setFieldsFormats("subcategory", selectedSubcategory.service_id)
    closeWindow()
  }

  return (
    <div
      className={`overlay select-category-and-service-overlay ${
        isOpened() ? "show" : ""
      }`}
    >
      <div className="modal">
        <b>Изберете {step === 1 ? "категория" : "услуга"}</b>
        <Tooltip title="Затваряне">
          <span className="close" onClick={closeWindow}></span>
        </Tooltip>
        {step === 1 ? (
          <ul className="categories row">
            {Object.values(app.handleCategories().get()).map((c) => (
              <li
                key={`c-${c.id}`}
                style={{ backgroundColor: CATEGORY_COLORS[c.tag].BACKGROUND }}
                onClick={() => {
                  setStep(2)
                  setSelectedCategory(c)
                }}
              >
                <span
                  className="icon"
                  style={{ backgroundColor: CATEGORY_COLORS[c.tag].ICON }}
                >
                  <img src={c.icon} alt="" />
                </span>
                <div className="right">
                  <b>{c.name}</b>
                  <p style={{ color: CATEGORY_COLORS[c.tag].TIME }}>
                    {c.favours_count}
                    {c.favours_count === 1 ? " услуга" : " услуги"}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <ul className="subcategories">
            {selectedCategory.subcategories.map((s) => (
              <li
                key={`s-${s.service_id}`}
                className={
                  selectedSubcategory?.service_id === s?.service_id
                    ? "active"
                    : ""
                }
                onClick={() => {
                  setSelectedSubcategory(s)
                }}
              >
                {s.service_name}
              </li>
            ))}
          </ul>
        )}
        <div className="buttons">
          <Tooltip title={step === 1 ? " Затваряне" : "Към избор на категория"}>
            <button
              className="btn-secondary"
              onClick={step === 1 ? closeWindow : goBack}
            >
              Назад
            </button>
          </Tooltip>
          {selectedCategory && step === 2 ? (
            <Tooltip
              title={!selectedSubcategory ? "Моля, изберете услуга" : ""}
            >
              <div>
                <button
                  className={`btn-primary ${
                    !selectedSubcategory ? "disabled" : ""
                  }`}
                  onClick={handleSave}
                >
                  Запазване
                </button>
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default forwardRef(SelectCategoryAndService)
