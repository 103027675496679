import { useRef } from "react"
import { createPortal } from "react-dom"

//images
import userIcon from "assets/images/schedule/icons/user.svg"

//components
import SelectWorkerAndDate from "./SelectWorkerAndDate"
import AdditionalWorkers from "./AdditionalWorkers"

//providers
import { useFormDataContext } from "../providers/form"

//libraries
import { Tooltip } from "@mui/material"

//helpers
import renderSelectedWorkers from "../helpers/workerNamesParse"

function SelectWorker({ viewFromHistory }) {
  const formData = useFormDataContext()
  const selectedWorkers = formData.getFieldForClient("users")
  const selectedExtraWorkers = formData.getFieldForClient("extraWorkers")
  const workers = [...selectedWorkers, ...selectedExtraWorkers]

  const selectWorkerAndDateRef = useRef()
  const additionalWorkersRef = useRef()

  function selectWorker() {
    if (viewFromHistory) return

    selectWorkerAndDateRef.current.open()
  }

  function addMoreWorkers() {
    if (viewFromHistory) return

    additionalWorkersRef.current.open()
  }

  return (
    <>
      {createPortal(
        <>
          <SelectWorkerAndDate ref={selectWorkerAndDateRef} />
          <AdditionalWorkers ref={additionalWorkersRef} />
        </>,
        document.body
      )}
      <div className="right-col">
        <div
          className={`worker-holder ${viewFromHistory ? "cursor-default" : ""}`}
          onClick={selectWorker}
        >
          <img src={userIcon} alt="" />
          <div className="right">
            <p>Изпълнители</p>
            <b>{renderSelectedWorkers(workers, 50)}</b>
          </div>
        </div>
        {!viewFromHistory && selectedWorkers.length ? (
          <Tooltip title="Управление на работниците">
            <button className="btn-add" onClick={addMoreWorkers}></button>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default SelectWorker
