//images
import viewIcon from "assets/images/schedule/icons/view.svg"
import editIcon from "assets/images/schedule/icons/edit.svg"
import removeIcon from "assets/images/schedule/icons/remove.svg"
import deactivateIcon from "assets/images/schedule/icons/deactivate.svg"
import activateIcon from "assets/images/schedule/icons/activate.svg"

export const MENU_ITEMS = [
    {
        name: "Преглед",
        icon: viewIcon,
        type: 'VIEW'
    },
    {
        name: "Редакция",
        icon: editIcon,
        type: 'EDIT'
    },
    {
        name: "Деактивация",
        icon: deactivateIcon,
        type: 'DEACTIVATE'
    },
    {
        name: "Активация",
        icon: activateIcon,
        type: 'ACTIVATE'
    },
    {
        name: "Изтриване",
        icon: removeIcon,
        type: 'REMOVE'
    },
]