//hooks
import useFetch from "hooks/useFetch"

//providers
import { useAppContext } from "providers/app"
import { useFinanceDataContext } from "../providers/finances"

function useRemoveTransactions(endpoint, removeRef, type) {
    const app = useAppContext()
    const financeProvider = useFinanceDataContext()

    const { fetch, loading } = useFetch()

    function handleRemove() {
        fetch({ endpoint, method: 'delete' })
            .then(() => {
                removeRef.current.close()
                app.handleMessage().success().show(`${type === 'EXPENSES' ? 'Разхода' : 'Прихода'} премахнат успешно!`)
                financeProvider.handleRefresh().refreshData()
            })
    }

    return {
        handleRemove,
        loading
    }
}

export default useRemoveTransactions