import { forwardRef, useImperativeHandle, useRef } from "react"

//libraries
import { Tooltip } from "@mui/material"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useUpdateSalary from "../hooks/useUpdateSalary"

function UpdateSalary(
  { salaryId, successfulyUpdatedSalaryCB, currentSalary },
  ref
) {
  const formRef = useRef()

  const { handleClose, handleOpen, isOpened } = useOpenWindow()
  const { handleSubmit, loading, handleValidations } = useUpdateSalary(
    salaryId,
    successCB
  )

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }))

  function closeWindow() {
    handleClose()
    resetForm()
  }

  function resetForm() {
    formRef.current.reset()
    handleValidations().clear()
  }

  function validate(fieldName) {
    return handleValidations().validate(fieldName)
  }

  function successCB() {
    closeWindow()
    successfulyUpdatedSalaryCB()
  }

  return (
    <div
      className={`overlay add-edit-user-overlay ${isOpened() ? "show" : ""}`}
      key={isOpened()}
    >
      <form action="#" autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
        <b>Промяна на заплатата</b>
        <Tooltip title="Затваряне">
          <span className="close" onClick={closeWindow}></span>
        </Tooltip>
        <div className="fields">
          <div className="row">
            <div className="col">
              <label htmlFor="salary">Заплата</label>
              <input
                type="number"
                name="salary"
                id="salary"
                className={validate("salary").className}
                min="1"
                step="1"
                defaultValue={currentSalary}
              />
              {validate("salary").message ? (
                <p className="error-text">{validate("salary").message}</p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="buttons">
          <button className="btn-secondary" onClick={closeWindow} type="button">
            Отказ
          </button>
          <button className={`btn-primary ${loading ? "loading" : ""}`}>
            Промяна
          </button>
        </div>
      </form>
    </div>
  )
}

export default forwardRef(UpdateSalary)
