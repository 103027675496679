import { useNavigate } from "react-router-dom"

//hooks
import usePrevParams from "hooks/usePrevParams"
import useFetch from "hooks/useFetch"

//providers
import { useFormDataContext } from "../providers/form"

//helpers
import { ENDPOINTS } from "constants/endpoints"

function useUploadAudioRecord() {
    const navigate = useNavigate()

    const { getPrevParams } = usePrevParams()
    const { fetch } = useFetch()

    const formData = useFormDataContext()
    const recordsList = formData.getFieldForClient("records")

    function uploadRecords(e) {
        const { name, files } = e.target

        const records = [...getRecords()]

        for (let i = 0; i < files.length; i++) {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(files[i])
            fileReader.onload = () => {
                const url = fileReader.result

                records.push({
                    // id: url,
                    url,
                })

                formData.setFieldsFormats(name, records)
            }
        }
    }

    function getRecords() {
        return recordsList
    }

    function getFirstRecords() {
        const records = []
        if (getRecords()[0]) records.push(getRecords()[0])
        if (getRecords()[1]) records.push(getRecords()[1])

        return records
    }

    function prepareRemoveRecord(index) {
        navigate({
            search: `${getPrevParams()}remove_record_index=${index}`,
        })
    }

    function removeRecord(index) {
        const recordId = getRecords()[index].id || null

        if (recordId) {
            fetch({ endpoint: `${ENDPOINTS.ADMIN.REMOVE_AUDIO}/${recordId}`, method: 'delete' })
        }

        const updatedRecordsList = getRecords().filter((_, i) => i !== Number(index))

        formData.setFieldsFormats('records', updatedRecordsList)
    }

    return {
        uploadRecords,
        getFirstRecords,
        removeRecord,
        prepareRemoveRecord,
        getRecords
    }
}

export default useUploadAudioRecord