import { createContext, useCallback, useContext, useMemo, useState } from "react"

//providers
import { useAppContext } from "providers/app"

//libraries
import moment from "moment-timezone"

export const FormDataContext = createContext({})

const FIELD_STRUCTURE_STRING = {
    clientFormat: '',
    serverFormat: '',
}
const FIELD_STRUCTURE_INT = {
    clientFormat: null,
    serverFormat: null,
}
const FIELD_STRUCTURE_ARRAY = {
    clientFormat: [],
    serverFormat: [],
}
const FIELD_STRUCTURE_OBJECT = {
    clientFormat: {},
    serverFormat: {},
}
const FIELD_OBJECT_STRING = {
    clientFormat: {},
    serverFormat: '',
}

const INITIAL_SUBCATEGORY_FIELDS_STATE = {
    [null]: []
}

const FormDataProvider = props => {
    const app = useAppContext()

    const INITIAL_FIELDS_STATE = {
        start: FIELD_STRUCTURE_STRING,
        end: FIELD_STRUCTURE_STRING,
        startHHMM: {
            clientFormat: moment().format("HH:mm")
        },
        endHHMM: {
            clientFormat: checkEndHour(moment().add(1, "hour").format("HH:mm"))
        },
        images: FIELD_STRUCTURE_ARRAY,
        records: FIELD_STRUCTURE_ARRAY,
        description: FIELD_STRUCTURE_STRING,
        category: FIELD_STRUCTURE_STRING,
        subcategory: FIELD_STRUCTURE_STRING,
        subcategoryFields: FIELD_STRUCTURE_OBJECT,
        users: FIELD_STRUCTURE_ARRAY,
        client: FIELD_STRUCTURE_STRING,
        clientName: FIELD_STRUCTURE_STRING,
        clientLocation: FIELD_STRUCTURE_STRING,
        clientLocationId: FIELD_STRUCTURE_INT,
        clientLocationsList: FIELD_STRUCTURE_ARRAY,
        clientPhone: FIELD_STRUCTURE_STRING,
        clientEmail: FIELD_STRUCTURE_STRING,
        extraWorkers: FIELD_STRUCTURE_STRING,
        paymentMethodId: FIELD_STRUCTURE_INT,
        sum: FIELD_STRUCTURE_INT
    }

    const [fields, setFields] = useState(INITIAL_FIELDS_STATE)
    const [subcategoryFields, setSubcategoryFields] = useState(INITIAL_SUBCATEGORY_FIELDS_STATE)

    function clearField(name) {
        setFields(prev => ({
            ...prev,
            [name]: FIELD_STRUCTURE_STRING
        }))
    }

    const getFields = useCallback(() => {
        return fields
    }, [fields])

    const getFieldForClient = useCallback(name => {
        return getFields()[name]?.clientFormat || ''
    }, [fields])

    const getFieldForServer = useCallback(name => {
        return getFields()[name]?.serverFormat || ''
    }, [fields])

    function checkEndHour(HHMM) {
        const minutes = HHMM.split(":")[1]

        if (minutes === "30" || minutes === "00") {
            HHMM = moment(HHMM, "HH:mm").subtract(1, "minute").format("HH:mm")
        }

        return HHMM
    }

    function setFieldsFormats(name, clientFormat, serverFormat = null) {

        if (name === "endHHMM") {
            clientFormat = checkEndHour(clientFormat)
        }


        setFields(prev => ({
            ...prev,
            [name]: {
                clientFormat,
                serverFormat: serverFormat || clientFormat,
            }
        }))
    }

    function resetFields() {
        setFields(INITIAL_FIELDS_STATE)
    }

    function getSelectedCategory() {
        const selectedCategory = Object.values(app.handleCategories().get()).find(c => {
            return c.id === getFieldForClient('category')
        })

        return selectedCategory || null
    }

    function getSelectedSubcategory() {
        const selectedSubcategory = (getSelectedCategory()?.subcategories || []).find(c => {
            return c.service_id === getFieldForClient('subcategory')
        })

        return selectedSubcategory || null
    }

    function handleSubcategoryFields() {
        function set(subcategoryId, fields) {
            setSubcategoryFields({
                [subcategoryId]: fields
            })
        }

        function clear() {
            setSubcategoryFields(INITIAL_SUBCATEGORY_FIELDS_STATE)
        }

        function get() {
            return subcategoryFields
        }

        return {
            set,
            clear,
            get
        }
    }

    const exportedData = useMemo(() => {
        return {
            getFields,
            getFieldForClient,
            getFieldForServer,
            setFieldsFormats,
            clearField,
            getSelectedCategory,
            getSelectedSubcategory,
            handleSubcategoryFields,
            resetFields
        }
    }, [fields, subcategoryFields])

    return <FormDataContext.Provider value={exportedData} {...props} />
}

export const useFormDataContext = () => useContext(FormDataContext)

export default FormDataProvider