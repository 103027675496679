//hooks
import useFetch from "hooks/useFetch"

//providers
import { useAppContext } from "providers/app"
import { useTransactionsDataContext } from "../providers/transactions"
import { useFinanceDataContext } from "components/finances/providers/finances"

function useCreateTransaction({ endpoint, closWindowCB, type }) {
    const app = useAppContext()
    const transactionsData = useTransactionsDataContext()
    const financesData = useFinanceDataContext()

    const { fetch, loading, validations, handleValidations } = useFetch()
    function handleSubmit(e) {
        e.preventDefault()
        const data = new FormData(e.target)

        fetch({ endpoint, method: 'post', data })
            .then(() => {
                closWindowCB()
                app.handleMessage().success().show(`${type === 'INCOMES' ? 'Прихода' : 'Разхода'} беше добавен успешно!`)                
                if(e.target.date) financesData.handleRefresh().refreshData()
                else transactionsData.refreshData(type)
            })
    }

    return {
        handleSubmit,
        loading,
        validations,
        handleValidations
    }
}

export default useCreateTransaction