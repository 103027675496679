//libraries
import moment from "moment-timezone"

//constants
import { SERVER_DATE_FORMAT } from "constants/formats"
import { useState } from "react"

function useSetPeriod() {
    const [periodType, setPeriodType] = useState("WEEK")
    const [selectedDate, setSelectedDate] = useState(null)

    function getActiveYearPeriod() {
        const currentYearFirstDate = moment()
            .startOf("year")
            .format(SERVER_DATE_FORMAT)

        const currentYearLastDate = moment()
            .endOf("year")
            .format(SERVER_DATE_FORMAT)

        return {
            currentYearFirstDate,
            currentYearLastDate,
        }
    }

    function getCurrentMonthPeriod() {
        const currentMonthFirstDate = moment()
            .startOf("month")
            .format(SERVER_DATE_FORMAT)

        const currentMonthLastDate = moment()
            .endOf("month")
            .format(SERVER_DATE_FORMAT)

        return {
            currentMonthFirstDate,
            currentMonthLastDate,
        }
    }

    function getCurrentWeekPeriod() {
        const currenWeekFirstDate = moment().startOf("week").format(SERVER_DATE_FORMAT)
        const currenWeekLastDate = moment().endOf("week").format(SERVER_DATE_FORMAT)

        return {
            currenWeekFirstDate,
            currenWeekLastDate,
        }
    }

    function handleChanegeDate(e) {
        const { value } = e.target

        setSelectedDate(value)
        setPeriodType("DAY")
    }

    function getSelectedPeriod() {
        let startDate = null
        let endDate = null

        switch (periodType) {
            case 'WEEK':
                startDate = getCurrentWeekPeriod().currenWeekFirstDate
                endDate = getCurrentWeekPeriod().currenWeekLastDate
                break
            case 'MONTH':
                startDate = getCurrentMonthPeriod().currentMonthFirstDate
                endDate = getCurrentMonthPeriod().currentMonthLastDate
                break
            case 'YEAR':
                startDate = getActiveYearPeriod().currentYearFirstDate
                endDate = getActiveYearPeriod().currentYearLastDate
                break
            case 'DAY':
                startDate = selectedDate
                break
            default:
                break
        }

        return {
            periodType,
            startDate,
            endDate
        }
    }

    return {
        getSelectedPeriod,
        setPeriodType,
        handleChanegeDate
    }
}

export default useSetPeriod