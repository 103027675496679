import { useEffect, useMemo, useState } from "react"

//constants
import { DAYS, DAYS_SHORTEN } from "constants/days"
import { MONTHS } from "constants/months"

//libraries
import moment from "moment-timezone"

function useHistorySchedule() {
    const dailyCalendar = useMemo(() => handlePeriods().getDaysGroupedByMounths(), [])
    const initialMonthNumber = moment().month() + 1
    const initialDayNumber = moment().dayOfYear()

    const INITIAL_CALENDAR_STATE = useMemo(() => ({
        daily: {
            active: {
                monthNumber: initialMonthNumber,
                dayNumber: initialDayNumber
            },
            data: dailyCalendar
        },
        activeYear: moment().year(),
    }), [])
    var [calendar, setCalendar] = useState(INITIAL_CALENDAR_STATE)

    useEffect(() => {
        setCalendar(prev => ({
            ...prev,
            daily: {
                ...prev.daily,
                data: handlePeriods().getDaysGroupedByMounths()
            }
        }))
    }, [handlePeriods().getActiveYear()])

    function handlePeriods() {
        const getActiveYear = () => calendar?.activeYear || moment().year()

        function changeYear() {
            function toNext() {
                setCalendar(prev => ({
                    ...prev,
                    activeYear: Number(prev.activeYear) + 1
                }))
            }

            function toPrev() {
                setCalendar(prev => ({
                    ...prev,
                    activeYear: Number(prev.activeYear) - 1
                }))
            }

            return {
                toNext,
                toPrev
            }
        }

        function getDaysGroupedByMounths() {
            const monthKeys = Object.keys(MONTHS)
            const monthValues = Object.values(MONTHS)
            const calendarObj = {}
           
            for (let i = 1; i <= monthKeys.length; i++) {
                const month = monthValues[i - 1].substring(0, 3)
                const daysInMonth = moment(i, 'M').daysInMonth()
                calendarObj[i] = {
                    monthAsWord: month,
                    monthAsNumber: i
                }

                for (let j = 1; j <= daysInMonth; j++) {

                    const dayPattern = Number(moment(`${j}.${i}.${getActiveYear()}`, 'DD.MM.YYYY').add(-1, 'd').format('d')) + 1
                    const dayOfWeek = DAYS[dayPattern]
                    const dayOfWeekShorten = DAYS_SHORTEN[dayPattern]
                    const dayOfYear = moment(`${j}.${i}.${getActiveYear()}`, 'DD.MM.YYYY').dayOfYear()

                    calendarObj[i] = {
                        ...calendarObj[i],
                        days: [
                            ...(calendarObj[i].days || []),
                            {
                                dayOfYear,
                                day: j,
                                dayOfWeek,
                                dayOfWeekShorten
                            }
                        ]
                    }
                }
            }

            return calendarObj
        }

        const getMonthsObj = () => calendar.daily.data

        const getMonthsList = () => Object.values(getMonthsObj())

        const getActiveMonthInDailyCalendar = () => calendar.daily.active.monthNumber
        const getActiveDayInDailyCalendar = () => calendar.daily.active.dayNumber

        function setActiveMonthInDailyCalendar(monthNumber) {
            setCalendar(prev => ({
                ...prev,
                daily: {
                    ...prev.daily,
                    active: {
                        ...prev.daily.active,
                        monthNumber
                    }
                }
            }))
        }

        function setActiveDayInDailyCalendar(dayNumber) {
            setCalendar(prev => ({
                ...prev,
                daily: {
                    ...prev.daily,
                    active: {
                        ...prev.daily.active,
                        dayNumber
                    }
                }
            }))
        }

        function getMonthDay() {
            const dayAsDate = moment().dayOfYear(`${getActiveDayInDailyCalendar()}.${getActiveYear()}`).format('DD.MM')
            const dayAsWord = DAYS[moment(`${dayAsDate}.${getActiveYear()}`, 'DD.MM.YYYY').isoWeekday()]

            return {
                dayAsDate,
                dayAsWord,
            }
        }

        return {
            getActiveYear,
            getDaysGroupedByMounths,
            getMonthsList,
            getMonthsObj,
            getActiveMonthInDailyCalendar,
            setActiveMonthInDailyCalendar,
            getActiveDayInDailyCalendar,
            setActiveDayInDailyCalendar,
            getMonthDay,
            changeYear,
        }
    }


    return {
        handlePeriods
    }
}

export default useHistorySchedule