//images
import loadingImage from "assets/images/app/loader.svg"

function Loader({ noPadding }) {
  return (
    <div className={`loader ${noPadding ? "no-padding" : ""}`}>
      <img src={loadingImage} alt="" />
    </div>
  )
}

export default Loader
