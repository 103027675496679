import { useCallback } from "react"
import { useParams } from "react-router-dom"

//libraries
import { Tooltip } from "@mui/material"
import { Area } from "@ant-design/plots"

//hooks
import useSetPeriod from "../hooks/useSetPeriod"
import useFetchTransactions from "../hooks/useFetchTransactions"
import useCategoryChange from "../hooks/useCategoryChange"

//images
import incomesIcon from "assets/images/users/icons/incomes.svg"
import expensesIcon from "assets/images/users/icons/expenses.svg"

//providers
import { useAppContext } from "providers/app"

function Transactions() {
  const app = useAppContext()

  const userId = useParams().userId

  const { setPeriodType, handleChanegeDate, getSelectedPeriod } = useSetPeriod()
  const { handleCategoryChange, getSelectedCategoryId } = useCategoryChange()
  const { getTransactions, loading } = useFetchTransactions(
    userId,
    getSelectedCategoryId(),
    getSelectedPeriod().startDate,
    getSelectedPeriod().endDate
  )

  const config = {
    data: getTransactions().data,
    xField: "day",
    yField: "value",
    seriesField: "type",
    smooth: true,
    color: ["#3ED19D", "#FF7079"],
    legend: false,
    xAxis: {
      range: [0, 1],
    },
    tooltip: {
      formatter: (data) => {
        return {
          name: data.type === "income" ? "Приход" : "Разход",
          value: `${data.value}  лв.`,
        }
      },
    },
    areaStyle: {
      fillOpacity: 0.1,
    },
    height: 150,
  }

  const getAreaChart = useCallback(() => {
    if (!getTransactions().data) return
    
    return <Area {...config} />
  }, [getTransactions()])

  function getCategories() {
    const categories = app.handleCategories().get()

    return Object.values(categories)
  }

  return (
    <div className={`transactions col ${loading ? "loading" : ""}`}>
      <div className="top row">
        <b>Приходи и Разходи</b>
        <div className="legend row">
          <ul className="points row">
            <li className="row incomes">
              <span></span>
              Приходи
            </li>
            <li className="row expenses">
              <span></span>
              Разходи
            </li>
          </ul>
          <ul className="by-period row">
            <Tooltip title="Текуща седмица">
              <li
                className={
                  getSelectedPeriod().periodType === "WEEK" ? "active" : ""
                }
                onClick={() => {
                  setPeriodType("WEEK")
                }}
              >
                С
              </li>
            </Tooltip>
            <Tooltip title="Текущ месец">
              <li
                onClick={() => {
                  setPeriodType("MONTH")
                }}
                className={
                  getSelectedPeriod().periodType === "MONTH" ? "active" : ""
                }
              >
                М
              </li>
            </Tooltip>
            <Tooltip title="Конкретен ден">
              <li
                className={
                  getSelectedPeriod().periodType === "DAY" ? "active" : ""
                }
              >
                Д <input type="date" onChange={handleChanegeDate} />
              </li>
            </Tooltip>
          </ul>
        </div>
      </div>
      <div className="bottom row">
        <div className="left col">
          <select
            name="task_type"
            value={getSelectedCategoryId()}
            onChange={handleCategoryChange}
          >
            <option value="0">Всички категории</option>
            {getCategories().map((c) => (
              <optgroup label={c.name} key={`category-${c.id}`}>
                {c.subcategories.map((s) => (
                  <option
                    key={`subcategory-${s.service_id}`}
                    value={s.service_id}
                  >
                    {s.service_name}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
          <div className="col">
            <div className="row">
              <img src={incomesIcon} alt="" />
              <div className="r col">
                <span>Приходи</span>
                <b>{(getTransactions().total_revenues || 0).toFixed(2)} лв.</b>
              </div>
            </div>
            <div className="row">
              <img src={expensesIcon} alt="" />
              <div className="r col">
                <span>Разходи</span>
                <b>{(getTransactions().total_expenses || 0).toFixed(2)} лв.</b>
              </div>
            </div>
          </div>
        </div>
        <div className="right">{getAreaChart()}</div>
      </div>
    </div>
  )
}

export default Transactions
