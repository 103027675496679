import { forwardRef, useImperativeHandle, useRef, useState } from "react"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useEditAddress from "../hooks/useEditAddress"

//libraries
import { Tooltip } from "@mui/material"

function EditAddress({ changeLocationFromEditAddress }, ref) {
  const [state, setState] = useState({})

  const addressFieldRef = useRef(null)

  const { handleClose, handleOpen, isOpened } = useOpenWindow()
  const { handleSubmit, loading, handleValidations } = useEditAddress(successCB)

  useImperativeHandle(ref, () => ({
    open: openWindow,
    close: closeWindow,
  }))

  function openWindow(address) {
    handleOpen()
    setState(address)
    addressFieldRef.current.focus()
  }

  function closeWindow() {
    handleClose()
    setState({})
    handleValidations().clear()
  }

  function handleChangeAddress(e) {
    const { name, value } = e.target

    setState({ ...state, [name]: value })
  }

  function successCB() {
    changeLocationFromEditAddress(state.id, state.location)
    closeWindow()
  }

  return (
    <div className={`overlay add-hour-overlay ${isOpened() ? "show" : ""}`}>
      <form className="modal" onSubmit={handleSubmit}>
        <b>Редакция на адрес</b>
        <Tooltip title="Затваряне">
          <span className="close" onClick={closeWindow}></span>
        </Tooltip>
        <div className="row">
          <div className="col">
            <input
              type="text"
              placeholder="Промяна на адреса"
              value={state.location}
              name="location"
              ref={addressFieldRef}
              onChange={handleChangeAddress}
              className={handleValidations().check('location') ? 'validate' : ''}
            />
            <input type="hidden" name="location_id" value={state.id} />
          </div>
        </div>
        <div className="buttons">
          <button className="btn-secondary" onClick={closeWindow}>
            Назад
          </button>
          <button className={`btn-primary ${loading ? "loading" : ""}`}>
            Редакция
          </button>
        </div>
      </form>
    </div>
  )
}

export default forwardRef(EditAddress)
