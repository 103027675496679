import { useNavigate } from "react-router-dom"

//images
import clockIcon from "assets/images/schedule/icons/clock.svg"
import locationIcon from "assets/images/schedule/icons/location.svg"
import pictureIcon from "assets/images/schedule/icons/picture.svg"
import micIcon from "assets/images/schedule/icons/mic.svg"

//constants
import { CATEGORY_COLORS } from "constants/colors"
import { CATEGORIES_LIST } from "constants/categories"
import { SERVER_DATE_AND_HOUR_FORMAT } from "constants/formats"

//libraries
import { Tooltip } from "@mui/material"
import moment from "moment-timezone"

//hooks
import usePrevParams from "hooks/usePrevParams"

function WeeklyTask({ data }) {
  const navigate = useNavigate()

  const {
    category,
    favours,
    client,
    start,
    end,
    id,
    comment_count,
    admin_photo_count,
    audio_clip_count,
    is_complete,
  } = data

  const { getPrevParams } = usePrevParams()

  function getFormattedHour(dateAndHour) {
    const formattedHour = moment(
      dateAndHour,
      SERVER_DATE_AND_HOUR_FORMAT
    ).format("HH:mm")

    return formattedHour
  }

  function viewTask(e) {
    const { classList } = e.target
    if (classList.contains("comments") || is_complete) return

    navigate({
      search: `${getPrevParams()}edit_task_id=${id}`,
    })
  }

  function viewComments(taskId) {
    navigate(`${getPrevParams()}edit_task_id=${taskId}&task_tab_index=3`)
  }

  function viewUploads(taskId) {
    navigate(`${getPrevParams()}edit_task_id=${taskId}`)
  }

  return (
    <Tooltip title={is_complete ? "Задачата е завършена" : "Преглед"}>
      <li
        style={{ backgroundColor: CATEGORY_COLORS[category.tag].BACKGROUND }}
        onClick={viewTask}
        className={is_complete ? "is-complete" : ""}
      >
        <div className="top">
          <time style={{ backgroundColor: CATEGORY_COLORS[category.tag].TIME }}>
            <img src={clockIcon} alt="" /> {getFormattedHour(start)}
            {end ? ` - ${getFormattedHour(end)}` : ""}
          </time>

          <span style={{ backgroundColor: CATEGORY_COLORS[category.tag].ICON }}>
            <img src={CATEGORIES_LIST[category.tag].icon} alt="" />
          </span>
        </div>
        <b>
          {favours.service_name}{" "}
          {comment_count ? (
            <Tooltip title="Виж коментарите">
              <button
                className="comments"
                onClick={() => {
                  viewComments(id)
                }}
              >
                {comment_count}
              </button>
            </Tooltip>
          ) : (
            <></>
          )}
        </b>
        {admin_photo_count || audio_clip_count ? (
          <ul className="files">
            {admin_photo_count ? (
              <li>
                <Tooltip title="Виж изображенията">
                  <button
                    onClick={() => {
                      viewUploads(id)
                    }}
                  >
                    <img src={pictureIcon} alt="" />
                    {admin_photo_count}
                  </button>
                </Tooltip>
              </li>
            ) : (
              <></>
            )}
            {audio_clip_count ? (
              <li>
                <Tooltip title="Чуй аудио записите">
                  <button
                    onClick={() => {
                      viewUploads(id)
                    }}
                  >
                    <img src={micIcon} alt="" />
                    {audio_clip_count}
                  </button>
                </Tooltip>
              </li>
            ) : (
              <></>
            )}
          </ul>
        ) : (
          <></>
        )}
        <a
          href={`https://www.google.com/maps/place/${client.location}`}
          target="_blank"
          className="link"
        >
          <img src={locationIcon} alt="" />
          {client.location}
        </a>
      </li>
    </Tooltip>
  )
}

export default WeeklyTask
