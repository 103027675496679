import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

//libraries
import { Tooltip } from "@mui/material"

//images
import removeIcon from "assets/images/schedule/icons/remove-gray.svg"

//components
import CommentReplyField from "./CommentReplyField"

//helpers
import { parseName } from "helpers/substring"

//hooks
import usePrevParams from "hooks/usePrevParams"

function CommentReplies({
  taskId,
  commentId,
  replies,
  getDateAndTimeFormatted,
  viewFromHistory,
}) {
  const navigate = useNavigate()

  const { getPrevParams } = usePrevParams()

  const renderCommentReplyField = useCallback(() => {
    return <CommentReplyField taskId={taskId} commentId={commentId} />
  }, [])

  function prepareRemoveReply(commentId) {
    navigate({
      search: `${getPrevParams()}remove_reply_id=${commentId}`,
    })
  }

  return (
    <div className="replies-holder">
      {!viewFromHistory ? renderCommentReplyField() : <></>}
      <ul className="replies">
        {replies.map((r) => (
          <li key={`reply-${r.id}`}>
            <div className="top">
              <b>{parseName(r.user.name)}</b>
              <time>{getDateAndTimeFormatted(r.created_at)}</time>
              {r.can_delete ? (
                <Tooltip title="Премахване">
                  <button
                    className="remove"
                    onClick={() => {
                      prepareRemoveReply(r.id)
                    }}
                  >
                    <img src={removeIcon} alt="" />
                  </button>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
            <p>{r.reply}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CommentReplies
