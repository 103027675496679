import { useState } from "react"

//libraries
import { Tooltip } from "@mui/material"

//components
import CommentStatuses from "./CommentStatuses"

//hooks
import useCreateComment from "../hooks/useCreatComment"

function CommentField({ taskId }) {
  const [fieldValue, setFieldValue] = useState("")

  const { createComment, loading } = useCreateComment(setFieldValue)

  function handleChange(e) {
    const { value } = e.target

    setFieldValue(value)
  }

  return (
    <div className="comment-field">
      <form className="reply-fields" onSubmit={createComment}>
        <input type="hidden" name="order_id" value={taskId} />
        <div className="col">
          <textarea
            name="comment"
            placeholder="Въведи отговор"
            autoFocus={true}
            value={fieldValue}
            onChange={handleChange}
          ></textarea>
          <div className="buttons">
            <CommentStatuses />
            <Tooltip
              title={
                !fieldValue.length ? "Необходимо е да въведете коментар" : ""
              }
            >
              <div>
                <button
                  className={`btn-primary 
                  ${!fieldValue.length ? "disabled" : ""}
                  ${loading ? "loading" : ""}
                  `}
                >
                  Изпрати
                </button>
              </div>
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CommentField
