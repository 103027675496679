//hooks
import useIsLoading from "hooks/useIsLoading"
import useValidation from "hooks/useValidation"

//constants
import { ENDPOINTS } from "constants/endpoints"

//helpers
import api from "helpers/api"

//providers
import { useAuthDataContext } from "providers/auth"

function useLogin() {
    const auth = useAuthDataContext()
    const {
        loading,
        setLoading
    } = useIsLoading()
    const {
        handleValidations,
        validations
    } = useValidation()

    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        handleValidations().clear()

        const data = new FormData(e.target)

        api.post(ENDPOINTS.AUTH.LOGIN, data)
            .then(res => {
                auth.handleAuth().login(res.data)
            })
            .catch(() => {
                handleValidations().set().all()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return {
        loading,
        handleSubmit,
        validations
    }
}

export default useLogin