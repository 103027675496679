//hooks
import { ENDPOINTS } from "constants/endpoints"
import useFetch from "hooks/useFetch"

//providers
import { useAppContext } from "providers/app"
import { useCommentsDataContext } from "../providers/comments"

function useCreateComment(setFieldValue) {
    const app = useAppContext()
    const commentsData = useCommentsDataContext()

    const { fetch, loading, validations, handleValidations } = useFetch()
    function createComment(e) {
        e.preventDefault()
        const data = new FormData(e.target)

        fetch({ endpoint: ENDPOINTS.SHARED.CREATE_COMMENT, method: 'post', data })
            .then(() => {
                _onSuccess()
            })
    }

    function createReply(e) {
        e.preventDefault()
        const data = new FormData(e.target)

        fetch({ endpoint: ENDPOINTS.SHARED.CREATE_REPLY, method: 'post', data })
            .then(() => {
                _onSuccess()
            })
    }

    function _onSuccess() {
        app.handleMessage().success().show('Вашият коментар беше добавен успешно!')
        commentsData.refreshData()
        setFieldValue('')
    }

    return {
        createComment,
        createReply,
        loading,
        validations,
        handleValidations
    }
}

export default useCreateComment