//libraries
import { Tooltip } from "@mui/material"

//images
import calendarIcon from "assets/images/schedule/icons/calendar.svg"

//providers
import { useScheduleDataContext } from "../providers/schedule"

//constants
import { MONTHS } from "constants/months"

function WeeklyPeriod() {
  const scheduleData = useScheduleDataContext()
  const sh = scheduleData.handlePeriods()
  const weekCalendar = sh.getWeeklyCalendar()
  const activeWeeklyPeriod = sh.getActivePeriod()

  return (
    <div className="week row">
      <div className="select-month row">
        <Tooltip
          title={
            activeWeeklyPeriod.monthNumber === 1
              ? "Предишна година"
              : "Предишен месец"
          }
        >
          <button className="prev" onClick={sh.changeMonth().toPrev}></button>
        </Tooltip>
        <div className="content">
          <b>{MONTHS[activeWeeklyPeriod.monthNumber]}</b>
          <span>{sh.getActiveYear()}</span>
        </div>
        <Tooltip
          title={
            activeWeeklyPeriod.monthNumber === 12
              ? "Следваща година"
              : "Следващ месец"
          }
        >
          <button className="next" onClick={sh.changeMonth().toNext}></button>
        </Tooltip>
      </div>
      <ul className="select-week row">
        {weekCalendar[activeWeeklyPeriod.monthNumber].map((p) => (
          <li
            key={p.weekNumber}
            className={
              activeWeeklyPeriod.weekNumber === p.weekNumber ? "active" : ""
            }
            onClick={() => {
              sh.setActivePeriod(p.weekNumber)
            }}
          >
            {p.monday} - {p.sunday}
          </li>
        ))}
      </ul>
      <Tooltip title="Смяна на типа филтър">
        <button
          className={`change-filter row ${
            scheduleData.loading ? "disabled" : ""
          }`}
          onClick={() => {
            if (scheduleData.loading) return

            sh.setCalendarType("daily")
          }}
        >
          <img src={calendarIcon} alt="" />
          Седмица
        </button>
      </Tooltip>
    </div>
  )
}

export default WeeklyPeriod
