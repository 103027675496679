import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"

//components
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"
import DailyTasksList from "components/schedule/components/DailyTasksList"
import Remove from "components/app/components/Remove"

//hooks
import useFetchUserTasks from "../hooks/useFetchUserTasks"
import useRemoveTask from "components/schedule/hooks/useRemoveTask"

//helpers
import { getUrlData } from "helpers/url"

function Tasks({ userId }) {
  const removeTaskRef = useRef()
  const taskId = getUrlData().remove_task_id

  const { getTasks, loading } = useFetchUserTasks(userId)
  const { handleRemove } = useRemoveTask(taskId, removeTaskRef)

  useEffect(() => {
    if (taskId) removeTaskRef.current.open()
  }, [taskId])

  if (loading) {
    return <Loader />
  }

  if (!getTasks().length) {
    return <NoResultsFound text="Няма задачи за този работник" />
  }

  return (
    <>
      {createPortal(
        <Remove
          ref={removeTaskRef}
          elementsContent={{
            heading: "Изтриване на задачата",
          }}
          accept={handleRemove}
          closeEditWhenFinish={true}
        />,
        document.body
      )}
      <DailyTasksList hideTime={true} tasks={getTasks()} />
    </>
  )
}

export default Tasks
