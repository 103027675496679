import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { createPortal } from "react-dom"

//libraries
import { Tooltip } from "@mui/material"

//hooks
import useFetchTransactions from "../hooks/useFetchTransactions"
import usePrevParams from "hooks/usePrevParams"
import useRemoveTransactions from "../hooks/useRemoveTransactions"

//components
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"
import Remove from "components/app/components/Remove"
import AddTransaction from "./AddTransaction"

//images
import descriptionIcon from "assets/images/schedule/icons/description.svg"
import addExpenseIcon from "assets/images/schedule/icons/add-expense.svg"

//helpers
import { getUrlData } from "helpers/url"

//constants
import { ENDPOINTS } from "constants/endpoints"

const TRANSACTION_TYPE_2 = "EXPENSES"
function ExpensesList({ taskId, viewFromHistory }) {
  const removeRef = useRef()
  const addTransactionRef = useRef()

  const navigate = useNavigate()

  const { getData, loading } = useFetchTransactions(
    ENDPOINTS.SHARED.GET_EXPENSE,
    taskId,
    TRANSACTION_TYPE_2
  )
  const { getPrevParams } = usePrevParams()
  const expenseId = getUrlData().remove_expense_id
  const { handleRemove } = useRemoveTransactions(
    `${ENDPOINTS.SHARED.REMOVE_EXPENSE}?expenses_id=${expenseId}`,
    removeRef,
    TRANSACTION_TYPE_2
  )

  useEffect(() => {
    if (expenseId) removeRef.current.open()
  }, [expenseId])

  function renderList() {
    if (loading) {
      return <Loader />
    }

    if (!loading && !getData().length) {
      return <NoResultsFound text="Няма добавени разходи" />
    }

    return (
      <ul className="list">
        {getData().map((e) => (
          <li key={`expense-${e.id}`}>
            <div className="row">
              <b>{e.expenses.toFixed(2)} лв.</b>
              {e.can_delete && !viewFromHistory ? (
                <Tooltip title="Премахване">
                  <span
                    className="remove"
                    onClick={() => {
                      prepareRemove(e.id)
                    }}
                  ></span>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
            <p>{e.description}</p>
            <i>Разходът е направен от {e.user?.name || "-"}</i>
          </li>
        ))}
      </ul>
    )
  }

  function prepareRemove(expenseId) {
    navigate({
      search: `${getPrevParams()}remove_expense_id=${expenseId}`,
    })
  }

  function addIncome() {
    addTransactionRef.current.open()
  }

  return (
    <>
      {createPortal(
        <>
          <Remove
            ref={removeRef}
            elementsContent={{
              heading: "Премахване на разхода",
            }}
            accept={handleRemove}
          />
          <AddTransaction
            ref={addTransactionRef}
            taskId={taskId}
            type={TRANSACTION_TYPE_2}
          />
        </>,
        document.body
      )}
      <div className="col">
        <div className="col">
          <div className="row">
            <h6>
              <img src={descriptionIcon} alt="" />
              Разходи
            </h6>
            {!viewFromHistory ? (
              <button className="add" onClick={addIncome}>
                <img src={addExpenseIcon} alt="" />
                Разход
              </button>
            ) : (
              <></>
            )}
          </div>
          {renderList()}
        </div>
      </div>
    </>
  )
}

export default ExpensesList
