import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constatns
import { ENDPOINTS } from "constants/endpoints"

function useFetchNotifications(areNotificationsHidden) {
    const [notifications, setNotifications] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)

    const { fetch, loading } = useFetch()

    useEffect(() => {
        _fetchData(getPage())
        _markAsSeen(getPage())
    }, [getPage(), areNotificationsHidden])

    function _fetchData(page) {
        if (loading || areNotificationsHidden) return

        fetch({
            endpoint: `${ENDPOINTS.SHARED.GET_NOTIFICATIONS}?page=${page}`,
            method: "get",
        })
            .then((res) => {
                const { data, totalPages, page } = res.data

                setNotifications(Number(page) === 1 ? data : [...getNotifications(), ...data])
                if (totalPages <= Number(page)) setHasMore(false)
            })
    }

    function _markAsSeen(page) {
        if (areNotificationsHidden) return

        fetch({
            endpoint: `${ENDPOINTS.SHARED.GET_NOTIFICATIONS}?page=${page}&seen=1`,
            method: "get",
        })
    }

    function getNotifications() {
        return notifications
    }

    function getPage() {
        return currentPage
    }

    function resetNotifications() {
        setCurrentPage(1)
        setNotifications([])
        setHasMore(true)
    }

    return {
        hasMore,
        loading,
        getNotifications,
        getPage,
        setCurrentPage,
        resetNotifications
    }
}

export default useFetchNotifications