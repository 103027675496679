//components
import User from "../components/User.jsx"
import Loader from "components/app/components/Loader.jsx"
import NoResultsFound from "components/app/components/NoResultsFound.jsx"

//providers
import { useUsersDataContext } from "../providers/users.js"

function UsersList() {
  const users = useUsersDataContext()
  const { loading, getUsers } = users

  if (loading) {
    return <Loader />
  }

  if (!getUsers().length) {
    return <NoResultsFound />
  }

  return (
    <div className="users">
      <div className="wrapper">
        <ul className="users-list">
          {getUsers().map((u) => (
            <User key={u.id} data={u} />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default UsersList
