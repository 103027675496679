//libraries
import { Tooltip } from "@mui/material"

function Periods({ handlePeriods }) {
  return (
    <div className="periods">
      <div className="day row">
        <div className="indicator">
          <b>{handlePeriods().getMonthDay().dayAsWord}</b>
          <span>{handlePeriods().getMonthDay().dayAsDate}</span>
        </div>
        <div className="right">
          <div className="top row">
            <div className="year">
              <Tooltip title="Предишна година">
                <button
                  className="prev"
                  onClick={() => {
                    handlePeriods()?.changeYear()?.toPrev()
                  }}
                ></button>
              </Tooltip>
              <span>{handlePeriods().getActiveYear()} г.</span>
              <Tooltip title="Следваща година">
                <button
                  className="next"
                  onClick={() => {
                    handlePeriods()?.changeYear()?.toNext()
                  }}
                ></button>
              </Tooltip>
            </div>
            <ul className="months">
              {handlePeriods()
                .getMonthsList()
                .map((m) => (
                  <li
                    key={`month-${m.monthAsNumber}`}
                    className={
                      m.monthAsNumber ===
                      handlePeriods().getActiveMonthInDailyCalendar()
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      handlePeriods().setActiveMonthInDailyCalendar(
                        m.monthAsNumber
                      )
                    }}
                  >
                    {m.monthAsWord}
                  </li>
                ))}
            </ul>
          </div>
          <ul className="bottom days">
            {handlePeriods()
              .getMonthsObj()
              [handlePeriods().getActiveMonthInDailyCalendar()].days.map(
                (d) => (
                  <li
                    key={`day-${d.day}`}
                    className={
                      d.dayOfYear ===
                      handlePeriods().getActiveDayInDailyCalendar()
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      handlePeriods().setActiveDayInDailyCalendar(d.dayOfYear)
                    }}
                  >
                    {d.day}
                    <br />
                    <span>{d.dayOfWeekShorten}</span>
                  </li>
                )
              )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Periods
