//hooks
import useFetch from "hooks/useFetch"

//constatns
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useAppContext } from "providers/app"
import { useUsersDataContext } from "../providers/users"

function useCreateEditUser(userId, closeWindow) {
    const app = useAppContext()
    const users = useUsersDataContext()

    const { fetch, loading, validations, handleValidations } = useFetch()
    function handleSubmit(e) {
        e.preventDefault()
        const data = new FormData(e.target)

        let endpoint
        if (userId) {
            data.append('user_id', userId)
            endpoint = ENDPOINTS.ADMIN.UPDATE_USER
        } else {
            endpoint = ENDPOINTS.ADMIN.CREATE_USER
        }

        fetch({ endpoint, method: 'post', data })
            .then(() => {
                users.refetchAllData()
                app
                    .handleMessage()
                    .success()
                    .show(`Потребителят беше ${userId ? 'редактиран' : 'добавен'} успешно!`)
                closeWindow()
            })
    }

    return {
        handleSubmit,
        loading,
        validations,
        handleValidations
    }
}

export default useCreateEditUser