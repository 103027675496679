//hooks
import useFetch from "hooks/useFetch"

//contants
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useAppContext } from "providers/app"
import { useCommentsDataContext } from "../providers/comments"

function useRemoveComment(commentId, closeRemoveWindow) {
    const app = useAppContext()
    const commentsData = useCommentsDataContext()

    const { fetch } = useFetch()

    function handleRemoveComment() {
        fetch({ endpoint: `${ENDPOINTS.SHARED.REMOVE_COMMENT}?comment_id=${commentId}`, method: 'delete' })
            .then(() => {
                _onSuccess()
            })
            .catch(() => {
                _onError()
            })
            .finally(() => {
                closeRemoveWindow()
            })
    }

    function handleRemoveReply() {
        fetch({ endpoint: `${ENDPOINTS.SHARED.REMOVE_REPLY}?reply_id=${commentId}`, method: 'delete' })
            .then(() => {
                _onSuccess()
            })
            .catch(() => {
                _onError()
            })
            .finally(() => {
                closeRemoveWindow()
            })
    }

    function _onSuccess() {
        app.handleMessage().success().show("Коментарът е премахната успешно!")
        commentsData.refreshData()
    }

    function _onError() {
        app.handleMessage().error().show("Нещо се обърка!")
    }


    return {
        handleRemoveComment,
        handleRemoveReply
    }
}

export default useRemoveComment