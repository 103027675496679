//providers
import { useFormDataContext } from "../providers/form"

//components
import ClientAddressField from "./ClientAddressField"

function ClientFields({ handleValidations, viewFromHistory }) {
  const formData = useFormDataContext()
  const selectedClient = {
    id: formData.getFieldForClient("client"),
    name: formData.getFieldForClient("clientName"),
    email: formData.getFieldForClient("clientEmail"),
    phone: formData.getFieldForClient("clientPhone"),
    location: formData.getFieldForClient("clientLocation"),
    locationId: formData.getFieldForClient("clientLocationId"),
    locationsList: formData.getFieldForClient("clientLocationsList"),
  }

  function handleChange(e) {
    const { name, value } = e.target

    clearLocationOnEmptyInput(name, value)
    formData.setFieldsFormats(name, value)
  }

  //Ако цялото поле на адреса се изтрие, да не редактира адреса, а да създва нов
  function clearLocationOnEmptyInput(name, value) {
    if (name === "clientLocation" && !value.length) {
      formData.setFieldsFormats("clientLocationId", null)
      formData.setFieldsFormats("clientLocation", "")
    }
  }

  function validate(fieldName) {
    return handleValidations().validate(fieldName)
  }

  return (
    <div className="col fields-col">
      <b>Данни за клиента</b>
      <div className="row">
        <div className="col">
          <label htmlFor="client-name">Име и Фамилия</label>
          <input
            type="text"
            name="clientName"
            id="client-name"
            placeholder="Въведете Име и Фамилия..."
            value={selectedClient.name}
            onChange={handleChange}
            className={`${validate("name").className} ${
              viewFromHistory ? "cursor-default" : ""
            }`}
            readOnly={viewFromHistory}
            autoComplete="off"
          />
          {validate("name").message ? (
            <p className="error-text">{validate("name").message}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ClientAddressField
            clientId={selectedClient.id}
            location={selectedClient.location}
            handleChange={handleChange}
            viewFromHistory={viewFromHistory}
            validate={validate}
            locationId={selectedClient.locationId}
            locationsList={selectedClient.locationsList}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="client-phone">Тел. номер</label>
          <input
            type="text"
            name="clientPhone"
            id="client-phone"
            placeholder="Въведете Тел. номер..."
            value={selectedClient.phone}
            onChange={handleChange}
            className={`${validate("number").className} ${
              viewFromHistory ? "cursor-default" : ""
            }`}
            readOnly={viewFromHistory}
            autoComplete="off"
          />
          {validate("number").message ? (
            <p className="error-text">{validate("number").message}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="client-email">Е-поща (опционално)</label>
          <input
            type="text"
            name="clientEmail"
            id="client-email"
            placeholder="Въведете Е-поща..."
            value={selectedClient.email}
            onChange={handleChange}
            className={`${validate("email").className} ${
              viewFromHistory ? "cursor-default" : ""
            }`}
            readOnly={viewFromHistory}
            autoComplete="off"
          />
          {validate("email").message ? (
            <p className="error-text">{validate("email").message}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      {selectedClient.id && !viewFromHistory ? (
        <p>
          <span>*</span>Редакцията на данните по-горе се отнася за избраният
          клиент. За да създадете нов, отмаркирайте избора си.
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ClientFields
