import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"


function useFetchAllClients() {

    const { fetch, loading } = useFetch()

    const [clients, setClients] = useState([])

    useEffect(() => {
        fetchClients()
    }, [])

    function fetchClients() {
        fetch({
            endpoint: `${ENDPOINTS.ADMIN.GET_ALL_CLIENTS}`,
            method: "get",
        })
            .then((res) => {
                setClients(res.data)
            })
    }

    function getClients() {
        return clients
    }

    return {
        getClients,
    }
}

export default useFetchAllClients