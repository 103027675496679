import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"

function useFetchIncomeCategories() {   

    const { fetch } = useFetch()

    const [incomeCategories, setIncomeCategories] = useState([])

    useEffect(()=> {
        fetchIncomeCategories()
    },[])    

    function fetchIncomeCategories() { 
       
        fetch({            
            endpoint: `${ENDPOINTS.SHARED.GET_INCOME_CATEGORIES}`,            
            method: "get",
        })
            .then((res) => {
                setIncomeCategories(res.data)
            })
    }

    function getIncomeCategories() {
        return incomeCategories
    }

    return {
        getIncomeCategories,
        fetchIncomeCategories,
    }
}

export default useFetchIncomeCategories