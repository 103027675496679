import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { createPortal } from "react-dom"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useFetchFreeWorkers from "../hooks/useFetchFreeWorkers"
import useAddExtraWorkers from "../hooks/useAddExtraWorkers"
import useRemoveExtraWorker from "../hooks/useRemoveExtraWorker"
import usePrevParams from "hooks/usePrevParams"

//libraries
import { Tooltip } from "@mui/material"
import moment from "moment-timezone"

//components
import Loader from "components/app/components/Loader"
import Remove from "components/app/components/Remove"
import NoResultsFound from "components/app/components/NoResultsFound"

//providers
import { useFormDataContext } from "../providers/form"

//helpers
import { parseName } from "helpers/substring"
import { getUrlData } from "helpers/url"

//images
import removeIcon from "assets/images/schedule/icons/remove.svg"
import { useNavigate } from "react-router-dom"


function AdditionalWorkers({ }, ref) {
  const taskId = getUrlData().edit_task_id

  const removeExtraWorkerRef = useRef()

  const eId = getUrlData().remove_extra_id
  const extraId = eId

  const formData = useFormDataContext()
  const selectedWorkers = formData.getFieldForClient("users")
  const startTime = formData.getFieldForClient("start")
  const endTime = formData.getFieldForClient("end")

  const navigate = useNavigate()
  //изтрий този коментар после

  const [selectedUsers, setSelectedUsers] = useState([])
  const [values, setValues] = useState("")
  const { handleClose, handleOpen, isOpened } = useOpenWindow()
  const { getWorkers, getExtraWorkers, loading, fetchData } = useFetchFreeWorkers()
  const { handleAddExtra, loadingExtra } = useAddExtraWorkers(setValues)
  const { handleDeleteExtra } = useRemoveExtraWorker(
    extraId,
    closeRemoveWindow,
    refreshWorkers,
    taskId
  )
  const { getPrevParams } = usePrevParams()

  useImperativeHandle(ref, () => ({
    open: openWindow,
    close: closeWindow,
  }))

  function openWindow() {
    handleOpen()
    fetchData(
      getDateAndTimeFormatted(startTime),
      getDateAndTimeFormatted(endTime),
      taskId
    )
    setSelectedUsers(selectedWorkers)
  }

  function closeWindow() {
    handleClose()
  }

  function getDateAndTimeFormatted(dateAndTime) {
    if (dateAndTime) {
      return moment(dateAndTime, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DD HH:mm")
    }

    return null
  }

  function closeRemoveWindow() {
    removeExtraWorkerRef.current.close()
  }

  function refreshWorkers() {
    fetchData(
      getDateAndTimeFormatted(startTime),
      getDateAndTimeFormatted(endTime),
      taskId
    )
  }

  useEffect(() => {
    if (eId) removeExtraWorkerRef.current.open()
  }, [extraId])

  function handleExtraWorkers(e) {
    const { value } = e.target

    setValues(value)
  }

  function handleAdd() {
    let userIds = []
    selectedUsers.map((s) => {
      userIds = [...userIds, s.id]
    })

    formData.setFieldsFormats("users", selectedUsers, userIds)
    closeWindow()
  }

  function selectUser(user, allowExtra = false) {
    if (user.extra && !allowExtra) return
    user["shortName"] = parseName(user.name)

    let updatedUsers = []
    if (isSelected(user.id)) {
      updatedUsers = selectedUsers.filter((s) => s.id !== user.id)
    } else {
      updatedUsers = [...selectedUsers, user]
    }

    setSelectedUsers(updatedUsers)
  }

  function handleSubmit(e) {
    handleAddExtra(e, () => {
      fetchData(
        getDateAndTimeFormatted(startTime),
        getDateAndTimeFormatted(endTime),
        taskId
      )
    })
  }

  function handleDelete(extraId) {
    handleDeleteExtra(extraId, () => {
      fetchData(
        getDateAndTimeFormatted(startTime),
        getDateAndTimeFormatted(endTime),
        taskId
      )
    })
  }

  function prepareRemoveExtraWorker(extraId) {
    navigate({
      search: `${getPrevParams()}remove_extra_id=${extraId}`,
    })
  }
  function isSelected(userId) {
    const selectedItem = selectedUsers.find((s) => s.id === userId)
    if (selectedItem) {
      return true
    }

    return false
  }
  function renderWorkers() {
    return (
      <div
        className={`overlay additional-workers-overlay ${isOpened() ? "show" : ""
          }`}
      >
        <div className="modal">
          <b>Добавяне на работници</b>
          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '10px', display: 'flex' }}>
              <input type="hidden" name="order_id" value={taskId} />
              <input
                placeholder="Въвдете име"
                onChange={handleExtraWorkers}
                name="name"
                autoFocus={true}
                value={values}
              />
              <Tooltip
                title={
                  !values.length ? "Необходимо е да въведете име" : ""
                }
              >
                <div>
                  <button
                    className={`btn-primary
              ${!values.length ? "disabled" : ""}
              ${loadingExtra ? "loading" : ""}
              `}
                    style={{ marginLeft: '5px' }}
                  >
                    Добави
                  </button>
                </div>
              </Tooltip>
            </div>
          </form>
          <Tooltip title="Затваряне">
            <span className="close" onClick={closeWindow}></span>
          </Tooltip>
          {loading ? (
            <Loader />
          ) : !getWorkers().length ? (
            <NoResultsFound text="Няма свободни работници за избраната дата." />
          ) : (
            <ul>
              {getExtraWorkers().length ? (
              getExtraWorkers().map((u) => (
                <li
                  className="row active cursor-default"
                  key={`extra-${u.id}`}
                  onClick={() => {
                    selectUser(u)
                  }}
                >
                  <h6>{u.name}</h6>

                  <Tooltip title="Премахване на работник">
                    <button
                      className="remove"
                      onClick={() => {
                        prepareRemoveExtraWorker(u.id)
                      }}
                    >
                      <img src={removeIcon} alt="" />
                    </button>
                  </Tooltip>
                </li>
              ))
              ):(
                <></>
              )}
              {getWorkers().map((u) => (
                <li
                  className={`row ${isSelected(u.id) ? "active" : ""}`}
                  key={`worker-${u.id}`}
                  onClick={() => {
                    selectUser(u)
                  }}
                >
                  <h6>{u.name}</h6>
                </li>
              ))}
            </ul>
          )}
          <div className="buttons">
            <button className="btn-secondary" onClick={closeWindow}>
              Назад
            </button>
            {getWorkers().length ? (
              <button className="btn-primary" onClick={handleAdd}>
                Добави
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      {createPortal(
        <>
          <Remove
            ref={removeExtraWorkerRef}
            elementsContent={{
              heading: "Изтриване на работникът",
            }}
            accept={handleDelete}
          />
        </>,
        document.body
      )}
      {renderWorkers()}
    </div>
  )
}

export default forwardRef(AdditionalWorkers)
