const _FORM_TABS = [
    "Детайли на задачата",
    "Данни за клиента",
    "Приходи и Разходи",
    "Коментари",
]

export const CREATE_TABS = [
    _FORM_TABS[0],
    _FORM_TABS[1]
]

export const EDIT_TABS = [
    ..._FORM_TABS
]
