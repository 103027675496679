import { forwardRef, useImperativeHandle } from "react"

//hooks
import useOpenWindow from "hooks/useOpenWindow"

//components
import AudioRecord from "./AudioRecord"
import NoResultsFound from "components/app/components/NoResultsFound"

function ViewAudioRecords({ audioRecordsList, prepareRemoveRecord, viewFromHistory }, ref) {
  const { handleClose, handleOpen, isOpened } = useOpenWindow()

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: closeWindow,
  }))

  const closeWindow = () => {
    handleClose()
  }

  return (
    <div
      className={`overlay audio-records-overlay ${isOpened() ? "show" : ""}`}
    >
      <div className="modal">
        <b>Списък с аудио записи</b>
        <span className="close" onClick={closeWindow}></span>
        {audioRecordsList().length ? (
          <ul className="audio-records">
            {audioRecordsList().map((rec, i) => (
              <AudioRecord
                key={`r-${rec.url}`}
                url={rec.url}
                removeRecord={() => {
                  prepareRemoveRecord(i)
                }}
                viewFromHistory={viewFromHistory}
              />
            ))}
          </ul>
        ) : (
          <NoResultsFound text="Списъкът е празен" />
        )}
        <div className="buttons">
          <button className="btn-secondary" onClick={closeWindow}>
            Назад
          </button>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(ViewAudioRecords)
