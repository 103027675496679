import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"

//images
import removeIcon from "assets/images/schedule/icons/remove.svg"
import pdfIcon from "assets/images/schedule/icons/pdf.svg"

//libraries
import { Tooltip, Tabs, Tab } from "@mui/material"

//helpers
import { getUrlData, getUrlExeptPassedParam } from "helpers/url"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useCreateEditTask from "../hooks/useCreateEditTask"
import useRequiredFields from "../hooks/useRequiredFields"
import usePrevParams from "hooks/usePrevParams"
import useFetchProtocol from "../hooks/useFetchProtocol"
import useCreateInvoice from "components/invoices/hooks/useCreateInvoice"

//components
import FormTaskData from "./FormTaskData"
import FormClientData from "./FormClientData"
import FormTransactions from "./FormTransactions"
import FormComments from "./FormComments"
import Loader from "components/app/components/Loader"

//constatns
import { CREATE_TABS, EDIT_TABS } from "../constants/tabs"
import { ENDPOINTS } from "constants/endpoints"

//providers
import FormDataProvider, { useFormDataContext } from "../providers/form"
import TransactionsDataProvider from "../providers/transactions"
import CommentsDataProvider from "../providers/comments"



function AddEdit(_, ref) {
  const formData = useFormDataContext()
  const { loading1, createInvoice} = useCreateInvoice({
    endpoint:
        ENDPOINTS.ADMIN.CREATE_INVOICE,
    closWindowCB: closeWindow,
})

  const formRef = useRef()
  const invoiceRef = useRef()
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const taskId = getUrlData().edit_task_id
  const tabIndex = (getUrlData().task_tab_index || [])[0]

  const { fetchProtocol } = useFetchProtocol()
  const { handleClose, handleOpen, isOpened } = useOpenWindow()
  const { loading, handleSave, handleValidations } = useCreateEditTask(
    taskId,
    isOpened
  )
  const { getPrevParams } = usePrevParams()
  const { requiredFields, getValidationsForTab } = useRequiredFields()
  const validationKeys = handleValidations().get().allKeys()

  useImperativeHandle(ref, () => ({
    open: () => {
      formData.resetFields()
      handleOpen()
    },
    close: handleClose,
  }))

  function closeWindow() {
    navigate({
      search: getUrlExeptPassedParam("edit"),
    })
    navigate({
      search: getUrlExeptPassedParam("task_tab_index"),
    })
    navigate({
      search: getUrlExeptPassedParam("task_preview"),
    })
    handleClose()
    resetForm()
    handleValidations().clear()
  }

  function resetForm() {
    formRef.current.reset()
    formData.resetFields()
    formData.handleSubcategoryFields().clear()
    setActiveTabIndex(0)
  }

  useEffect(() => {
    if (tabIndex) setActiveTabIndex(Number(tabIndex))
  }, [tabIndex])

  useEffect(() => {
    if (taskId) handleOpen()
    else closeWindow()
  }, [taskId])

  useEffect(() => {
    if (validationKeys.length)
      setActiveTabIndex(getValidationsForTab(validationKeys) || 0)
  }, [validationKeys.length])

  function changeTabIndex(_, index) {
    setActiveTabIndex(index)
  }

  function fetchingTaskData() {
    return taskId && !getValueOrNull(formData.getFieldForServer("category"))
  }

  function viewFromHistory() {
    if (location.pathname.includes("history")) {
      return true
    }

    if (location.search.includes("task_preview")) {
      return true
    }

    return false
  }

  function renderActiveTab() {
    if (fetchingTaskData()) {
      return <Loader />
    }
    switch (activeTabIndex) {
      case 0:
        return (
          <FormTaskData
            handleValidations={handleValidations}
            viewFromHistory={viewFromHistory()}
          />
        )
      case 1:
        return (
          <FormClientData
            handleValidations={handleValidations}
            viewFromHistory={viewFromHistory()}
          />
        )
      case 2:
        return (
          <TransactionsDataProvider>
            <FormTransactions viewFromHistory={viewFromHistory()} />
          </TransactionsDataProvider>
        )
      case 3:
        return (
          <CommentsDataProvider>
            <FormComments viewFromHistory={viewFromHistory()} />
          </CommentsDataProvider>
        )
      default:
        break
    }
  }

  function getValueOrNull(value) {
    if (!value) {
      return 0
    }
    if (!String(value).length) {
      return 0
    }

    return value
  }

  function hasEmptyRequiredFileds() {
    let empty = false
    for (let i = 0; i < requiredFields.length; i++) {
      console.log(getValueOrNull(formData.getFieldForServer(requiredFields[i])))
      if (!empty) {
        if (!getValueOrNull(formData.getFieldForServer(requiredFields[i]))) {
          empty = true
        }
      }
    }

    return empty
  }

  function handleInvoice(e) {
    e.preventDefault()

    if (!hasEmptyRequiredFileds()) createInvoice(e)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (viewFromHistory()) return

    if (!hasEmptyRequiredFileds()) handleSave(e, closeWindow)
  }

  function removeTask() {
    navigate({
      search: `${getPrevParams()}remove_task_id=${taskId}`,
    })
  }

  function generateProtocol() {
    fetchProtocol(taskId)
  }

  return (
    <div
      className={`overlay add-edit-task-overlay ${isOpened() ? "show" : ""}`}
      key={isOpened()}
    >
      <div className="form-holder">
        <b>
          {viewFromHistory() ? "Преглед" : taskId ? "Редакция" : "Добавяне"} на
          задача
        </b>
        <Tooltip title="Затваряне">
          <span className="close" onClick={closeWindow}></span>
        </Tooltip>
        <div className="row">
          <Tabs value={activeTabIndex} onChange={changeTabIndex}>
            {(taskId ? EDIT_TABS : CREATE_TABS).map((t, i) => (
              <Tab key={`tab-${i}`} label={t} />
            ))}
          </Tabs>
          {taskId ? (
            <button
              className="download-protocol btn-secondary"
              onClick={generateProtocol}
            >
              <img src={pdfIcon} alt="" />
              Издай протокол
            </button>
          ) : (
            <></>
          )}
        </div>
        {renderActiveTab()}
        <form
          className={`buttons ${fetchingTaskData() ? "disabled" : ""}`}
          onSubmit={(e) => {
            const buttonName = e.nativeEvent.submitter.name;
            if (buttonName === "submit") handleSubmit(e);
            if (buttonName === "invoice") handleInvoice(e);
          }}
          ref={formRef}
        >
          {taskId ? <input type="hidden" value={taskId} name="id" /> : <></>}
          <input
            type="hidden"
            value={getValueOrNull(
              `${formData.getFieldForServer("start").split(" ")[0]
              } ${formData.getFieldForClient("startHHMM")}`
            )}
            name="start"
          />
          <input
            type="hidden"
            value={getValueOrNull(
              `${formData.getFieldForServer("end").split(" ")[0]
              } ${formData.getFieldForClient("endHHMM")}`
            )}
            name="end"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("description"))}
            name="description"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("category"))}
            name="category_id"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("subcategory"))}
            name="favour_id"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("client"))}
            name="client_id"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("clientName"))}
            name="name"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("clientEmail"))}
            name="email"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("clientPhone"))}
            name="number"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("clientLocation"))}
            name="location"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("clientMOL"))}
            name="mol"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("clientEIKEGN"))}
            name="eikegn"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("clientVatNum"))}
            name="vat_number"
          />
          <input
            type="hidden"
            value={getValueOrNull(
              formData.getFieldForServer("clientLocationId")
            )}
            name="location_id"
          />
          <input
            type="hidden"
            value={getValueOrNull(
              formData.getFieldForServer("paymentMethodId")
            )}
            name="method_id"
          />
          <input
            type="hidden"
            value={getValueOrNull(formData.getFieldForServer("sum"))}
            name="cost"
          />
          {formData.getFieldForServer("users").map((u, i) => (
            <input type="hidden" value={u} name={`workers[${i}][user_id]`} />
          ))}
          {formData
            .getFieldForServer("images")
            .map((img, i) =>
              !img.id ? (
                <input
                  type="hidden"
                  value={img.url}
                  name={`adminPhotos[${i}][base64]`}
                  key={img.id || `index-${i}`}
                />
              ) : (
                <></>
              )
            )}
          {formData
            .getFieldForServer("records")
            .map((r, i) =>
              !r.id ? (
                <input
                  type="hidden"
                  value={r.url}
                  name={`audioClips[${i}][base64]`}
                  key={r.id || `index-${i}`}
                />
              ) : (
                <></>
              )
            )}
          {Object.keys(formData.getFieldForServer("subcategoryFields")).map(
            (f, i) => (
              <input
                type="hidden"
                value={f}
                name={`notes[${i}][servicenote_id]`}
                key={`s-${f}`}
              />
            )
          )}
          {Object.values(formData.getFieldForServer("subcategoryFields")).map(
            (f, i) => (
              <input
                type="hidden"
                value={f}
                name={`notes[${i}][note]`}
                key={`n-${f}-${i}`}
              />
            )
          )}
          {taskId && !viewFromHistory() ? (
            <span className="remove" onClick={removeTask}>
              <img src={removeIcon} alt="" />
              Изтриване
            </span>
          ) : (
            <></>
          )}
          {/* {taskId ? (              
              <button
              style={{ marginRight: '10px' }}
              name="invoice"
              className={`btn-primary ${loading1 ? "loading" : ""} ${hasEmptyRequiredFileds() ? "disabled" : ""
                }`}
            >
              Издай фактура
            </button>
          ) : (
            <></>
          )} */}
          <button className="btn-secondary" onClick={closeWindow} type="button">
            {!viewFromHistory() ? "Отказ" : "Назад"}
          </button>
          {!viewFromHistory() ? (
            <button
              name="submit"
              className={`btn-primary ${loading ? "loading" : ""} ${hasEmptyRequiredFileds() ? "disabled" : ""
                }`}
            >
              {taskId ? "Редактирай" : "Добави"}
            </button>
          ) : (
            <></>
          )}
        </form>
      </div>
    </div>
  )
}

export default forwardRef(AddEdit)
