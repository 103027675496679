import { forwardRef, useImperativeHandle, useRef } from "react"

//libraries
import { Tooltip } from "@mui/material"
import moment from "moment-timezone"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useCreateTransaction from "../hooks/useCreateTransaction"
import useFetchFreeWorkers from "../hooks/useFetchFreeWorkers"

//constants
import { ENDPOINTS } from "constants/endpoints"
import { SERVER_DATE_FORMAT } from "constants/formats"
import useFetchExpenseCategories from "components/finances/hooks/useFetchExpenseCategories"
import useFetchIncomeCategories from "components/finances/hooks/useFetchIncomeCategories"
import useFetchUsers from "components/users/hooks/useFetchUsers"
import useFetchWorkers from "../hooks/useFetchWorkers"
import useFetchAllClients from "../hooks/useFetchAllClients"

function AddSum({ taskId, type, withDate = false }, ref) {
  const formRef = useRef()

  const { getExpenseCategories, fetchExpenseCategories } = useFetchExpenseCategories()
  const { getIncomeCategories, fetchIncomeCategories } = useFetchIncomeCategories()
  const { handleClose, handleOpen, isOpened } = useOpenWindow()
  const { getWorkers } = useFetchWorkers()
  const { getClients } = useFetchAllClients()
  const { handleSubmit, loading, handleValidations } = useCreateTransaction({
    endpoint:
      ENDPOINTS.SHARED[type === "INCOMES" ? "CREATE_INCOME" : "CREATE_EXPENSE"],
    closWindowCB: closeWindow,
    type,
  })

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }))

  function closeWindow() {
    handleClose()
    resetForm()
  }

  function resetForm() {
    formRef.current.reset()
    handleValidations().clear()
  }

  function validate(fieldName) {
    return handleValidations().validate(fieldName)
  }

  function getValidationForField() {
    return type === "INCOMES" ? "revenue" : "expenses"
  }

  return (
    <div
      className={`overlay add-edit-user-overlay ${isOpened() ? "show" : ""}`}
      key={isOpened()}
    >
      <form action="#" autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
        <input type="hidden" name="order_id" value={taskId} />
        <b>Добавяне на {type === "INCOMES" ? "приход" : "разход"}</b>
        <Tooltip title="Затваряне">
          <span className="close" onClick={closeWindow}></span>
        </Tooltip>
        <div className="fields">
          <div className="row">
            <div className="col">
              <label htmlFor="sum">Сума</label>
              <input
                type="number"
                name={getValidationForField()}
                id="sum"
                className={validate(getValidationForField()).className}
                min="0.01"
                step="0.01"
              />              
              {validate(getValidationForField()).message ? (
                <p className="error-text">
                  {validate(getValidationForField()).message}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          {withDate ? <div className="row"> 
            <div className="col">
            <label htmlFor="category">Категория</label>
              <select
              name="category"
              id="category">
                <option selected value="0" disabled>
                  Изберете категория
                </option>
                {getExpenseCategories().length && type === 'EXPENSES' ? getExpenseCategories().map((e) => (
                <option value={e.id}>
                  {e.name}
                </option>
                )) : <></>}              
                {getIncomeCategories().length && type === 'INCOMES' ? getIncomeCategories().map((i) => (
                <option value={i.id}>
                  {i.name}
                </option>
                )) : <></>}    
              </select>
            </div>
          </div> : <></>}
          {withDate ? <div className="row"> 
            <div className="col">
            <label htmlFor={type === 'EXPENSES' ? "worker_id" : "client_id"}>{type === 'EXPENSES' ? 'Служител' : 'Клиент'}</label>
              <select
              name={type === 'EXPENSES' ? "worker_id" : "client_id"}
              id={type === 'EXPENSES' ? "worker_id" : "client_id"}>
                <option selected value="0" disabled>
                {type === 'EXPENSES' ? 'Изберете служител' : 'Изберете клиент'}
                </option>
                {type === 'EXPENSES' ? getWorkers().map((e) => (
                <option value={e.id}>
                  {e.name}
                </option>
                )) : <></>}              
                {type === 'INCOMES' ? getClients().map((i) => (
                <option value={i.id}>
                  {i.name}
                </option>
                )) : <></>}    
              </select>
            </div>
          </div> : <></>}
          <div className="row">
            <div className="col">
              <label htmlFor="description">Описание</label>
              <textarea
                name="description"
                id="description"
                className={validate("description").className}
              ></textarea>
              {validate("description").message ? (
                <p className="error-text">{validate("description").message}</p>
              ) : (
                <></>
              )}
            </div>
          </div> 
          {withDate ? <div className="row">
            <div className="col">
            <label htmlFor="date">Дата</label>
            <input
                name="date"
                id="date"
                type="date"
                defaultValue={moment().format(SERVER_DATE_FORMAT)}
              />
            </div>
          </div> : <></>}
        </div>
        <div className="buttons">
          <button className="btn-secondary" onClick={closeWindow} type="button">
            Отказ
          </button>
          <button className={`btn-primary ${loading ? "loading" : ""}`}>
            Добави
          </button>
        </div>
      </form>
    </div>
  )
}

export default forwardRef(AddSum)
