import { useState } from "react"
import useExpenseTypeChange from "./useExpenseTypeChange"

function useClientChange() {
    const [selectedId, setSelectedId] = useState(0)

    function handleClientChange(e) {
        const { value } = e.target
        setSelectedId(value)
    }

    function getSelectedClientId() {
        return selectedId
    }

    return {
        handleClientChange,
        getSelectedClientId,
    }
}

export default useClientChange