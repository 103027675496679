import { useImperativeHandle, useState, forwardRef } from "react"

//constants
import { ENDPOINTS } from "constants/endpoints"

//librariers
import { Tooltip } from "@mui/material"

//images
import incomesIcon from "assets/images/users/icons/incomes.svg"
import expensesIcon from "assets/images/users/icons/expenses.svg"

function EachDay({ data, dailyTotal, prepareRemove }, ref) {
  const [hidden, setHidden] = useState(true)

  useImperativeHandle(ref, () => ({
    toggle: () => setHidden(!hidden),
  }))

  if (hidden) {
    return <></>
  }

  return (
    <div>
      <table cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th>Тип</th>
            <th>Създаден от</th>
            <th>Към задача</th>
            <th>Категория</th>
            <th>Описание</th>
            <th className="align-right">Сума</th>
            <th className="align-right">Опции</th>
          </tr>
        </thead>

        <tbody>
          {Object.values(data).map((t) => (
            <tr key={`inner-1-${t.id}`}>
              <td>
                <Tooltip title={t.revenue ? "Приход" : "Разход"}>
                  <img src={t.revenue ? incomesIcon : expensesIcon} alt="" />
                </Tooltip>
              </td>
              <td>{t.userName}</td>
              <td>{t.task || "-"}</td>
              <td>{t.category_name || "-"}</td>
              <td>{t.description || "-"}</td>
              <td
                className={`align-right ${t.revenue ? "income" : "expense"}`}
              >
                {(t.revenue || t.expense).toFixed(2)} лв.
              </td>
              <td className="align-right">
                <Tooltip title="Премахване">
                  <td>
                    <span
                      className="remove"
                      onClick={() => {
                        t.revenue
                          ? prepareRemove(
                            t.id,
                            "INCOMES",
                            `${ENDPOINTS.SHARED.REMOVE_INCOME}?revenue_id=${t.id}`,
                            "Премахване на прихода"
                          )
                          : prepareRemove(
                            t.id,
                            "EXPENSES",
                            `${ENDPOINTS.SHARED.REMOVE_EXPENSE}?expenses_id=${t.id}`,
                            "Премахване на разхода"
                          )
                      }}
                    ></span>
                  </td>
                </Tooltip>
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td
              className={`align-right`}
              style={{color:`${(dailyTotal || 0) < 0 ? "#ff7079" : "#3ed19d"}`, fontSize:'large'}}
            >
              {(dailyTotal).toFixed(2)} лв.
            </td>
            <td className="align-right">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default forwardRef(EachDay)