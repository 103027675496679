//providers
import { useScheduleDataContext } from "../providers/schedule"

//images
import ascIcon from "assets/images/schedule/icons/asc.svg"
import descIcon from "assets/images/schedule/icons/desc.svg"
import { Tooltip } from "@mui/material"

function Sort() {
  const schaduleData = useScheduleDataContext()

  const isAsc = () => schaduleData.handleSort().get() === "asc"
  return (
    <Tooltip title={isAsc() ? 'Сортиране по низходящ ред Нд. - Пн.' : 'Сортиране по възходящ ред Пн. - Нд.'}>
      <button
        className="sort row"
        onClick={() => {
          schaduleData.handleSort().set(isAsc() ? "desc" : "asc")
        }}
      >
        <img src={isAsc() ? ascIcon : descIcon} alt="" />
        Сортиране
      </button>
    </Tooltip>
  )
}

export default Sort
