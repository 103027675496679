import { useCallback } from "react"

//components
import CategoriesTransactions from "./components/CategoriesTransactions"
import ClientsTransactions from "./components/ClientsTransactions"
import TasksTransactions from "./components/TasksTransactions"
import WorkersTransactions from "./components/WorkersTransactions"

//hooks
import useFetchUsers from "components/users/hooks/useFetchUsers"
import WorkersTransactionsColumn from "./components/WorkersTransactionsColumn"

function Statistics() {
  const { getUsers } = useFetchUsers("")

  const renderClientTransactions = useCallback(() => {
    return <ClientsTransactions />
  }, [])

  const renderTaskTransactions = useCallback(() => {
    return <TasksTransactions getUsers={getUsers} />
  }, [getUsers()])

  const renderCategoriesTransactions = useCallback(() => {
    return <CategoriesTransactions />
  }, [])

  const renderWorkersTransactions = useCallback(() => {
    return <WorkersTransactions getUsers={getUsers} />
  }, [getUsers()])
  const renderWorkersTransactionsColumn = useCallback(() => {
    return <WorkersTransactionsColumn getUsers={getUsers} />
  }, [getUsers()])

  return (
    <section id="statistics">
      <div className="screen-top-bar">
        <div className="wrapper row">
          <h1>Справки на дейността</h1>
        </div>
      </div>

      <div className="wrapper">
      <div className="row">
          <div className="col">{renderWorkersTransactionsColumn()}</div>
        </div>
        <div className="row">
          <div className="col">{renderClientTransactions()}</div>
          <div className="col">{renderTaskTransactions()}</div>
        </div>
        <div className="row">
          <div className="col">{renderCategoriesTransactions()}</div>
          <div className="col">{renderWorkersTransactions()}</div>
        </div>       
      </div>
    </section>
  )
}

export default Statistics
