import { useEffect, useState } from "react"

//constants
import { ENDPOINTS } from "constants/endpoints"

//hooks
import useFetch from "hooks/useFetch"

function useCheckNotifications() {
    const { fetch } = useFetch()

    const [hasUnseenNotifications, setHasUnseenNotifications] = useState(false)
    const [data, setData] = useState({})

    useEffect(() => {
        _fetchData()
    }, [])

    function _fetchData() {
        fetch({ endpoint: ENDPOINTS.SHARED.GET_UNSEEN_NOTIFICATIONS, method: 'get' })
            .then(res => {
                if (res.data === 'seen') return

                const { type, tasks_count } = res.data

                res.data['custom_type'] = type

                if (tasks_count > 0) setHasUnseenNotifications(true)
                setData(res.data)
            })
    }

    function getUnseenNotificationsData() {
        return data
    }

    return {
        hasUnseenNotifications,
        getUnseenNotificationsData
    }
}

export default useCheckNotifications