import { useNavigate } from "react-router-dom"

//libraries
import { Tooltip, MenuItem, Menu } from "@mui/material"

//constants
import { MENU_ITEMS } from "../constants/options"

//hooks
import useMenu from "hooks/useMenu"
import usePrevParams from "hooks/usePrevParams"

function Options({ userId, deactivated }) {
  const navigate = useNavigate()

  const { open, anchorEl, handleOpen, handleClose } = useMenu()
  const { getPrevParams } = usePrevParams()

  function isLastMenuOption(index) {
    if (index === MENU_ITEMS.length - 1) {
      return true
    }
    return false
  }

  function handleOption(type) {
    switch (type) {
      case "VIEW":
        navigate({
          pathname: `/user/${userId}`,
        })
        break
      case "EDIT":
        navigate({
          search: `${getPrevParams()}edit_user_id=${userId}`,
        })
        break
      case "ACTIVATE":
        navigate({
          search: `${getPrevParams()}activate=${userId}`,
        })
        break
      case "DEACTIVATE":
        navigate({
          search: `${getPrevParams()}deactivate=${userId}`,
        })
        break
      case "REMOVE":
        navigate({
          search: `${getPrevParams()}remove_user_id=${userId}`,
        })
        break

      default:
        break
    }
    handleClose()
  }

  function toggleActivateAndDeactivate(m) {
    if (
      (deactivated && m.type === "DEACTIVATE") ||
      (!deactivated && m.type === "ACTIVATE")
    ) {
      return true
    }

    return false
  }

  return (
    <>
      <Tooltip title="Опции">
        <button className="menu" onClick={handleOpen}></button>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {MENU_ITEMS.map((m, i) =>
          toggleActivateAndDeactivate(m) ? (
            <></>
          ) : (
            <MenuItem
              key={`menu-item-${i}`}
              className={isLastMenuOption(i) ? "last" : ""}
              onClick={() => {
                handleOption(m.type)
              }}
            >
              <img src={m.icon} alt="" />
              {m.name}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  )
}

export default Options
