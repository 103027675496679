import { useNavigate } from "react-router-dom"

//libraries
import { Tooltip, MenuItem, Menu } from "@mui/material"

//constants
import { MENU_ITEMS } from "../constants/options"

//hooks
import useMenu from "hooks/useMenu"
import usePrevParams from "hooks/usePrevParams"

function Options({ taskId }) {
  const navigate = useNavigate()

  const { open, anchorEl, handleOpen, handleClose } = useMenu()
  const { getPrevParams } = usePrevParams()

  function isLastMenuOption(index) {
    if (index === MENU_ITEMS.length - 1) {
      return true
    }
    return false
  }
  function handleOption(type) {
    switch (type) {
      case "EDIT":
        navigate({
          search: `${getPrevParams()}edit_task_id=${taskId}`,
        })
        break
      case "REMOVE":
        navigate({
          search: `${getPrevParams()}remove_task_id=${taskId}`,
        })
        break
      case "INCOME":
        navigate({
          search: `${getPrevParams()}edit_task_id=${taskId}&task_tab_index=2`,
        })
        break
      case "EXPENSE":
        navigate({
          search: `${getPrevParams()}edit_task_id=${taskId}&task_tab_index=2`,
        })
        break
      case "COMMENT":
        navigate({
          search: `${getPrevParams()}edit_task_id=${taskId}&task_tab_index=3`,
        })
        break
      default:
        break
    }
    handleClose()
  }

  return (
    <>
      <Tooltip title="Опции">
        <button className="menu" onClick={handleOpen}></button>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {MENU_ITEMS.map((m, i) => (
          <MenuItem
            key={`menu-item-${i}`}
            className={isLastMenuOption(i) ? "last" : ""}
            onClick={() => {
              handleOption(m.type)
            }}
          >
            <img src={m.icon} alt="" />
            {m.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Options
