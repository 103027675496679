import { useEffect, useState } from "react"

//constants
import { ENDPOINTS } from "constants/endpoints"

//hooks
import useFetch from "hooks/useFetch"

function useFetchWorkersTransactions(categoryId, userId, startDate, endDate) {
    const { fetch, loading } = useFetch()

    const [transactions, setTransactions] = useState({})

    useEffect(() => {
        _fetchData()
    }, [startDate, categoryId, userId,endDate])

    function _fetchData() {
        const params = `?worker=${userId}&task_type=${categoryId}&start_date=${startDate}&end_date=${endDate || 0}`

        fetch({ endpoint: `${ENDPOINTS.ADMIN.GET_WORKER_TRANSACTIONS}${params}`, method: 'get' })
            .then(res => {
                setTransactions(res.data)
            })
    }

    function getTransactions() {
        return transactions
    }

    function refetchData() {
        _fetchData()
    }

    return {
        getTransactions,
        loading,
        refetchData,
    }
}

export default useFetchWorkersTransactions