export const NOTIFICATION_TYPES = {
    NEW_TASK: {
        name: 'Нова задача',
        icon: null
    },
    FINISHED_TASK: {
        name: 'Завършена задача',
        icon: null
    },
    TASK_UPDATED: {
        name: 'Редактирана задача',
        icon: null
    },
    UPLOADED_IMAGES: {
        name: 'Бяха прикачени изображения към задача',
        icon: null
    },
    UPLOADED_AUDIOS: {
        name: 'Бяха прикачени аудио записи към задача',
        icon: null
    },
    UPLOADED_FILES: {
        name: 'Бяха прикачени файлове към задача',
        icon: null
    },
    COMMENTED: {
        name: 'Нов коментар',
        icon: null
    }
}