import { useState } from "react"

//hooks
import useFetch from "hooks/useFetch"
import useIsLoading from "hooks/useIsLoading"

//constants
import { ENDPOINTS } from "constants/endpoints"

function useFetchTasks() {
    const { fetch } = useFetch()
    const { loading, setLoading } = useIsLoading(true)

    const [tasks, setTasks] = useState([])

    function fetchTasks(params) {
        setLoading(true)

       if(!params.length) return

        fetch({
            endpoint: `${ENDPOINTS.SHARED.GET_SCHEDULE}${params}`,
            method: "get",
        })
            .then((res) => {
                setTasks(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function getTasks() {
        return tasks
    }

    return {
        getTasks,
        loading,
        fetchTasks,
        setLoading
    }
}

export default useFetchTasks