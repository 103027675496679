import { useEffect, useRef, useState } from "react"

//libraries
import moment from "moment-timezone"

//helpers
import { getGETParam, getUrlData } from "helpers/url"

//constants
import { SERVER_DATE_FORMAT } from "constants/formats"

//components
import AddTransaction from "components/schedule/components/AddTransaction"
import ExportFile from "./ExportFile"

//providers
import { useFinanceDataContext } from "../providers/finances"



function TopBar() {
    const [getParams, setGetParams] = useState({
        ...getUrlData()
    })

    const financeData = useFinanceDataContext()
    const fin = financeData.handlePeriods()
    const [start, setStart] = useState(getParams.start)
    const [end, setEnd] = useState(getParams.end)
    const activeDate = fin.getActiveDate()

    const exportFileRef = useRef()
    const addTransactionRef = useRef()
    const [transactionType, setTransactionType] = useState()

    function addTransaction(type) {
        setTransactionType(type)
        addTransactionRef.current.open()
    }

    function exportFile() {
        exportFileRef.current.open()
    }

    function handleChange() {
        fin.setPeriod(document.getElementById("start").value,document.getElementById("end").value)
    }

    return (
        <div>
            <div className="screen-top-bar">
                <div className="wrapper row">
                    <h1>
                        Финанси
                    </h1>
                    <div>
                        <AddTransaction
                            ref={addTransactionRef}
                            type={transactionType}
                            withDate={true}
                        />
                        <ExportFile
                            ref={exportFileRef}
                        />
                        <button className="btn-add" style={{ backgroundColor: '#FE5000', marginRight: '5px' }} onClick={() => exportFile()}>
                            Импорт на XML
                        </button>
                        <button style={{ marginRight: '5px' }} className="btn-add" onClick={() => addTransaction('INCOMES')}>
                            Добави приход
                        </button>
                        <button className="btn-add" style={{ backgroundColor: '#FF4E77' }} onClick={() => addTransaction('EXPENSES')}>
                            Добави разход
                        </button>
                    </div>
                </div>
            </div>
            <form id="date-filter" className="schedule-filter" onChange={handleChange}>
                <div className="row" style={{ padding: '5px',alignItems:'center'}}>
                    <span style={{marginLeft:'5px'}}>Начало:</span>
                    <input
                        type="date"
                        id="start"
                        name="start"
                        defaultValue={start ? start : moment().startOf('month').format(SERVER_DATE_FORMAT)}
                        style={{marginLeft:'5px'}}
                        value={activeDate.start.start}
                    />
                    <span style={{marginLeft:'25px'}}>Край:</span>
                    <input
                        type="date"
                        id="end"
                        name="end"
                        defaultValue={end ? end : moment().endOf('month').format(SERVER_DATE_FORMAT)}
                        style={{marginLeft:'5px'}}
                        value={activeDate.end.end}
                    />
                </div>
            </form>
        </div>
    )
}
export default TopBar