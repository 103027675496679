import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"


function useFetchWorkers() {

    const { fetch, loading } = useFetch()

    const [workers, setWorkers] = useState([])

    useEffect(() => {
        fetchWorkers()
    }, [])

    function fetchWorkers() {
        fetch({
            endpoint: `${ENDPOINTS.ADMIN.GET_WORKERS}`,
            method: "get",
        })
            .then((res) => {
                setWorkers(res.data)
            })
    }

    function getWorkers() {
        return workers
    }

    return {
        getWorkers,
    }
}

export default useFetchWorkers