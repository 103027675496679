import { useEffect, useState } from "react"


function useControlAudioRecord(audioRef) {
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)


    useEffect(() => {
        let getDurationTimeout

        function getDuration() {
            if (duration === 0) {
                getDurationTimeout = setTimeout(() => {
                    getDuration()
                }, 200)
            } else clearTimeout(getDurationTimeout)

            setDuration(Math.floor(audioRef.current?.duration || 0))
        }
        getDuration()
    }, [])

    useEffect(() => {
        let audioInterval

        if (isPlaying) {
            if (audioRef.current.currentTime >= duration) {
                clearInterval(audioInterval)
                setDuration(audioRef.current.currentTime)
            } else {
                audioInterval = setInterval(() => {
                    setCurrentTime(audioRef.current.currentTime)
                }, 1000)
            }
        } else clearInterval(audioInterval)

        return () => clearInterval(audioInterval)
    }, [isPlaying])

    function _playAudio() {
        setIsPlaying(true)
        audioRef.current.play()
    }
    function _pauseAudio() {
        setIsPlaying(false)
        audioRef.current.pause()
    }
    function _stopAudio() {
        _pauseAudio()
        audioRef.current.currentTime = 0
    }

    function getDurationAsString() {
        const currentTimeAsString = `00:${String(Math.ceil(currentTime)).padStart(
            2,
            0
        )}`
        const duratonAsString = `00:${String(Math.ceil(duration)).padStart(2, 0)}`

        return `${currentTimeAsString} / ${duratonAsString}`
    }

    function handleAudioEnded() {
        _stopAudio()
        setCurrentTime(duration)
    }

    function handleControlBtn() {
        if (isPlaying) _pauseAudio()
        else _playAudio()
    }

    return {
        currentTime,
        isPlaying,
        handleAudioEnded,
        handleControlBtn,
        getDurationAsString
    }
}

export default useControlAudioRecord