//components
import User from "./User.jsx"
import Loader from "components/app/components/Loader.jsx"
import NoResultsFound from "components/app/components/NoResultsFound.jsx"

//providers
import { useUsersDataContext } from "../providers/users.js"

function DeactivatedUsersList() {
  const users = useUsersDataContext()
  const { loadingDeactivatedUsers, getDeactivatedUsers } = users

  if (loadingDeactivatedUsers) {
    return <Loader />
  }

  if (!getDeactivatedUsers().length) {
    return <NoResultsFound />
  }

  return (
    <div className="users">
      <div className="wrapper">
        <ul className="users-list">
          {getDeactivatedUsers().map((u) => (
            <User key={u.id} data={u} />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default DeactivatedUsersList
