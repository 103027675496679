import { useState } from "react"
import useClientChange from "./useClientChange"

function useExpenseTypeChange() {
    const [selectedId, setSelectedId] = useState(0)

    function handleExpenseTypeChange(e) {
        const { value } = e.target  
        setSelectedId(value)
    }

    function getSelectedExpenseTypeId() {
        return selectedId
    }

    return {
        handleExpenseTypeChange,
        getSelectedExpenseTypeId,
    }
}

export default useExpenseTypeChange