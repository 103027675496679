//helpers
import api from "helpers/api"

//providers
import { useAuthDataContext } from "providers/auth"

//hooks
import useValidation from "./useValidation"
import useIsLoading from "./useIsLoading"

function useFetch() {
    const auth = useAuthDataContext()

    const { loading, setLoading } = useIsLoading()
    const {
        handleValidations,
        validations,
    } = useValidation()

    async function fetch(params) {
        const { endpoint, method, data } = params

        handleValidations().clear()
        setLoading(true)
        return await api[method](endpoint, data || {})
            .then(res => {
                return res
            })
            .catch(err => {
                const { status, data } = err.response

                if (status === 401) auth.handleAuth().logout()
                if (data.errors) handleValidations().set().fields(data.errors)

                throw err
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return {
        fetch,
        validations,
        handleValidations,
        loading
    }
}

export default useFetch