//components
import ListTypeFilter from "./ListTypeFilter"

//hooks
import useFetchUsers from "components/users/hooks/useFetchUsers"

function FilterByUsers() {
  const { getUsers, loading } = useFetchUsers("", 3)

  return (
    <ListTypeFilter
      loading={loading}
      label="Изпълнители"
      name="worker"
      data={getUsers()}
    />
  )
}

export default FilterByUsers
