//helpers
import { parseName } from "helpers/substring"

export default function renderSelectedWorkers(selectedWorkers, limit = 2) {
    if (!selectedWorkers.length) return null

    if(limit > selectedWorkers.length){
        limit = selectedWorkers.length
    }

    let workers = ""

    selectedWorkers.map((w, i) => {

        if (w.workers) {
            w['name'] = w.workers.name
        }

        if (i <= limit - 1) {
            workers += `${parseName(w.name)}${i < (limit - 1) && selectedWorkers.length - (limit - 1) !== i ? "," : ""
                } `
        }
    })

    return `${workers} ${selectedWorkers.length > limit ? `+${selectedWorkers.length - limit}` : ""
        }`
}