import { useCallback, useState } from "react"

//libraries
import { Tooltip } from "@mui/material"
import { Line } from "@ant-design/plots"

//hooks
import useCategoryChange from "components/users/hooks/useCategoryChange"
import useSetPeriod from "components/users/hooks/useSetPeriod"
import useFetchWorkersTransactions from  "components/finances/hooks/useFetchWorkersTransactions"
//"../hooks/useFetchWorkersTransactions"

//helpers
import { getGETParam, getUrlData } from "helpers/url"

//images
import incomesIcon from "assets/images/users/icons/incomes.svg"
import expensesIcon from "assets/images/users/icons/expenses.svg"

//providers
import { useAppContext } from "providers/app"

function WorkersTransactions({ getUsers }) {
  const app = useAppContext()

  const [getParams, setGetParams] = useState({
    ...getUrlData()
})

  const [startDate, setStart] = useState(getParams.start)
  const [endDate, setEnd] = useState(getParams.end)

  const { setPeriodType, handleChanegeDate, getSelectedPeriod } = useSetPeriod()
  const { getTransactions, loading } = useFetchWorkersTransactions(
    startDate,
    endDate
  )

  const config = {
    data: getTransactions().data,
    xField: "day",
    yField: "value",
    seriesField: "type",
    smooth: true,
    color: ["#3ED19D", "#FF7079"],
    xAxis: {
      range: [0, 1],
    },
    legend: true,
    legend: {
      position: 'top-right',
      itemName: {
        style: {
          fill: '#000',
        },

        formatter: (seriesField) => {
          if (seriesField === "expense") {
              return 'Разход';
          }
          if (seriesField === "income") {
            return 'Приход';
        }
          return seriesField;
      },
        //formatter: (name) => name,

      },
    },
    tooltip: {
      formatter: (data) => {
        return {          
          name: data.type === "income" ? "Приход" : "Разход",
          value: `${(data.value).toFixed(2)}  лв.`,
        }
      },
    },
    lineStyle: {
      fillOpacity: 0.1,
    },
    height: 150,
  }

  const getAreaChart = useCallback(() => {
    if (!getTransactions().data) return

    return <Line {...config} />
  }, [getTransactions()])

  return (
    <div className={`transactions col ${loading ? "loading" : ""}`}>
      <div className="top row">
        <b>Приходи и Разходи</b>
        <div className="legend row">        
        </div>
      </div>
      <div className="bottom row">
        <div className="left col">
          <div className="col">
            <div className="row">
              <img src={incomesIcon} alt="" />
              <div className="r col">
                <span>Приходи</span>
                <b>{(getTransactions().total_revenues || 0).toFixed(2)} лв.</b>
              </div>
            </div>
            <div className="row">
              <img src={expensesIcon} alt="" />
              <div className="r col">
                <span>Разходи</span>
                <b>{(getTransactions().total_expenses || 0).toFixed(2)} лв.</b>
              </div>
            </div>
          </div>
        </div>
        <div className="right">{getAreaChart()}</div>
      </div>
    </div>
  )
}

export default WorkersTransactions
