import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"

function useFetchHistory(getSelectedDate) {
    const { fetch, loading } = useFetch()

    const [hasMore, setHasMore] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [tasks, setTasks] = useState([])

    useEffect(() => {
        fetchData(currentPage)
    }, [getPage(), getSelectedDate()])

    useEffect(() => {
        _resetPage()
    }, [getSelectedDate()])

    function fetchData(page) {
        if (loading) return
        disableCalendarSideEffect(true)

        fetch({
            endpoint: `${ENDPOINTS.SHARED.GET_SCHEDULE}${getSelectedDate() ? `?date=${getSelectedDate()}&` : '?'}page=${page}&completed_tasks=1`,
            method: "get",
        }).then((res) => {
            const { data, totalPages, page } = res.data

            setTasks(Number(page) === 1 ? data : [...getTasks(), ...data])
            if (totalPages <= Number(page)) setHasMore(false)
        })
            .finally(() => {
                disableCalendarSideEffect(false)
            })
    }

    function disableCalendarSideEffect(disable) {
        const calendar = document.querySelector('.periods .bottom')

        if (disable) calendar.classList.add('disabled')
        else calendar.classList.remove('disabled')
    }

    function getTasks() {
        return tasks
    }

    function getPage() {
        return currentPage
    }

    function _resetPage() {
        setCurrentPage(1)
        setHasMore(true)
        setTasks([])
    }

    return {
        loading,
        hasMore,
        setCurrentPage,
        getPage,
        getTasks,
        _resetPage
    }
}

export default useFetchHistory