import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"

function useFetchExpenseCategories() {   

    const { fetch } = useFetch()

    const [expenseCategories, setExpenseCategories] = useState([])

    useEffect(()=> {
        fetchExpenseCategories()
    },[])    

    function fetchExpenseCategories() {
       
        fetch({            
            endpoint: `${ENDPOINTS.SHARED.GET_EXPENSE_CATEGORIES}`,            
            method: "get",
        })
            .then((res) => {
                setExpenseCategories(res.data)
            })
    }

    function getExpenseCategories() {
        return expenseCategories
    }

    return {
        getExpenseCategories,
        fetchExpenseCategories,
    }
}

export default useFetchExpenseCategories