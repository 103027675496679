import { useEffect, useState } from "react"

//libraries
import { Menu, MenuItem } from "@mui/material"

//hooks
import useFetchCommentStatuses from "../hooks/useFetchCommentStatuses"

function CommentStatuses() {
  const [selectedStatusId, setSelectedStatusId] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const { getStatuses, loading } = useFetchCommentStatuses()

  useEffect(() => {
    if (getStatuses().length) setSelectedStatusId(getStatuses()[0].id)
  }, [getStatuses().length])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleClickMenu(statusId) {
    setSelectedStatusId(statusId)
    handleClose()
  }

  function getSelectedStatus() {
    return getStatuses().find((s) => s.id === selectedStatusId)
  }

  return (
    <>
      <input type="hidden" name="status_id" value={selectedStatusId} />
      <div
        className={`status ${loading ? "loading" : ""}`}
        onClick={handleClick}
      >
        <img src={getSelectedStatus()?.icon} alt="" />
        {getSelectedStatus()?.name || ""}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {getStatuses().map((s) => (
          <MenuItem
            key={`status-${s.id}`}
            onClick={() => {
              handleClickMenu(s.id)
            }}
          >
            <img src={s.icon} alt="" />
            {s.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default CommentStatuses
