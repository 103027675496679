import { useEffect, useState } from "react"

//constants
import { ENDPOINTS } from "constants/endpoints"

//hooks
import useFetch from "hooks/useFetch"

//providers
import { useScheduleDataContext } from "components/schedule/providers/schedule"

function useFetchUserTasks(userId) {
    const scheduleData = useScheduleDataContext()

    const { fetch, loading } = useFetch()

    const [tasks, setTasks] = useState([])

    useEffect(() => {
        _fetchData()
    }, [scheduleData.handleRefresh().getRefreshed()])

    function _fetchData() {
        fetch({ endpoint: `${ENDPOINTS.SHARED.GET_USER_ACTIVE_TASKS}?user_id=${userId}`, method: 'get' })
            .then(res => {
                setTasks(res.data)
            })
    }

    function getTasks() {
        return tasks
    }

    return {
        getTasks,
        loading,
    }
}

export default useFetchUserTasks