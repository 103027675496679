import { useEffect } from "react"

//hooks
import { useAppContext } from "providers/app"

function useFetchRoles() {
    const app = useAppContext()

    useEffect(() => {
        _fetchData()
    }, [])

    function _fetchData() {
        app.handleRoles().fetchData()
    }

    return {}
}

export default useFetchRoles