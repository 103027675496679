import { useCallback, useState } from "react"

//libraries
import { Tooltip } from "@mui/material"
import { Area } from "@ant-design/plots"

//hooks
import useSetPeriod from "components/users/hooks/useSetPeriod"
import useCategoryChange from "components/users/hooks/useCategoryChange"
import useFetchCategoriesTransactions from "../hooks/useFetchCategoriesTransactions"

//images
import incomesIcon from "assets/images/users/icons/incomes.svg"
import expensesIcon from "assets/images/users/icons/expenses.svg"

//providers
import { useAppContext } from "providers/app"
import moment from "moment-timezone"
import { SERVER_DATE_FORMAT } from "constants/formats"

function CategoriesTransactions() {
  const app = useAppContext()
  const [startDate, setStartDate] = useState(moment().startOf('month').format(SERVER_DATE_FORMAT))
  const [endDate, setEndDate] = useState(moment().endOf('month').format(SERVER_DATE_FORMAT))
  const { setPeriodType, handleChanegeDate, getSelectedPeriod } = useSetPeriod()
  const { handleCategoryChange, getSelectedCategoryId } = useCategoryChange()
  const { getTransactions, loading } = useFetchCategoriesTransactions(
    getSelectedCategoryId(),
    startDate,
    endDate
  )

  const config = {
    data: getTransactions().data,
    xField: "day",
    yField: "value",
    seriesField: "type",
    smooth: true,
    color: ["#3ED19D", "#FF7079"],
    legend: true,
    xAxis: {
      range: [0, 1],
    },
    tooltip: {
      formatter: (data) => {
        return {
          name: data.type === "income" ? "Приход" : "Разход",
          value: `${(data.value).toFixed(2)}  лв.`,
        }
      },
    },
    legend: {
      position: 'top-right',
      itemName: {
        style: {
          fill: '#000',
        },

        formatter: (seriesField) => {
          if (seriesField === "expense") {
            return 'Разход';
          }
          if (seriesField === "income") {
            return 'Приход';
          }
          return seriesField;
        },
        //formatter: (name) => name,

      },
    },
    areaStyle: {
      fillOpacity: 0.1,
    },
    height: 150,
  }

  const getAreaChart = useCallback(() => {
    if (!getTransactions().data) return

    return <Area {...config} />
  }, [getTransactions()])

  function getCategories() {
    const categories = app.handleCategories().get()

    return Object.values(categories)
  }

  return (
    <div className={`transactions col ${loading ? "loading" : ""}`}>
       <div className="top row">
        <b>Приходи и Разходи по услуги</b>
        <div className="legend row">
          <div style={{ marginRight: "15px" }}>
            <label htmlFor="start-date">Начало:</label>
            <input
              name="start-date"
              id="start-date"
              type="date"
              defaultValue={moment().startOf('month').format(SERVER_DATE_FORMAT)} onChange={(sd) => setStartDate(sd.target.value)} />
          </div>
          <div>
            <label htmlFor="end-date">Край:</label>
            <input
              name="end-date"
              id="end-date"
              type="date"
              defaultValue={moment().endOf('month').format(SERVER_DATE_FORMAT)} onChange={(ed) => setEndDate(ed.target.value)} />
          </div>
        </div>
      </div>
      <div className="bottom row">
        <div className="left col">
          <div className="col">
            <select
              name="task_type"
              value={getSelectedCategoryId()}
              onChange={handleCategoryChange}
            >
              <option value="0">Всички категории</option>
              {getCategories().map((c) => (
                <optgroup label={c.name} key={`category-${c.id}`}>
                  {c.subcategories.map((s) => (
                    <option
                      key={`subcategory-${s.service_id}`}
                      value={s.service_id}
                    >
                      {s.service_name}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>
          <div className="col">
            <div className="row">
              <img src={incomesIcon} alt="" />
              <div className="r col">
                <span>Приходи</span>
                <b>{(getTransactions().total_revenues || 0).toFixed(2)} лв.</b>
              </div>
            </div>
            <div className="row">
              <img src={expensesIcon} alt="" />
              <div className="r col">
                <span>Разходи</span>
                <b>{(getTransactions().total_expenses || 0).toFixed(2)} лв.</b>
              </div>
            </div>
          </div>
        </div>
        <div className="right">{getAreaChart()}</div>
      </div>
    </div>
  )
}

export default CategoriesTransactions
