import { createContext, useContext, useMemo, useState } from "react"

export const AwaitingDataContext = createContext({})

const AwaitingDataProvider = props => {
    const [refresh, setRefresh] = useState(null)

    function refreshData() {
        setRefresh(new Date().getTime())
    }

    function getRefreshed() {
        return refresh
    }

    function handleRefresh() {
        function refreshData() {
            setRefresh(new Date().getTime())
        }

        function getRefreshed() {
            return refresh
        }

        return {
            refreshData,
            getRefreshed
        }
    }

    const exportedData = useMemo(() => {
        return {
            refreshData,
            handleRefresh,
            getRefreshed
        }
    }, [refresh, handleRefresh()])

    return <AwaitingDataContext.Provider value={exportedData} {...props} />
}

export const useAwaitingDataContext = () => useContext(AwaitingDataContext)

export default AwaitingDataProvider