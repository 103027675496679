import { useRef } from "react"
import { createPortal } from "react-dom"

//images
import rightArrowIcon from "assets/images/schedule/icons/right-arrow.svg"
import whiteRightArrowIcon from "assets/images/schedule/icons/right-arrow-white.svg"

//components
import SelectCategoryAndService from "./SelectCategoryAndService"

//providers
import { useFormDataContext } from "../providers/form"

//constants
import { CATEGORY_COLORS } from "constants/colors"

function SelectCategory({ viewFromHistory }) {
  const formData = useFormDataContext()
  const selectedCategory = formData.getSelectedCategory() || null
  const selectedSubcategory = formData.getSelectedSubcategory() || null

  const selectCategoryAndServiceRef = useRef()

  function getArrowIcon() {
    if (selectedSubcategory) {
      return rightArrowIcon
    }

    return whiteRightArrowIcon
  }

  function selectCategory() {
    if (viewFromHistory) return

    selectCategoryAndServiceRef.current.open()
  }

  return (
    <>
      {createPortal(
        <SelectCategoryAndService ref={selectCategoryAndServiceRef} />,
        document.body
      )}
      <div
        className={`select-category ${viewFromHistory ? "cursor-default" : ""}`}
        onClick={selectCategory}
        style={
          selectedSubcategory
            ? {
              backgroundColor:
                CATEGORY_COLORS[selectedCategory.tag].BACKGROUND,
            }
            : {
              backgroundColor:
                '#FE6017',
            }
        }
      >
        <div className="left">
          {selectedSubcategory ? (
            <span
              className="icon"
              style={{
                backgroundColor: CATEGORY_COLORS[selectedCategory.tag].ICON,
              }}
            >
              <img src={selectedCategory.icon} alt="" />
            </span>
          ) : (
            <></>
          )}
          <b
            style={
              selectedSubcategory
                ? {}
                : {
                  color:
                    '#FFFFFF',
                }
            }
          >{selectedSubcategory?.service_name || "Изберете услуга"}</b>
        </div>
        {!viewFromHistory ? <img src={getArrowIcon()} alt="" /> : <></>}
      </div>
    </>
  )
}

export default SelectCategory
