import { Link, NavLink } from "react-router-dom"

//images
import brandImage from "assets/images/app/header/brand.svg"
import scheduleIcon from "assets/images/app/header/icons/schedule.svg"
import historyIcon from "assets/images/app/header/icons/history.svg"
import statisticsIcon from "assets/images/app/header/icons/statistics.svg"
import usersIcon from "assets/images/app/header/icons/users.svg"
import userIcon from "assets/images/app/header/icons/user.svg"
import mapIcon from "assets/images/app/header/icons/map.svg"
import logoutIcon from "assets/images/app/header/icons/logout.svg"
import walletIcon from "assets/images/app/header/icons/finnances.png"

//libraries
import { Tooltip } from "@mui/material"

//providers
import { useAuthDataContext } from "providers/auth"

//components
import Notifications from "./Notifications"

function Header() {
  const auth = useAuthDataContext()
  const userId = auth.handleAuth().get().user()?.id

  return (
    <header>
      <div className="wrapper row">
        <div className="col full left">
          <Link to="/">
            <img src={brandImage} alt="" />
          </Link>
          <p>Управление на дейността</p>
        </div>
        <div className="col full mid">
          <ul className="row nav">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active none" : "")}
              >
                <img src={scheduleIcon} alt="" />
                Задачи
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/history"
                className={({ isActive }) => (isActive ? "active none" : "")}
              >
                <img src={historyIcon} alt="" />
                История
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/finances"
                className={({ isActive }) => (isActive ? "active none" : "")}
              >
                <img src={walletIcon} alt="" />
                Финанси
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/statistics"
                className={({ isActive }) => (isActive ? "active none" : "")}
              >
                <img src={statisticsIcon} alt="" />
                Справки
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/users"
                className={({ isActive }) => (isActive ? "active none" : "")}
              >
                <img src={usersIcon} alt="" />
                Потребители
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/track-users"
                className={({ isActive }) => (isActive ? "active none" : "")}
              >
                <img src={mapIcon} alt="" />
                Карта
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col full right">
          <div className="row">
            <Notifications />
            <Tooltip title="Моят профил">
              <Link
                to={`/user/${userId}`}
                className={`user ${!userId ? "disabled" : ""}`}
              >
                <img src={userIcon} alt="" />
              </Link>
            </Tooltip>
            <Tooltip title="Изход">
              <span className="logout" onClick={auth.handleAuth().logout}>
                <img src={logoutIcon} alt="" />
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
