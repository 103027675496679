// import { StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

//components
import App from './App'

//providers
import AuthDataProvider from 'providers/auth'
import AppProvider from 'providers/app'
import SocketProvider from 'providers/socket'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <StrictMode>
  <BrowserRouter>
    <AppProvider>
      <AuthDataProvider>
        <SocketProvider>
          <App />
        </SocketProvider>
      </AuthDataProvider>
    </AppProvider>
  </BrowserRouter>
  // </StrictMode>
)

reportWebVitals()
