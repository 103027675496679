//hooks
import useFetch from "hooks/useFetch"

//providers
import { useAppContext } from "providers/app"

//constants
import { ENDPOINTS } from "constants/endpoints"
import { useState } from "react"

function useEditFinance(successCB) {
    const app = useAppContext()
    const [loadingFinance, setLoadingFinance] = useState(null)
    const [validation, setValidation] = useState([])

    const { fetch, loading, validations, handleValidations } = useFetch()
    function handleSubmit(value,id,type, name) {
        const data = { finance_id: id, finance: value, finance_type: type, type: name}
        setLoadingFinance(id)

        fetch({ endpoint:  `${ENDPOINTS.ADMIN.EDIT_FINANCE}`, method: 'post', data })
            .then(() => {
                successCB()
                app
                    .handleMessage()
                    .success()
                    .show('Редакцията беше успешна!')
                setValidation([])
            })
            .catch(res => {
                app
                .handleMessage()
                .error()
                .show(res.response.data.errors.finance)
                setValidation(validation => [...validation,id])
                setLoadingFinance(null)
            });
    }

    return {
        handleSubmit,
        loading,
        handleValidations,
        handleValidations,
        validation,
        loadingFinance,
        setLoadingFinance,
    }
}

export default useEditFinance