//hooks
import useFetch from "hooks/useFetch"

//contants
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useAppContext } from "providers/app"
import { useScheduleDataContext } from "../providers/schedule"

function useRemoveTask(taskId, removeTaskRef) {
    const app = useAppContext()
    const scheduleData = useScheduleDataContext()

    const { fetch } = useFetch()

    function handleRemove() {
        fetch({ endpoint: `${ENDPOINTS.ADMIN.REMOVE_TASK}/${taskId}`, method: 'delete' })
            .then(() => {
                app.handleMessage().success().show("Задачата е премахната успешно!")
                scheduleData.handleRefresh().refreshData()
            })
            .catch(() => {
                app.handleMessage().error().show("Нещо се обърка!")
            })
            .finally(() => {
                removeTaskRef.current.close()
            })
    }

    return {
        handleRemove
    }
}

export default useRemoveTask