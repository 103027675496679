//images
import editIcon from "assets/images/schedule/icons/edit.svg"
import commentsIcon from "assets/images/schedule/icons/comments-gray.svg"
import expenseIcon from "assets/images/schedule/icons/expense.svg"
import incomeIcon from "assets/images/schedule/icons/income.svg"
import removeIcon from "assets/images/schedule/icons/remove.svg"

export const MENU_ITEMS = [
    {
        name: "Редакция",
        icon: editIcon,
        type: 'EDIT'
    },
    {
        name: "Добави приход",
        icon: incomeIcon,
        type: 'INCOME'
    },
    {
        name: "Добави разход",
        icon: expenseIcon,
        type: 'EXPENSE'
    },
    {
        name: "Добави коментар",
        icon: commentsIcon,
        type: 'COMMENT'
    },
    {
        name: "Изтриване",
        icon: removeIcon,
        type: 'REMOVE'
    },
]