import { createContext, useContext, useMemo, useState } from "react"

//plugins
import moment from "moment-timezone"

export const FilterDataContext = createContext({})

const TODAY = moment().format('YYYY-MM-DD')
const FilterDataProvider = props => {
    const [filter, setFilter] = useState({
        user: null,
        usersList: [],
        date: TODAY,
        hour: null
    })

    function getUser() {
        return filter.user
    }

    function getUsersList() {
        return filter.usersList
    }

    function getDate() {
        return filter.date
    }

    function getDateFormatted() {
        return moment(getDate(), 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    function getHour() {
        return filter.hour
    }

    function setUser(user) {
        setFilter(prev => ({
            ...prev,
            user
        }))
    }

    function setUsersList(usersList) {
        setFilter(prev => ({
            ...prev,
            usersList
        }))
    }

    function setDate(date) {
        setFilter(prev => ({
            ...prev,
            date: !date || !date.length ? TODAY : date
        }))
    }

    function setHour(hour) {
        setFilter(prev => ({
            ...prev,
            hour
        }))
    }

    const exportedData = useMemo(() => {
        return {
            getDate,
            getDateFormatted,
            getUser,
            getUsersList,
            getHour,
            setUser,
            setUsersList,
            setDate,
            setHour
        }
    }, [filter])

    return <FilterDataContext.Provider value={exportedData} {...props} />
}

export const useFilterDataContext = () => useContext(FilterDataContext)

export default FilterDataProvider