//images
import checkIcon from "assets/images/app/icons/check.svg"

//providers
import { useAppContext } from "providers/app"

const SuccessMessage = () => {
  const app = useAppContext()
  const { show, text } = app.messages.success

  return (
    <div className={`success-message ${show ? "show" : ""}`}>
      <img src={checkIcon} />
      {text || "Действието беше успешно!"}
    </div>
  )
}

export default SuccessMessage
