import { useState } from "react"

function useIsLoading(initialValue = false) {
    const [loading, setLoading] = useState(initialValue)

    return {
        loading,
        setLoading,
    }
}

export default useIsLoading
