import { useCallback, useState } from "react"
import FinancesList from "./components/FinancesList"
import TopBar from "./components/TopBar"
import WorkersTransactions from "./components/WorkersTransactions"
import FinancesTable from "./components/FinancesTable"
import { Tab, Tabs } from "@mui/material"

function Finances() {

  const [table, setTable] = useState(1)

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  function changeTabIndex(_, index) {
    setActiveTabIndex(index)
  }

  function renderActiveTab() {
    switch (activeTabIndex) {
      case 0:
        return (
          <div className="wrapper">
            <FinancesTable />
          </div>
        )
      case 1:
        return (
          <div className="wrapper hidden">
            <FinancesList />
          </div>
        )

      default:
        break;
    }
  }
  function handleTableChange(e) {
    setTable(e.target.value)
  }
  const renderFinancesTransactions = useCallback(() => {
    return <WorkersTransactions />
  }, [])
  return (
    <section id="finances">
      <TopBar />
      <section id="statistics">
        <div className="wrapper">
          <div style={{ width: '100%' }}>{renderFinancesTransactions()}</div>
        </div>
      </section>
      {/* <div style={{ marginLeft: '15px', marginBottom:'-40px' }}>
        <select onChange={handleTableChange}>
          <option value={1}>Таблица</option>
          <option value={2}>Списък</option>
        </select>
      </div> */}
      <div style={{ marginLeft: '15px'}}>
      <Tabs value={activeTabIndex} onChange={changeTabIndex}>
        <Tab key="tab-0" label="Таблица" />
        <Tab key="tab-1" label="Списък" />

      </Tabs>
      </div>
      {renderActiveTab()}


    </section>
  )
}
export default Finances
