import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"

//helpers
import { getGETParam, getUrlData } from "helpers/url"

//libraries
import moment from "moment-timezone"

//constants
import { MONTHS } from "constants/months"
import { DAYS, DAYS_SHORTEN } from "constants/days"
import { SERVER_DATE_FORMAT } from "constants/formats"

//hooks
import useFetchTasks from "../hooks/useFetchTasks"

//providers
import { useAppContext } from "providers/app"

export const ScheduleDataContext = createContext({})

let fetchScheduleTimeout
const ScheduleDataProvider = props => {
    const [refresh, setRefresh] = useState(null)

    const { fetchTasks, getTasks, loading, setLoading } = useFetchTasks()
    const app = useAppContext()

    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()

    const activeCalendarType = 'daily'
    const dailyCalendar = useMemo(() => handlePeriods()?.getDaysGroupedByMounths(), [handlePeriods().getActiveYear()])
    const weeklyCalendar = useMemo(() => handlePeriods()?.getWeeksGroupedByMonths(), [handlePeriods().getActiveYear()])
    const initialMonthNumber = (moment(searchParams.get('start_date') || searchParams.get('date'), SERVER_DATE_FORMAT).month() || moment().month()) + 1
    const initialWeekNumber = moment(searchParams.get('start_date'), SERVER_DATE_FORMAT).week() || moment().week()
    const initialDayNumber = moment(searchParams.get('date'), SERVER_DATE_FORMAT).dayOfYear() || moment().dayOfYear()
    const initialActiveYear = moment(searchParams.get('start_date') || searchParams.get('date'), SERVER_DATE_FORMAT).year() || moment().year()

    const INITIAL_CALENDAR_STATE = useMemo(() => ({
        weekly: {
            active: {
                monthNumber: initialMonthNumber,
                weekNumber: initialWeekNumber,
            },
            data: weeklyCalendar
        },
        daily: {
            active: {
                monthNumber: initialMonthNumber,
                dayNumber: initialDayNumber,
            },
            data: dailyCalendar
        },
        activeYear: initialActiveYear,
        activeType: activeCalendarType
    }), [])

    const [filters, setFilters] = useState({
        ...getUrlData()
    })
    const [sort, setSort] = useState(searchParams.get('sort') || 'asc')
    var [calendar, setCalendar] = useState(INITIAL_CALENDAR_STATE)

    useEffect(() => {
        clearTimeout(fetchScheduleTimeout)
        setSearchParams(getGETParam(filters))
        if (!Object.keys(filters)[0]) handlePeriods().setDefaultParam()
        fetchScheduleTimeout = setTimeout(() => {
            fetchData()
        }, 500)
    }, [filters])

    useEffect(() => {
        if (handleRefresh().getRefreshed()) fetchData()
    }, [handleRefresh().getRefreshed()])

    useEffect(() => {
        if (app.handleNewNotification().get()) fetchData()
    }, [app.handleNewNotification().get()])

    useEffect(() => {
        handlePeriods().setDefaultParam()

        setCalendar(prev => ({
            ...prev,
            weekly: {
                ...prev.weekly,
                data: weeklyCalendar
            },
            daily: {
                ...prev.daily,
                data: dailyCalendar
            }
        }))
    }, [calendar.activeType, handlePeriods().getActiveYear(), dailyCalendar, weeklyCalendar])

    function fetchData() {
        fetchTasks(getGETParam(filters))
    }

    function handleFilters() {
        function set(key, value) {
            if (!key) {
                console.error('Лиспващ параметър - key')
                return
            }
            // if (!value) {
            //     console.error('Лиспващ параметър - value')
            //     return
            // }

            setFilters(prev => ({
                ...prev,
                [key]: value
            }))
        }

        function get(key) {
            if (!key) {
                console.error('Лиспващ параметър - key')
                return []
            }

            return filters[key]
        }

        function clearAll() {
            setFilters({})
            setCalendar(INITIAL_CALENDAR_STATE)
        }

        return {
            set,
            get,
            clearAll,
        }
    }

    function handleSort() {
        function set(sort) {
            const msg = "Сортирането може да бъде 'asc' или 'desc'"
            switch (!sort) {
                case 'asc':
                    console.error(msg)
                    break
                case 'desc':
                    console.error(msg)
                    break

                default: setSort(sort)
                    break
            }

            handleFilters().set('sort', sort)
        }

        const get = () => sort

        return {
            set,
            get
        }
    }

    function handlePeriods() {
        function getWeeksGroupedByMonths() {
            const numberOfWeeksInYear = moment().weeksInYear()
            const calendarObj = {}
            for (let i = 1; i <= numberOfWeeksInYear; i++) {
                const week = moment().week(i)
                const monthNumber = Number(week.format('M'))

                calendarObj[monthNumber] = [
                    ...(calendarObj[monthNumber] || []),
                    {
                        month: MONTHS[monthNumber],
                        weekNumber: i,
                        monday: week.day(1).format('DD.MM'),
                        sunday: week.day(7).format('DD.MM')
                    }
                ]
            }

            return calendarObj
        }

        const getWeeklyCalendar = () => calendar.weekly.data

        const getActivePeriod = () => calendar.weekly.active

        function setActivePeriod(weekNumber) {
            if (!weekNumber) {
                console.error('Лиспващ параметър.')
                return
            }

            setCalendar(prev => ({
                ...prev,
                weekly: {
                    ...prev.weekly,
                    active: {
                        ...prev.weekly.active,
                        weekNumber
                    }
                }
            }))

            setDefaultParam(weekNumber)
        }

        function changeMonth() {
            function toPrev() {
                if (getActivePeriod().monthNumber === 1) {
                    changeYear().toPrev()
                    _setMonthNumber(12)

                    return
                }

                _setMonthNumber(getActivePeriod().monthNumber - 1)
            }

            function toNext() {
                if (getActivePeriod().monthNumber === 12) {
                    changeYear().toNext()
                    _setMonthNumber(1)

                    return
                }

                _setMonthNumber(getActivePeriod().monthNumber + 1)
            }

            function _setMonthNumber(monthNumber) {
                setCalendar(prev => ({
                    ...prev,
                    weekly: {
                        ...prev.weekly,
                        active: {
                            ...prev.weekly.active,
                            monthNumber
                        }
                    }
                }))
            }

            return {
                toPrev,
                toNext,
            }
        }

        function changeYear() {
            function toNext() {
                setCalendar(prev => ({
                    ...prev,
                    activeYear: Number(prev.activeYear) + 1
                }))
            }

            function toPrev() {
                setCalendar(prev => ({
                    ...prev,
                    activeYear: Number(prev.activeYear) - 1
                }))
            }

            return {
                toNext,
                toPrev
            }
        }

        const getCalendarType = () => calendar.activeType

        function setDefaultParam(data) {
            switch (getCalendarType()) {
                case 'weekly':
                    if (!data) {
                        data = getActivePeriod().weekNumber
                    }
                    const week = moment().year(getActiveYear()).week(data)
                    const startDate = week.day(1).format(SERVER_DATE_FORMAT)
                    const endDate = week.day(7).format(SERVER_DATE_FORMAT)

                    handleFilters().set('awaiting', '')
                    handleFilters().set('date', '')
                    handleFilters().set('start_date', startDate)
                    handleFilters().set('end_date', endDate)
                    break
                case 'daily':
                    if (!data) {
                        data = getActiveDayInDailyCalendar()
                    }

                    const date = moment().year(getActiveYear()).dayOfYear(data).format(SERVER_DATE_FORMAT)

                    handleFilters().set('awaiting', '')
                    handleFilters().set('start_date', '')
                    handleFilters().set('end_date', '')
                    handleFilters().set('date', date)
                    break
                case 'awaiting':
                    handleFilters().set('awaiting', '1')
                    handleFilters().set('start_date', '')
                    handleFilters().set('end_date', '')
                    handleFilters().set('date', '')
                    break
                default:
                    console.error(`Невалиден тип календар. Възможни типове: weekly, daily, awaiting`)
                    break
            }
        }

        const getActiveYear = () => calendar?.activeYear || moment(searchParams.get('start_date') || searchParams.get('date'), SERVER_DATE_FORMAT).year() || moment().year()

        function setCalendarType(activeType) {
            if (!activeType) {
                console.error('Лиспващ параметър. Възможни параметри: weekly, daily')
                return
            }
            let isValidType = false
            switch (activeType) {
                case 'daily':
                    isValidType = true
                    break
                case 'weekly':
                    isValidType = true
                    break
                case 'awaiting':
                    isValidType = true
                    break
                default:
                    break
            }
            if (!isValidType) {
                console.error(`${activeType} не е валиден параметър. Възможни параметри: weekly, daily, awaiting`)
                return
            }
            setCalendar(prev => ({
                ...prev,
                activeType
            }))
            setLoading(true)
        }

        //

        function getDaysGroupedByMounths() {
            const monthKeys = Object.keys(MONTHS)
            const monthValues = Object.values(MONTHS)
            const calendarObj = {}

            for (let i = 1; i <= monthKeys.length; i++) {
                const month = monthValues[i - 1].substring(0, 3)
                const daysInMonth = moment(i, 'M').daysInMonth()
                calendarObj[i] = {
                    monthAsWord: month,
                    monthAsNumber: i
                }

                for (let j = 1; j <= daysInMonth; j++) {
                    const dayPattern = Number(moment(`${j}.${i}.${getActiveYear()}`, 'DD.MM.YYYY').add(-1, 'd').format('d')) + 1
                    const dayOfWeek = DAYS[dayPattern]
                    const dayOfWeekShorten = DAYS_SHORTEN[dayPattern]
                    const dayOfYear = moment(`${j}.${i}.${getActiveYear()}`, 'DD.MM.YYYY').dayOfYear()

                    calendarObj[i] = {
                        ...calendarObj[i],
                        days: [
                            ...(calendarObj[i].days || []),
                            {
                                dayOfYear,
                                day: j,
                                dayOfWeek,
                                dayOfWeekShorten
                            }
                        ]
                    }
                }
            }

            return calendarObj
        }

        const getMonthsObj = () => calendar.daily.data

        const getMonthsList = () => Object.values(getMonthsObj())

        const getActiveMonthInDailyCalendar = () => calendar.daily.active.monthNumber
        const getActiveDayInDailyCalendar = () => calendar.daily.active.dayNumber

        function setActiveMonthInDailyCalendar(monthNumber) {
            setCalendar(prev => ({
                ...prev,
                daily: {
                    ...prev.daily,
                    active: {
                        ...prev.daily.active,
                        monthNumber
                    }
                }
            }))
        }

        function setActiveDayInDailyCalendar(dayNumber) {
            setDefaultParam(dayNumber)
            setCalendar(prev => ({
                ...prev,
                daily: {
                    ...prev.daily,
                    active: {
                        ...prev.daily.active,
                        dayNumber
                    }
                }
            }))
        }

        function getMonthDay() {
            const dayAsDate = moment().dayOfYear(`${getActiveDayInDailyCalendar()}.${getActiveYear()}`).format('DD.MM')
            const dayAsWord = DAYS[moment(`${dayAsDate}.${getActiveYear()}`, 'DD.MM.YYYY').isoWeekday()]

            return {
                dayAsDate,
                dayAsWord
            }
        }

        return {
            getWeeksGroupedByMonths,
            getWeeklyCalendar,
            getActivePeriod,
            setActivePeriod,
            changeMonth,
            setDefaultParam,
            getActiveYear,
            setCalendarType,
            getCalendarType,
            getDaysGroupedByMounths,
            getMonthsList,
            getMonthsObj,
            getActiveMonthInDailyCalendar,
            setActiveMonthInDailyCalendar,
            getActiveDayInDailyCalendar,
            setActiveDayInDailyCalendar,
            getMonthDay,
            changeYear
        }
    }

    function handleRefresh() {
        function refreshData() {
            setRefresh(new Date().getTime())
        }

        function getRefreshed() {
            return refresh
        }

        return {
            refreshData,
            getRefreshed
        }
    }

    function isAwaintingPage() {
        if (location.search.includes("awaiting")) {
            return true
        }

        return false
    }

    const exportedData = useMemo(() => {
        return {
            handleFilters,
            handleSort,
            handlePeriods,
            getTasks,
            loading,
            handleRefresh,
            isAwaintingPage
        }
    }, [filters, calendar, getTasks(), loading, handleRefresh().getRefreshed(), isAwaintingPage()])

    return <ScheduleDataContext.Provider value={exportedData} {...props} />
}

export const useScheduleDataContext = () => useContext(ScheduleDataContext)

export default ScheduleDataProvider