//components
import WeeklyTask from "./WeeklyTask"

//libraries
import moment from "moment-timezone"
import "moment/locale/bg"

//constants
import { SERVER_DATE_FORMAT } from "constants/formats"

const today = moment().format(SERVER_DATE_FORMAT)
moment.locale("bg")

function WeeklyTasksList({ tasks }) {
  function isToday(date) {
    if (today === date) {
      return true
    }

    return false
  }

  function isTomorrow(date) {
    const tomorrow = moment(today, SERVER_DATE_FORMAT)
      .add(1, "d")
      .format(SERVER_DATE_FORMAT)
    if (tomorrow === date) {
      return true
    }

    return false
  }

  function isYesterday(date) {
    const yesterday = moment(today, SERVER_DATE_FORMAT)
      .add(-1, "d")
      .format(SERVER_DATE_FORMAT)
    if (yesterday === date) {
      return true
    }

    return false
  }

  function getDayOfWeekAsWord(date) {
    const dayOfWeekAsWord = moment(date, SERVER_DATE_FORMAT).format("dd")

    return dayOfWeekAsWord
  }

  function getDayAsWord(date) {
    if (isYesterday(date)) {
      return "Вчера"
    }
    if (isToday(date)) {
      return "Днес"
    }
    if (isTomorrow(date)) {
      return "Утре"
    }

    return getDayOfWeekAsWord(date)
  }

  function getFormattedDate(date) {
    const formattedDate = moment(date, SERVER_DATE_FORMAT).format("DD.MM")

    return formattedDate
  }

  return (
    <div className="weekly-tasks-list-holder">
      <ul className="weekly-tasks-list">
        {tasks.map((t) => (
          <li key={t.id}>
            <div className="worker">{t.name}</div>
            <ul className="tasks">
              {Object.values(t.list).map((l, i) => (
                <li className="day" key={`${Object.keys(t.list)[i]}-${i}`}>
                  <h3 className="head">
                    {getDayAsWord(Object.keys(t.list)[i])},{" "}
                    {getFormattedDate(Object.keys(t.list)[i])}
                    {l.length ? (
                      <span className="daily-tasks-count">{l.length}</span>
                    ) : (
                      <></>
                    )}
                  </h3>
                  {l.length ? (
                    <ul>
                      {l.map((tl) => (
                        <WeeklyTask key={tl.id} data={tl} />
                      ))}
                    </ul>
                  ) : (
                    <p className="no-tasks">Няма задачи за този ден</p>
                  )}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default WeeklyTasksList
