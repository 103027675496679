/**
 * 
 * @param {string} string произволен string 
 * @param {number} length къде да бъде отрязан string-a
 * @returns отрязания стринг + многоточие || оригиналния string
 */
export const substring = (string, length = null) => {
    if (!length || !string) {
        return string
    }
    if (string.length >= length) {
        string = `${string.substring(0, length)}...`
    }

    return string
}


export function parseName(name) {
    if (!name) return '-'

    const splittedName = name.split(" ")

    return `${splittedName[0]}${splittedName[1] ? ` ${splittedName[1][0]}.` : ""}`
}