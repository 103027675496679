import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useFormDataContext } from "../providers/form"

//helpers
import { getUrlData } from "helpers/url"

let searchClientsTimeout
function useFetchClients() {
    const { fetch, loading } = useFetch()
    const taskId = getUrlData().edit_task_id

    const formData = useFormDataContext()

    function getInitialSearchState() {
        if (Object.keys(formData).length) {
            return formData?.getFieldForClient("clientName")

        }

        return ''
    }

    const [hasMore, setHasMore] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [clients, setClients] = useState([])
    const [search, setSearch] = useState(getInitialSearchState())


    useEffect(() => {
        fetchData(currentPage)
    }, [getSearchValue(), getPage()])

    function fetchData(page) {
        if (loading) return

        fetch({
            endpoint: `${ENDPOINTS.ADMIN.GET_CLIENTS}?name=${getSearchValue()}&order_id=${taskId}&page=${page}`,
            method: "get",
        }).then((res) => {
            const { data, totalPages, page } = res.data

            setClients(Number(page) === 1 ? data : [...clients, ...data])
            if (totalPages <= Number(page)) setHasMore(false)
        })
    }

    function getClients() {
        return clients
    }

    function getPage() {
        return currentPage
    }

    function _resetPage() {
        setCurrentPage(1)
        setHasMore(true)
    }

    function getSearchValue() {
        return search
    }

    function handleSearchByName(name) {
        _search(name)
    }

    function _search(name) {
        clearTimeout(searchClientsTimeout)
        searchClientsTimeout = setTimeout(() => {
            setSearch(name)
            _resetPage()
        }, 500)
    }

    function handleSearch(e) {
        const { value } = e.target

        _search(value)
    }

    return {
        loading,
        hasMore,
        setCurrentPage,
        getPage,
        getClients,
        handleSearch,
        handleSearchByName,
        getSearchValue
    }
}

export default useFetchClients