import { useNavigate } from "react-router-dom"

//libraries
import moment from "moment-timezone"
import { ClickAwayListener, Tooltip } from "@mui/material"

//constatns
import { NOTIFICATION_TYPES } from "constants/notifications"
import { CATEGORIES_LIST } from "constants/categories"

//components
import Loader from "./Loader"
import NoResultsFound from "./NoResultsFound"

//hooks
import useFetchNotifications from "../hooks/useFetchNotifications"

function NotificationsList({ hidden, setHidden }) {
  const navigate = useNavigate()

  const {
    hasMore,
    loading,
    getNotifications,
    getPage,
    setCurrentPage,
    resetNotifications,
  } = useFetchNotifications(hidden)

  if (hidden) {
    return <></>
  }

  function handleView(taskId) {
    navigate(`?edit_task_id=${taskId}&task_preview=1`)
    setHidden(true)
  }

  function timeStampHandler() {
    function _getBeautiflyTaskDate(d) {
      if (!d) {
        return "Липсващ параметър"
      }
      const date = moment(d, "DD.MM.Y")
      const dayAsDate = date.format("D")
      const monthAsWord = date.format("MMMM")

      return `${dayAsDate} ${monthAsWord}`
    }
    function _getTime(timeStamp) {
      const time = moment(timeStamp).utc().tz("Europe/Sofia").format("HH:mm")

      return time
    }
    function getBeautiflyTaskDateAndHourFromTimestamp(timeStamp) {
      const date = moment(timeStamp, "YY-MM-DD").format("DD.MM.Y")

      return `${_getBeautiflyTaskDate(date)} ${_getTime(timeStamp)}`
    }

    return {
      getBeautiflyTaskDateAndHourFromTimestamp,
    }
  }

  function handleScrollList(e) {
    if (!hasMore || loading) return

    const { target } = e
    if (
      (target.scrollHeight - target.clientHeight).toFixed(0) - 1 <=
      target.scrollTop.toFixed(0)
    )
      setCurrentPage(getPage() + 1)
  }
  
  return (
    <ClickAwayListener
      onClickAway={() => {
        setHidden(true)
        resetNotifications()
      }}
    >
      <div className="list">
        {loading && !getNotifications().length ? (
          <Loader />
        ) : (
          <>
            <h3>Вашите известия</h3>
            {!getNotifications().length ? (
              <NoResultsFound text="Все още няма известия" />
            ) : (
              <ul onScroll={handleScrollList}>
                {getNotifications().map((n) => (
                  <Tooltip
                    title={!n?.items ? "Задачата е изтрита" : ""}
                    key={n.created_at}
                  >
                    <li
                      className={`row ${!n.read_at ? "unread" : ""} ${!n?.items ? 'removed' : ''}`}
                      onClick={() => {
                        if (n.items) handleView(n.data.workorder_id)
                      }}
                    >
                      <div className="icon">
                        {n?.items ? (
                          <img
                            src={CATEGORIES_LIST[n.items.category.tag].icon}
                            alt=""
                          />
                        ) : (
                          <>?</>
                        )}
                      </div>
                      <div className="right col">
                        <h6>{NOTIFICATION_TYPES[n.data.type].name} от {n.items?.user?.name || 'Неизвестен потребител'}</h6>
                        <span>
                          {timeStampHandler().getBeautiflyTaskDateAndHourFromTimestamp(
                            n.created_at
                          )}
                        </span>
                      </div>
                    </li>
                  </Tooltip>
                ))}
                {loading ? (
                  <li>
                    <Loader noPadding={true} />
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            )}
          </>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default NotificationsList
