import { useState } from "react"

//providers
import { useFormDataContext } from "../providers/form"

function useSetClientLocation(locationId) {
    const formData = useFormDataContext()
    const [isHidden, setIsHidden] = useState(true)

    function changeLocation(location) {
        let unselectLocation = false
        if ((locationId === location?.id) || (location === null)) {
            unselectLocation = true
        }

        formData.setFieldsFormats("clientLocation", unselectLocation ? "" : location?.location)
        formData.setFieldsFormats("clientLocationId", unselectLocation ? null : location?.id)
    }

    function isSelected(id) {
        if (locationId === id) {
            return true
        }

        return false
    }

    return {
        changeLocation,
        isSelected,
        setIsHidden,
        isHidden
    }
}

export default useSetClientLocation