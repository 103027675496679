import { useNavigate } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { Autocomplete, InputBase, Tooltip } from "@mui/material"

//images
import incomesIcon from "assets/images/users/icons/incomes.svg"
//images
import loadingImage from "assets/images/app/loader.svg"
import expensesIcon from "assets/images/users/icons/expenses.svg"

//hooks
import usePrevParams from "hooks/usePrevParams"
import useFetchFinancesTable from "../hooks/useFetchFinancesTable"
import useFetchExpenseCategories from "../hooks/useFetchExpenseCategories"
import useFetchIncomeCategories from "../hooks/useFetchIncomeCategories"
import useRemoveTransactions from "../hooks/useRemoveTransactions"
import useFetchClients from "components/schedule/hooks/useFetchClients"
import useUserChange from "components/statistics/hooks/useUserChange"
import useFetchUsers from "components/users/hooks/useFetchUsers"
import useFetchAllClients from "components/schedule/hooks/useFetchAllClients"
import useCategoryChange from "components/users/hooks/useCategoryChange"

//components
import Remove from "components/app/components/Remove"

//helpers
import { getGETParam, getUrlData } from "helpers/url"

//constants
import { ENDPOINTS } from "constants/endpoints"
import { SERVER_DATE_FORMAT } from "constants/formats"

//libraries
import moment from "moment-timezone"
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"


//providers
import { useAppContext } from "providers/app"
import useClientChange from "../hooks/useClientChange"
import useExpenseTypeChange from "../hooks/useExpenseTypeChange"
import useEditFinance from "../hooks/useEditFinance"
import { useFinanceDataContext } from "../providers/finances"
import TableRow from "./TableRow"
import TableLoader from "./TableLoader"


function FinancesTable() {
    const financeProvider = useFinanceDataContext()

    const app = useAppContext()
    const { getUsers } = useFetchUsers("")
    const { handleSubmit, validation, loadingFinance, setLoadingFinance } = useEditFinance(successCB)
    const { getClients } = useFetchAllClients()
    const { handleCategoryChange, getSelectedCategoryId } = useCategoryChange()
    const { handleClientChange, getSelectedClientId } = useClientChange()
    const { handleExpenseTypeChange, getSelectedExpenseTypeId } = useExpenseTypeChange()

    const eachDayRefs = useRef([])
    const tableInput = useRef([])


    const { handleUserChange, getSelectedUserId } = useUserChange()


    const { loading, getFinances, getFinancesEntries, setLoading, fetchFinances } =
        useFetchFinancesTable(getSelectedExpenseTypeId(), getSelectedUserId(), getSelectedClientId(), getSelectedCategoryId())

    const { getExpenseCategories, fetchExpenseCategories } = useFetchExpenseCategories()

    const { getIncomeCategories, fetchIncomeCategories } = useFetchIncomeCategories()

    const [transactionType, setTransactionType] = useState()

    const [transactionRemoveType, setTransactionRemoveType] = useState()

    const [headingType, setHeadingType] = useState()

    const clientsLoading = useFetchClients().loading

    const [visibleSpans, setVisibleSpans] = useState()

    function switchInput(e) {
        var indexNum = Number(e.target.getAttribute("data-input-index"))

        if (e.key === 'ArrowDown') {
            if (indexNum + 1 >= (tableInput.current.length - 1)) {
                tableInput.current[1].focus()
            }
            else {
                tableInput.current[indexNum + 1].focus()
            }
        }
        if (e.key === 'ArrowUp') {
            if (indexNum - 1 <= 0) {
                tableInput.current[tableInput.current.length - 1].focus()
            }
            else {
                tableInput.current[indexNum - 1].focus()
            }
        }
    }

    useEffect(() => {
        if (loading == false) {
            setLoadingFinance(null)
        }
    }, [loading])

    function getCategories() {
        const categories = app.handleCategories().get()

        return Object.values(categories)
    }

    function handleChange(e) {
        const { value } = e.target
        let type = e.target.getAttribute("data-finance-type")
        let id = e.target.getAttribute("data-finance-id")
        let name = e.target.getAttribute("data-finance-name")

        if (e.target.value != e.target.defaultValue) {
            handleSubmit(value, id, type, name)
        }
    }
    function handleDateChange(e) {
        const { value } = e.target
        let type = e.target.getAttribute("data-finance-type")
        let id = e.target.getAttribute("data-finance-id")
        let name = e.target.getAttribute("data-finance-name")
        console.log(value, id, type, name);

        if (e.target.value != e.target.defaultValue) {
            handleSubmit(value, id, type, name)
        }
    }


    useEffect(() => {
        setVisibleSpans(Array(getFinances().days).fill(false))
        fetchExpenseCategories()
        fetchIncomeCategories()
    }, [getFinances()])

    const removeRef = useRef()

    const incomeId = getUrlData().remove_income_id
    const expenseId = getUrlData().remove_expense_id

    const { handleRemove } = useRemoveTransactions(
        //`${ENDPOINTS.SHARED.REMOVE_INCOME}?revenue_id=${incomeId}`,
        transactionRemoveType,
        removeRef,
        transactionType
        //TRANSACTION_TYPE
    )
    function successCB() {
        fetchFinances(getGETParam(financeProvider.filters), getSelectedExpenseTypeId(), getSelectedUserId(), getSelectedClientId(), getSelectedCategoryId())
    }

    useEffect(() => {
        if (incomeId) removeRef.current.open()
        if (expenseId) removeRef.current.open()
    }, [incomeId, expenseId])

    const navigate = useNavigate()

    const { getPrevParams } = usePrevParams()


    function prepareRemove(id, type, endpoint, headingType) {
        setTransactionType(type)
        setTransactionRemoveType(endpoint)
        setHeadingType(headingType)
        if (type === "INCOMES") {
            navigate({
                search: `${getPrevParams()}remove_income_id=${id}`,
            })
        }
        if (type === "EXPENSES") {
            navigate({
                search: `${getPrevParams()}remove_expense_id=${id}`,
            })
        }
    }
    function renderFinanceList() {
        if (loading == true && loadingFinance == null) {
            return <Loader />
        }

        if (!getFinancesEntries().length) {
            return <NoResultsFound text="Не бяха открити приходи и разходи" />
        }

        // if (!getFinances().days) {
        //     return <NoResultsFound text="Не бяха открити приходи и разходи" />
        // }

        function toggleInfo(index) {
            eachDayRefs.current[index].toggle()
            setVisibleSpans(prevVisibleSpans => {
                const updatedVisibleSpans = [...prevVisibleSpans]
                updatedVisibleSpans[index] = !updatedVisibleSpans[index]
                return updatedVisibleSpans
            })
        }

        return (
            <div>
                <div>
                    <Remove
                        ref={removeRef}
                        elementsContent={{
                            heading: headingType,
                        }}
                        accept={handleRemove}
                    /></div>
                <div
                    className="finance-table-div"
                    style={{

                        width: "100%",
                        background: "#f5fbff",
                        border: "1px solid #e1e8f0",
                        borderRadius: "4px",
                        padding: "20px"


                    }}>
                    <div className="fields" style={{ marginBottom: '10px' }}>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="worker_id">Служител</label>
                                <select
                                    name="worker_id"
                                    id="worker_id"
                                    value={getSelectedUserId()}
                                    onChange={handleUserChange}   >
                                    <option value={0}>Всички служители</option>
                                    {getUsers().map((u) => (
                                        <option key={`user-${u.id}`} value={u.id}>
                                            {u.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col">
                                <label htmlFor="order_id">Задача</label>
                                <select
                                    name="order_id"
                                    value={getSelectedCategoryId()}
                                    onChange={handleCategoryChange}                            >
                                    <option value="0">Всички категории</option>
                                    {getCategories().map((c) => (
                                        <optgroup label={c.name} key={`category-${c.id}`}>
                                            {c.subcategories.map((s) => (
                                                <option
                                                    key={`subcategory-${s.service_id}`}
                                                    value={s.service_id}
                                                >
                                                    {s.service_name}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </select>
                            </div>
                            <div className="col">
                                <label htmlFor="category_id">Разход</label>
                                <select
                                    name="category_id"
                                    id="category_id"
                                    value={getSelectedExpenseTypeId()}
                                    onChange={handleExpenseTypeChange}  >
                                    <option value={0}>Разход</option>
                                    {getExpenseCategories().map((e) => (
                                        <option value={e.id}>
                                            {e.name}
                                        </option>))}
                                </select>
                            </div>
                            <div className="col">
                                <label htmlFor="client_id">Клиент</label>
                                <select
                                    name="client_id"
                                    id="client_id"
                                    value={getSelectedClientId()}
                                    onChange={handleClientChange}  >
                                    <option value={0}>Клиент</option>
                                    {getClients().map((i) => (
                                        <option value={i.id}>
                                            {i.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div >
                    {!getFinances().days ? <NoResultsFound text="Не бяха открити приходи и/или разходи" /> :
                        <div>
                            <table className="finance-table">
                                <thead>
                                    <tr className="finance-table">
                                        <th className="finance-table-head finance-table">Дата</th>
                                        <th className="finance-table-head finance-table">Описание</th>
                                        {getIncomeCategories().map((i) =>
                                            <th className="finance-table-head finance-table" style={{ backgroundColor: "#D0F0E6" }} id={`r-${i.id}`}>{i.name}</th>
                                        )}
                                        {getExpenseCategories().map((e) =>
                                            <th className="finance-table-head finance-table" style={{ backgroundColor: "#F7DCDF" }} id={`e-${e.id}`}>{e.name}</th>
                                        )}
                                        <th className="finance-table-head finance-table">Опции</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getFinances().days.map((f) =>
                                        f.transactions.map((t) =>
                                            <tr className="finance-table-row">
                                                <td className="finance-table finance-table-body">{f.date}</td>
                                                {/* <td style={{ border: 'solid' }}>{f.task}</td> */}
                                                {
                                                    Object.values(t).map((tr) =>
                                                        <TableRow tr={tr} handleChange={handleChange} validation={validation} prepareRemove={prepareRemove} loadingFinance={loadingFinance} tableInput={tableInput} switchInput={switchInput} />
                                                    )
                                                }
                                            </tr>
                                        )
                                    )}

                                </tbody>
                            </table>
                            <div>
                                <h4 style={{ marginRight: '15px' }}>
                                    Общо приход: {(getFinances().totalRevenue).toFixed(2)}
                                </h4>
                                <h4 style={{ marginRight: '15px' }}>
                                    Общо разход: {(getFinances().totalExpense).toFixed(2)}
                                </h4>
                                <h4 style={{ marginRight: '15px' }}>
                                    Баланс: {(getFinances().totalIncome).toFixed(2)}
                                </h4>
                            </div>
                        </div>}

                </div >
            </div>

        )
    }
    return <section>{renderFinanceList()}</section>
}

export default FinancesTable