import { useRef } from "react"
import { useParams } from "react-router-dom"
import { createPortal } from "react-dom"

//libraries
import { Tooltip } from "@mui/material"

//hooks
import useFetchBalance from "../hooks/useFetchBalance"
import Loader from "components/app/components/Loader"

//components
import SumsList from "./SumsList"
import AddSum from "./AddSum"
import UpdateSalary from "./UpdateSalary"

function Balance() {
  const sumsListRef = useRef()
  const addSumRef = useRef()
  const updateSalaryRef = useRef()

  const userId = useParams().userId
  const { getBalance, loading, refetchData } = useFetchBalance(userId)

  function viewSums() {
    sumsListRef.current.open()
  }

  function addSum() {
    if (!getBalance().current_salary) return
    addSumRef.current.open()
  }

  function updateSalary() {
    updateSalaryRef.current.open()
  }

  function successfulyAddedSumCB() {
    refetchData()
    sumsListRef.current.refetchData()
  }

  function successfulyUpdatedSalaryCB() {
    refetchData()
    sumsListRef.current.refetchData()
  }

  return (
    <>
      {createPortal(
        <>
          <SumsList
            userId={userId}
            successfulyRemovedSumCB={refetchData}
            ref={sumsListRef}
          />
          <AddSum
            userId={userId}
            successfulyAddedSumCB={successfulyAddedSumCB}
            ref={addSumRef}
          />
          <UpdateSalary
            salaryId={getBalance().id}
            successfulyUpdatedSalaryCB={successfulyUpdatedSalaryCB}
            ref={updateSalaryRef}
            currentSalary={getBalance().salary}
          />
        </>,
        document.body
      )}
      <div className="bottom">
        {loading ? (
          <Loader noPadding={true} />
        ) : (
          <>
            <div className="t row">
              <b>Месечен отчет</b>
              <span onClick={viewSums}>Справки</span>
            </div>
            <div className="b row">
              <ul className="row">
                <Tooltip title="Промяна">
                  <li className="col change-salary" onClick={updateSalary}>
                    <span>Заплата</span>
                    <b>{getBalance().salary} лв.</b>
                  </li>
                </Tooltip>
                <li className="col">
                  <span>Аванс</span>
                  <b>{getBalance().total_advances} лв.</b>
                </li>
                <li className="col">
                  <span>За вземане</span>
                  <b>{getBalance().current_salary} лв.</b>
                </li>
              </ul>
              <Tooltip
                title={
                  !getBalance().current_salary
                    ? "Работникът не може да получи повече пари"
                    : ""
                }
              >
                <div>
                  <button
                    className="btn-add"
                    disabled={!getBalance().current_salary}
                    onClick={addSum}
                  >
                    Добави плащане
                  </button>
                </div>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Balance
