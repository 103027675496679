import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"
import { COMMENT_STATUSES } from "constants/statuses"

function useFetchCommentStatuses() {
    const { fetch, loading } = useFetch()

    const [statuses, setStatuses] = useState([])

    useEffect(() => {
        fetchStatuses()
    }, [])

    function fetchStatuses() {
        fetch({
            endpoint: ENDPOINTS.SHARED.GET_STATUSES,
            method: "get",
        })
            .then((res) => {
                setStatuses(res.data.status || [])
            })
    }

    function getStatuses() {
        const updatedStatuses = statuses.map(s => {
            s['position'] = COMMENT_STATUSES[s.tag].position
            s['icon'] = COMMENT_STATUSES[s.tag].icon

            return s
        })

        updatedStatuses.sort((a, b) => {
            return a.position - b.position
        })

        return updatedStatuses
    }

    return {
        getStatuses,
        loading,
    }
}

export default useFetchCommentStatuses