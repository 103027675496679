//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useFormDataContext } from "../providers/form"

function useAddExtraWorkers(setValues) {
    
    const formData = useFormDataContext()

    const { fetch, loading } = useFetch()

    function handleAddExtra(e, refreshUserListCb) {
        e.preventDefault()

        const data = new FormData(e.target)

        if(data.get('order_id')) addExtra(data, refreshUserListCb)
        addExtraLocally(data.get('name'))
    }

    function addExtra(data, refreshUserListCb) {
        fetch({ endpoint: ENDPOINTS.ADMIN.CREATE_EXTRA_WORKER, method: 'post', data })
            .then(() => {
                refreshUserListCb()
                _onSuccess()
            })
    }

    function addExtraLocally(name) {
        const fields = [
            {
                name,
                extra: 1,
                id: new Date().getTime()
            },        
            ...formData.getFieldForClient("extraWorkers")
        ]

        formData.setFieldsFormats('extraWorkers', fields)

        setValues('')        
    }

    function _onSuccess() {
        setValues('')
    }

    return {
        handleAddExtra,
        loadingExtra: loading
    }
}

export default useAddExtraWorkers