import { forwardRef, useImperativeHandle, useRef } from "react"

//libraries
import { Tooltip } from "@mui/material"

//helpers
import { getUrlData } from "helpers/url"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useChangePassword from "../hooks/useChangePassword"

function ChangePassword({ handleCloseParent }, ref) {
  const formRef = useRef()

  const userId = getUrlData().edit_user_id

  const { handleClose, handleOpen, isOpened } = useOpenWindow()
  const { handleSubmit, loading, handleValidations } =
    useChangePassword(closeAllWindows)

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }))

  function closeAllWindows(){
    handleCloseParent()
    closeWindow()
  }

  function closeWindow() {
    handleClose()
    resetForm()
  }

  function resetForm() {
    formRef.current.reset()
    handleValidations().clear()
  }

  function validate(fieldName) {
    return handleValidations().validate(fieldName)
  }

  return (
    <div
      className={`overlay add-edit-user-overlay ${isOpened() ? "show" : ""}`}
      key={isOpened()}
    >
      <form action="#" autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
        <b>Смяна на паролата</b>
        <Tooltip title="Затваряне">
          <span className="close" onClick={closeWindow}></span>
        </Tooltip>
        <div className="fields">
          <input type="hidden" name="id" value={userId} />
          <div className="col">
            <label htmlFor="oldPassowrd">Текуща парола</label>
            <input
              type="password"
              id="oldPassowrd"
              autoComplete="off"
              placeholder="Въведете текуща парола..."
              name="old_password"
              className={validate("old_password").className}
            />
            {validate("old_password").message ? (
              <p className="error-text">{validate("old_password").message}</p>
            ) : (
              <></>
            )}
          </div>
          <div className="col">
            <label htmlFor="newPassword">Нова парола</label>
            <input
              type="password"
              id="newPassword"
              autoComplete="off"
              placeholder="Въведете нова парола..."
              name="new_password"
              className={validate("new_password").className}
            />
            {validate("new_password").message ? (
              <p className="error-text">{validate("new_password").message}</p>
            ) : (
              <></>
            )}
          </div>
          <div className="col">
            <label htmlFor="confirmPassword">Повторете паролата</label>
            <input
              type="password"
              id="confirmPassword"
              autoComplete="off"
              placeholder="Повтори паролата..."
              name="confirm_password"
              className={validate("confirm_password").className}
            />
            {validate("confirm_password").message ? (
              <p className="error-text">
                {validate("confirm_password").message}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="buttons">
          <button className="btn-secondary" onClick={closeWindow} type="button">
            Отказ
          </button>
          <button className={`btn-primary ${loading ? "loading" : ""}`}>
            Смяна
          </button>
        </div>
      </form>
    </div>
  )
}

export default forwardRef(ChangePassword)
