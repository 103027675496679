import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

//components
import AddEdit from "./components/AddEdit.jsx"
import Remove from "components/app/components/Remove.jsx"
import UsersList from "./components/UsersList.jsx"
import QuestionDeactivate from "components/app/components/Question.jsx"
import QuestionActivate from "components/app/components/Question.jsx"
import DeactivatedUsersList from "./components/DeactivatedUsersList.jsx"

//helpers
import { getUrlData } from "helpers/url.js"

//prividers
import { useUsersDataContext } from "./providers/users.js"

//hooks
import useRemoveUser from "./hooks/useRemoveUser.js"
import useDeactivateUser from "./hooks/useDeactivateUser"
import useActivateUser from "./hooks/useActivateUser.js"

//libraries
import { Tab, Tabs } from "@mui/material"
import { useAuthDataContext } from "providers/auth.js"

function Users() {
  const users = useUsersDataContext()
  const auth = useAuthDataContext()

  const addEditUserWindow = useRef()
  const removeUserRef = useRef()
  const deactivateUserRef = useRef()
  const activateUserRef = useRef()

  const removeUserId = getUrlData().remove_user_id
  const deactivateUserId = getUrlData().deactivate
  const activateUserId = getUrlData().activate

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const { handleRemove } = useRemoveUser(removeUserId, removeUserRef)
  const { handleDeactivate } = useDeactivateUser(
    deactivateUserId,
    deactivateUserRef
  )
  const { handleActivate } = useActivateUser(activateUserId, activateUserRef)

  function showWindow() {
    addEditUserWindow.current.open()
  }

  useEffect(() => {
    if (removeUserId) removeUserRef.current.open()
  }, [removeUserId])

  useEffect(() => {
    if (deactivateUserId) deactivateUserRef.current.open()
  }, [deactivateUserId])

  useEffect(() => {
    if (activateUserId) activateUserRef.current.open()
  }, [activateUserId])

  function renderActiveTab() {
    switch (activeTabIndex) {
      case 0:
        return <UsersList />
      case 1:
        return <DeactivatedUsersList />
    }
  }

  function changeTabIndex(_, index) {
    setActiveTabIndex(index)
  }

  return (
    <>
      {createPortal(
        <>
          <Remove
            ref={removeUserRef}
            elementsContent={{
              heading: "Изтриване на потребителя",
            }}
            accept={handleRemove}
          />
          <QuestionDeactivate
            ref={deactivateUserRef}
            elementsContent={{
              heading: "Искате ли да деактивирате потребителя?",
            }}
            param="deactivate"
            accept={handleDeactivate}
          />
          <QuestionActivate
            ref={activateUserRef}
            elementsContent={{
              heading: "Искате ли да активирате потребителя?",
            }}
            param="activate"
            accept={handleActivate}
          />
          <AddEdit ref={addEditUserWindow} />
        </>,
        document.body
      )}
      <section id="users">
        <div className="screen-top-bar">
          <div className="wrapper row">
            <h1>Потребителски профили</h1>
            {auth.handleAuth().isHeadAdmin() ? (
              <button className="btn-add" onClick={showWindow}>
                Добави потребител
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="users-filter">
          <div className="row top">
            <input
              type="text"
              placeholder="Търсене"
              className="search-by-name"
              name="name"
              autoComplete="off"
              onChange={users.handleSearch().set}
              value={users.handleSearch().get()}
            />
          </div>
        </div>
        <div className="wrapper tabs-wrapper">
          <Tabs value={activeTabIndex} onChange={changeTabIndex}>
            <Tab label="Активни" />
            <Tab label="Неактивни" />
          </Tabs>
        </div>
        {renderActiveTab()}
      </section>
    </>
  )
}

export default Users
