import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"

//helpers
import { getGETParam, getUrlData } from "helpers/url"

//libraries
import moment from "moment-timezone"

//constants
import { SERVER_DATE_FORMAT } from "constants/formats"

export const FinanceDataContext = createContext({})

let fetchScheduleTimeout
const FinanceDataProvider = props => {
    const [refresh, setRefresh] = useState(null)

    const [searchParams, setSearchParams] = useSearchParams()

    const initialStart = moment(searchParams.get('start'), SERVER_DATE_FORMAT).startOf('month') || moment()
    const initialEnd = moment(searchParams.get('end'), SERVER_DATE_FORMAT).endOf('month') || moment().add(1,'months')

    const INITIAL_CALENDAR_DATE = useMemo(() => ({
        start: initialStart,
        end: initialEnd
    }), [])

    const [dates, setDates] = useState(INITIAL_CALENDAR_DATE)

    const [filters, setFilters] = useState({
        ...getUrlData()
    })

    useEffect(() => {
        clearTimeout(fetchScheduleTimeout)
        setSearchParams(getGETParam(filters))
        if (!Object.keys(filters)[0]) handlePeriods().setDefaultParam()
        fetchScheduleTimeout = setTimeout(() => {
            handleRefresh().refreshData()
        }, 500)
    }, [filters])

    function handleFilters() {
        function set(key, value) {
            if (!key) {
                console.error('Липсващ параметър - key')
                return
            }

            setFilters(prev => ({
                ...prev,
                [key]: value
            }))
        }

        function get(key) {
            if (!key) {
                console.error('Липсващ параметър - key')
                return []
            }

            return filters[key]
        }

        // function clearAll() {
        //     setFilters({})
        // }

        return {
            set,
            get,
        }
    }

    function handlePeriods() {
        const getActiveDate = () => dates

        function setPeriod(start, end) {
            setDates(prev => ({
                ...prev,
                start: {
                    ...prev.start,
                    start
                },
                end: {
                    ...prev.end,
                    end
                }
            }))

            setDefaultParam(start, end)
        }

        function setDefaultParam(start, end) {
            if (!start) {
                start = moment().startOf('month').format(SERVER_DATE_FORMAT)
            }
            if (!end) {
                end = moment().endOf('month').format(SERVER_DATE_FORMAT)
            }
            handleFilters().set('start', start)
            handleFilters().set('end', end)
        }

        return {
            setPeriod,
            getActiveDate,
            setDefaultParam
        }
    }

    function handleRefresh() {
        function refreshData() {
            setRefresh(new Date().getTime())
        }

        function getRefreshed() {
            return refresh
        }

        return {
            refreshData,
            getRefreshed
        }
    }

    const exportedData = useMemo(() => {
        return {
            filters,
            handleFilters,
            handlePeriods,
            handleRefresh,
        }
    }, [filters, handleRefresh().getRefreshed()])

    return <FinanceDataContext.Provider value={exportedData} {...props} />
}

export const useFinanceDataContext = () => useContext(FinanceDataContext)

export default FinanceDataProvider