import { useState } from "react"

function useOpenWindow() {
    const [id, setId] = useState(null)
    const [open, setOpen] = useState(false)

    function handleClose() {
        setOpen(false)
        setId(null)

        document.body.style.overflow = 'initial'
    }

    function handleOpen(id) {
        setOpen(true)
        if (id) setId(id)
        document.body.style.overflow = 'hidden'
    }

    function isOpened() {
        return open
    }

    function getId() {
        return id
    }

    return {
        handleClose,
        handleOpen,
        isOpened,
        getId
    }
}

export default useOpenWindow