import { forwardRef, useImperativeHandle, useRef } from "react"

//libraries
import { Tooltip } from "@mui/material"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useCreateSum from "../hooks/useCreateSum"

function AddSum({ userId, successfulyAddedSumCB }, ref) {
  const formRef = useRef()

  const { handleClose, handleOpen, isOpened } = useOpenWindow()
  const { handleSubmit, loading, handleValidations } = useCreateSum(successCB)

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }))

  function closeWindow() {
    handleClose()
    resetForm()
  }

  function resetForm() {
    formRef.current.reset()
    handleValidations().clear()
  }

  function validate(fieldName) {
    return handleValidations().validate(fieldName)
  }

  function successCB() {
    closeWindow()
    successfulyAddedSumCB()
  }

  return (
    <div
      className={`overlay add-edit-user-overlay ${isOpened() ? "show" : ""}`}
      key={isOpened()}
    >
      <form action="#" autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
        <input type="hidden" name="user_id" value={userId} />
        <b>Добавяне на плащане</b>
        <Tooltip title="Затваряне">
          <span className="close" onClick={closeWindow}></span>
        </Tooltip>
        <div className="fields">
          <div className="row">
            <div className="col">
              <label htmlFor="advances">Сума</label>
              <input
                type="number"
                name="advances"
                id="advances"
                className={validate("advances").className}
                min="1"
                step="1"
              />
              {validate("advances").message ? (
                <p className="error-text">{validate("advances").message}</p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="description">Описание</label>
              <textarea
                name="description"
                id="description"
                className={validate("description").className}
              ></textarea>
              {validate("description").message ? (
                <p className="error-text">{validate("description").message}</p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="buttons">
          <button className="btn-secondary" onClick={closeWindow} type="button">
            Отказ
          </button>
          <button className={`btn-primary ${loading ? "loading" : ""}`}>
            Добави
          </button>
        </div>
      </form>
    </div>
  )
}

export default forwardRef(AddSum)
