//images
import splashImage from "assets/images/app/splash.png"

function InitialScreen() {
  return (
    <section id="splash">
      <div className="wrapper">
        <img src={splashImage} alt="" />
      </div>
    </section>
  )
}

export default InitialScreen
