import { useNavigate } from "react-router-dom"
import { useRef, useState } from "react"

//images
import clockIcon from "assets/images/schedule/icons/clock.svg"
import locationIcon from "assets/images/schedule/icons/location.svg"
import workerIcon from "assets/images/schedule/icons/worker.svg"
import clockDarkIcon from "assets/images/schedule/icons/clock-dark.svg"
import pictureIcon from "assets/images/schedule/icons/picture.svg"
import micIcon from "assets/images/schedule/icons/mic.svg"
import pdfIcon from "assets/images/schedule/icons/pdf.svg"
import invoiceIcon from "assets/images/app/header/icons/invoice.svg"

//constatns
import { CATEGORY_COLORS } from "constants/colors"
import { CATEGORIES_LIST } from "constants/categories"
import {
  SERVER_DATE_AND_HOUR_FORMAT,
  SERVER_DATE_FORMAT,
} from "constants/formats"

//libraries
import { Tooltip } from "@mui/material"
import moment from "moment-timezone"

//components
import Options from "./Options"
import Loader from "components/app/components/Loader"
import AddInvoice from "components/invoices/components/AddInvoice"

//helpers
import renderSelectedWorkers from "../helpers/workerNamesParse"

//hooks
import useFetchProtocol from "../hooks/useFetchProtocol"

//prodviders
import FormDataProvider from "components/invoices/providers/form"

function DailyTasksList({
  tasks,
  hideTime,
  handleScrollList,
  loadingMore,
  hideOptions,
}) {
  //const { loading1, createInvoice } = useFinishInvoice()
  const { fetchProtocol } = useFetchProtocol()
  const navigate = useNavigate()
  const invoiceRef = useRef()

  function addInvoice(taskId) {
    invoiceRef.current.open(taskId)
}

  // function handleInvoice(taskId) {
  //   createInvoice(taskId)
  // }

  function getFormattedHour(date) {
    const formattedHour = moment(date, SERVER_DATE_AND_HOUR_FORMAT).format(
      "HH:mm"
    )

    return formattedHour
  }

  function getFormattedDate(date) {
    const formattedDate = moment(date, SERVER_DATE_AND_HOUR_FORMAT).format(
      "DD.MM.YY"
    )

    return formattedDate
  }

  function viewComments(taskId) {
    navigate(`?edit_task_id=${taskId}&task_tab_index=3`)
  }

  function viewUploads(taskId) {
    navigate(`?edit_task_id=${taskId}`)
  }

  function getMergedWorkers(t) {
    return [...t.worker_task, ...t.part_time_workers] || []
  }

  return (
    <div>
      <FormDataProvider>
        <AddInvoice
          ref={invoiceRef}
        />
      </FormDataProvider>
      <ul className="daily-tasks-list" onScroll={handleScrollList}>
        {tasks.map((t) => (
          <li key={`t-${t.id}`}>
            {!hideTime ? <time>{getFormattedHour(t.start)}</time> : <></>}
            <div
              className="task"
              style={{
                backgroundColor: CATEGORY_COLORS[t.category.tag].BACKGROUND,
              }}
            >
              {hideOptions || t.is_complete ? (
                <button
                  className="download-protocol btn-secondary"
                  style={{fontSize:'13px'}}
                  onClick={() => {
                    fetchProtocol(t.id)
                  }}
                >
                  <img 
                  src={pdfIcon} 
                  style={{paddingLeft:'10px', paddingRight:'8px', height:'25px'}}
                  alt="" />
                  Изтегли протокол
                </button>
              ) : (
                <Options taskId={t.id} />
              )}
              {t.is_complete ? (
                <button
                  style={{ right: '165px', paddingRight:'8px', fontSize:'13px' }}
                  className="download-protocol btn-secondary"
                  onClick={() => addInvoice(t.id)}                  
                >
                  <img 
                  style={{paddingLeft:'10px',marginRight:'15px', height:'25px'}} 
                  src={invoiceIcon} 
                  alt="" />                 
                  Издай фактура
                </button>
              ) : (
                <></>
              )}
              <div className="top">
                <div className="left">
                  <time
                    style={{
                      backgroundColor: CATEGORY_COLORS[t.category.tag].TIME,
                    }}
                  >
                    <img src={clockIcon} alt="" />
                    {!t.start ? (
                      <>Без дата и час</>
                    ) : (
                      <>
                        {`от: ${getFormattedDate(t.start)}г., ${getFormattedHour(
                          t.start
                        )} ч.`}
                        <br />
                        {t.end
                          ? `до: ${getFormattedDate(t.end)}г., ${getFormattedHour(
                            t.end
                          )} ч.`
                          : ""}
                      </>
                    )}
                  </time>
                  <Tooltip title={t.is_complete ? "Задачата е завършена" : ""}>
                    <span
                      style={{
                        backgroundColor: CATEGORY_COLORS[t.category.tag].ICON,
                      }}
                      className={t.is_complete ? "is-complete" : ""}
                    >
                      <img src={CATEGORIES_LIST[t.category.tag].icon} alt="" />
                    </span>
                  </Tooltip>
                </div>
                <div className="right">
                  <div className="col extra-info-col">
                    <h5>
                      {t.favours.service_name}
                      {t.comment_count ? (
                        <Tooltip title="Виж коментарите">
                          <button
                            className="comments"
                            onClick={() => {
                              viewComments(t.id)
                            }}
                          >
                            {t.comment_count}
                          </button>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </h5>
                    <p>
                      Описание: <b>{t?.description || "-"}</b>
                    </p>
                    {/* <ul className="extra-info">
                    {(t?.service_order || []).map((s) => (
                      <li key={`question-${s.id}`}>
                        {s.service_note.label}: <b>{s.text || "-"}</b>
                      </li>
                    ))}
                  </ul> */}
                    {t.admin_photo_count || t.audio_clip_count ? (
                      <ul className="files">
                        {t.admin_photo_count ? (
                          <li>
                            <Tooltip title="Виж изображенията">
                              <button
                                onClick={() => {
                                  viewUploads(t.id)
                                }}
                              >
                                <img src={pictureIcon} alt="" />
                                {t.admin_photo_count}
                              </button>
                            </Tooltip>
                          </li>
                        ) : (
                          <></>
                        )}
                        {t.audio_clip_count ? (
                          <li>
                            <Tooltip title="Чуй аудио записите">
                              <button
                                onClick={() => {
                                  viewUploads(t.id)
                                }}
                              >
                                <img src={micIcon} alt="" />
                                {t.audio_clip_count}
                              </button>
                            </Tooltip>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col">
                    <h6>Изпълнител</h6>
                    <ul>
                      <Tooltip
                        title={
                          getMergedWorkers(t).length > 20 &&
                            getMergedWorkers(t).length > 0
                            ? renderSelectedWorkers(
                              getMergedWorkers(t),
                              getMergedWorkers(t).length
                            )
                            : ""
                        }
                      >
                        <li>
                          <img src={workerIcon} alt="" />
                          {renderSelectedWorkers(getMergedWorkers(t), 20) ||
                            "Няма работници"}
                        </li>
                      </Tooltip>
                      <li>
                        <img src={clockDarkIcon} alt="" />
                        {!t.start && !t.end ? (
                          <>Без краен час</>
                        ) : (
                          <>
                            {`от: ${getFormattedDate(
                              t.start
                            )}г., ${getFormattedHour(t.start)} ч.`}
                            <br />
                            {t.end
                              ? `до: ${getFormattedDate(
                                t.end
                              )}г., ${getFormattedHour(t.end)} ч.`
                              : ""}
                          </>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <h6>Клиент</h6>
                    <ul>
                      <li>{t.client.name}</li>
                      <li>тел. {t.client.number}</li>
                      <li>
                        <Tooltip title="Към адреса">
                          <a
                            href={`https://www.google.com/maps/place/${t.client.location}`}
                            target="_blank"
                            className="link"
                          >
                            <img src={locationIcon} alt="" />
                            {t.client.location}
                          </a>
                        </Tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* {t.description ? (
              <>
                <hr
                  style={{
                    backgroundColor: CATEGORY_COLORS[t.category.tag].TIME,
                  }}
                />
                <p className="bottom">{t.description}</p>
              </>
            ) : (
              <></>
            )} */}
            </div>
          </li>
        ))}
        {loadingMore ? (
          <li>
            <Loader />
          </li>
        ) : (
          <></>
        )}
      </ul>
    </div>
  )
}

export default DailyTasksList
