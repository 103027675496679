import { useEffect, useRef, useState } from "react"

//hooks
import useFetchSettlements from "../hooks/useFetchSettlements"
import useSetClientLocation from "../hooks/useSetClientLocation"

//libraries
import { ClickAwayListener, Tooltip } from "@mui/material"

//images
import editActiveIcon from "assets/images/schedule/icons/edit-active.svg"
import EditAddress from "./EditAddress"

//providers
import { useFormDataContext } from "../providers/form"

function ClientAddressField({
  clientId,
  location,
  locationId,
  locationsList,
  handleChange,
  viewFromHistory,
  validate,
}) {
  const formData = useFormDataContext()

  const [locations, setLocations] = useState([])

  const inputSettlementRef = useRef(null)
  const editAddressRef = useRef(null)

  const { changeLocation, isSelected, isHidden, setIsHidden } =
    useSetClientLocation(locationId)

  const {
    getSettlements,
    inputSettlement,
    isSingleSettlementInListSameAsInput,
    loading,
  } = useFetchSettlements()

  useEffect(() => {
    setLocations(locationsList)
  }, [locationsList])

  function focusLocationField() {
    inputSettlementRef.current.focus()
  }

  function changeLocationFromEditAddress(addressId, addressName) {
    const updatedLocations = [...locations].map((l) => {
      if (addressId === l.id) {
        l["location"] = addressName

        if (locationId === l.id) {
          formData.setFieldsFormats("clientLocation", addressName)
        }
      }

      return l
    })

    setLocations(updatedLocations)
  }

  function showDropdown() {
    return Boolean(!viewFromHistory && !isHidden)
  }

  function showSettlementDropdown() {
    return Boolean(
      getSettlements().length &&
        !isSingleSettlementInListSameAsInput() &&
        !locationId
    )
  }

  return (
    <>
      <EditAddress
        ref={editAddressRef}
        changeLocationFromEditAddress={changeLocationFromEditAddress}
      />
      <label htmlFor="client-location">Адрес</label>
      <div className="holder">
        {/* <ClickAwayListener
          onClickAway={() => {
            setIsHidden(true)
          }}
        > */}
        {/* Формата е сложена, защото без нея излиза autoComplete от браузъра */}
        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
          onClick={() => {
            setIsHidden(false)
          }}
        >
          <input
            type="text"
            name="clientLocation"
            // id="client-location"
            placeholder="Въведете Адрес"
            value={location}
            onChange={handleChange}
            className={`${validate("location").className} ${
              viewFromHistory ? "cursor-default" : ""
            }`}
            readOnly={viewFromHistory || locationId}
            onFocus={() => {
              setIsHidden(false)
            }}
            onBlur={() => {
              setTimeout(() => {
                setIsHidden(true)
              }, 250)
            }}
            autoComplete="off"
            ref={inputSettlementRef}
          />
          {locationId ? (
            <Tooltip title="Премахване на избрания адрес">
              <span
                className="remove"
                onClick={() => {
                  changeLocation(null)
                  focusLocationField()
                }}
              ></span>
            </Tooltip>
          ) : (
            <></>
          )}
        </form>
        {/* </ClickAwayListener> */}
        {showDropdown() ? (
          showSettlementDropdown() ? (
            <div className={`dropdown ${loading ? "loading" : ""}`}>
              <ul className="settlements">
                {getSettlements().map((s) => (
                  <li
                    key={`s-${s.id}`}
                    onClick={() => {
                      inputSettlement(s.name)
                    }}
                  >
                    {s.name}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="dropdown">
              <ul className="addresses">
                {locations.map((l) => (
                  <li
                    onClick={(e) => {
                      if (
                        isSelected(l.id) ||
                        e.target.classList.contains("edit")
                      )
                        return

                      changeLocation(l)
                    }}
                    className={isSelected(l.id) ? "active" : ""}
                    key={l.id}
                  >
                    {l.location}
                    <Tooltip title="Редакция на адреса">
                      <span
                        className="edit"
                        onClick={() => {
                          editAddressRef.current.open(l)
                        }}
                      >
                        <img src={editActiveIcon} alt="" />
                      </span>
                    </Tooltip>
                  </li>
                ))}
              </ul>
              {locations.length && clientId ? (
                <ul className="add-new-address">
                  <li
                    onClick={() => {
                      changeLocation(null)
                      focusLocationField()
                    }}
                  >
                    <button className="btn-add">Нов адрес</button>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>
          )
        ) : (
          <></>
        )}
      </div>
      <input type="hidden" name="clientLocationId" value={locationId} />
      {validate("location").message ? (
        <p className="error-text">{validate("location").message}</p>
      ) : (
        <></>
      )}
    </>
  )
}

export default ClientAddressField
