import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { NavLink } from "react-router-dom"

//components
import Filters from "./components/Filters"
import Periods from "./components/Periods"
import WeeklyTasksList from "./components/WeeklyTasksList"
import DailyTasksList from "./components/DailyTasksList"
import AddEdit from "./components/AddEdit"
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"
import Remove from "components/app/components/Remove"

//providers
import { useScheduleDataContext } from "./providers/schedule"
import FormDataProvider from "./providers/form"

//helpers
import { getUrlData } from "helpers/url"

//hooks
import useRemoveTask from "./hooks/useRemoveTask"
import useFetchAwaitingTasksAmount from "./hooks/useFetchAwaitingTasksAmount"

function Schedule() {
  const addEditRef = useRef()
  const removeTaskRef = useRef()

  const taskId = getUrlData().remove_task_id

  const scheduleData = useScheduleDataContext()
  const sh = scheduleData.handlePeriods()

  const { getAwaitingCount, awaitingCount } = useFetchAwaitingTasksAmount()

  const { handleRemove } = useRemoveTask(taskId, removeTaskRef)
  
  useEffect(() => {
    if (taskId) removeTaskRef.current.open()
  }, [taskId])

  useEffect(() => {
    getAwaitingCount()
  },[getAwaitingCount])

  console.log()

  function renderSchedule() {
    if (scheduleData.loading) {
      return <Loader />
    }

    if (!scheduleData.getTasks().length) {
      return <NoResultsFound text="Не бяха открити задачи" />
    }

    // if (scheduleData.isAwaintingPage()) {
    //   return <DailyTasksList hideTime={true} tasks={scheduleData.getTasks()} />
    // }

    switch (sh.getCalendarType()) {
      case "weekly":
        return <WeeklyTasksList tasks={scheduleData.getTasks()} />
      case "daily":
        return <DailyTasksList tasks={scheduleData.getTasks()} />
      case "awaiting":
        return (
          <DailyTasksList hideTime={true} tasks={scheduleData.getTasks()} />
        )
      default:
        return (
          <div className="no-results">
            <b>Грешка при зареждането на Графика.</b>
          </div>
        )
    }
  }

  function createTask() {
    addEditRef.current.open()
  }

  return (
    <>
      {createPortal(
        <>
          <FormDataProvider>
            <AddEdit ref={addEditRef} />
          </FormDataProvider>
          <Remove
            ref={removeTaskRef}
            elementsContent={{
              heading: "Изтриване на задачата",
            }}
            accept={handleRemove}
            closeEditWhenFinish={true}
          />
        </>,
        document.body
      )}
      <section id="schedule">
        <div className="screen-top-bar">
          <div className="wrapper row">
            <ul className="tabs-big">
              <li
                onClick={() => {
                  sh.setCalendarType("daily")
                }}
                className={!scheduleData.isAwaintingPage() ? "active" : ""}
              >
                График на задачите
              </li>
              <li
                onClick={() => {
                  sh.setCalendarType("awaiting")
                }}
                className={scheduleData.isAwaintingPage() ? "active" : ""}
              >
                Изчакващи задачи {getAwaitingCount() > 0 ? '('+getAwaitingCount()+')' : ""}
              </li>
            </ul>
            <button className="btn-add" onClick={createTask}>
              Добави задача
            </button>
          </div>
        </div>
        <Filters />
        {!scheduleData.isAwaintingPage() ? <Periods /> : <></>}
        {renderSchedule()}
      </section>
    </>
  )
}

export default Schedule
