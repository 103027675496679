//images
import okIcon from 'assets/images/schedule/icons/ok.svg'
import imposibleIcon from 'assets/images/schedule/icons/imposible.svg'
import problemIcon from 'assets/images/schedule/icons/problem.svg'
import otherIcon from 'assets/images/schedule/icons/other.svg'

const STATUSES_LIST = ['OK', 'IMPOSIBLE', 'PROBLEM', 'OTHER']

export const COMMENT_STATUSES = {
    [STATUSES_LIST[0]]: {
        icon: okIcon,
        position: 1
    },
    [STATUSES_LIST[1]]: {
        icon: imposibleIcon,
        position: 2
    },
    [STATUSES_LIST[2]]: {
        icon: problemIcon,
        position: 3
    },
    [STATUSES_LIST[3]]: {
        icon: otherIcon,
        position: 4
    }
}