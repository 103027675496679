import { useEffect } from "react"

//images
import cardIcon from "assets/images/schedule/icons/card.svg"
import walletIcon from "assets/images/schedule/icons/wallet.svg"

//libraries
import { FormControlLabel, Radio } from "@mui/material"

//providers
import { useAppContext } from "providers/app"
import { useFormDataContext } from "../providers/form"

function TaskPayment({ viewFromHistory }) {
  const app = useAppContext()
  const formData = useFormDataContext()

  const paymentMethods = app.handlePaymentMethods().get()
  const selectedMethodId = formData.getFieldForClient("paymentMethodId")
  const sum = formData.getFieldForClient("sum")

  useEffect(() => {
    if (!selectedMethodId) {
      formData.setFieldsFormats("paymentMethodId", paymentMethods[0].id)
    }
  }, [])

  function handleChange(e) {
    if (viewFromHistory) return
    const { name, value } = e.target

    formData.setFieldsFormats(name, Number(value))
  }

  return (
    <div className="row payment-holder">
      <div className="col">
        <h6>
          <img src={cardIcon} alt="" />
          Метод на плащане
        </h6>
        <ul>
          {paymentMethods.map((p) => (
            <li key={p.id}>
              <FormControlLabel
                control={
                  <Radio className={viewFromHistory ? "cursor-default" : ""} />
                }
                label={p.name}
                value={p.id}
                name="paymentMethodId"
                onChange={handleChange}
                checked={selectedMethodId === p.id}
                className={viewFromHistory ? "cursor-default" : ""}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="col">
        <h6>
          <img src={walletIcon} alt="" />
          Сума (опционално)
        </h6>
        <input
          type="number"
          name="sum"
          value={sum}
          onChange={handleChange}
          placeholder="Въведете сума..."
          className={viewFromHistory ? "cursor-default" : ""}
          readOnly={viewFromHistory}
        />
      </div>
    </div>
  )
}

export default TaskPayment
