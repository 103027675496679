import { useNavigate, useParams } from "react-router-dom"

//libraries
import { Tooltip } from "@mui/material"

//components
import Transactions from "./components/Transactions"
import Tasks from "./components/Tasks"
import PersonalData from "./components/PersonalData"
import Loader from "components/app/components/Loader"
import Balance from "./components/Balance"

//hooks
import useFetchUser from "./hooks/useFetchUser"

//providers
import ScheduleDataProvider from "components/schedule/providers/schedule"

function User() {
  const userId = useParams().userId
  const navigate = useNavigate()

  const { getUser, loadingUser } = useFetchUser(userId)

  function goBack() {
    navigate("/users")
  }

  return (
    <>
      <section id="user">
        <div className="screen-top-bar">
          <div className="wrapper row">
            <div className="row">
              <Tooltip title="Назад">
                <button className="back" onClick={goBack}></button>
              </Tooltip>
              {loadingUser ? (
                <Loader noPadding={true} />
              ) : (
                <h1>{getUser().name}</h1>
              )}
            </div>
          </div>
        </div>
        <div className="user-content">
          <div className="wrapper">
            <div className="row">
              <div className="personal-data">
                <PersonalData userData={getUser()} loading={loadingUser} />
                <Balance />
              </div>
              <Transactions />
            </div>
            <div className="screen-top-bar">
              <div className="wrapper row">
                <h2>Списък със задачи</h2>
              </div>
            </div>
            <ScheduleDataProvider>
              <Tasks userId={userId} />
            </ScheduleDataProvider>
          </div>
        </div>
      </section>
    </>
  )
}

export default User
