import { forwardRef, useImperativeHandle } from "react"

//hooks
import useOpenWindow from "hooks/useOpenWindow"

//libraries
import { Tooltip } from "@mui/material"
import moment from "moment-timezone"

//providers
import useWorkerSchedule from "../hooks/useWorkersSchedule"

//constants
import { SERVER_DATE_FORMAT } from "constants/formats"

//components
import WorkersSchedule from "./WorkersSchedule"
import Periods from "components/history/components/Periods"

function SelectWorkerAndDate(_, ref) {
  const { handlePeriods } = useWorkerSchedule()
  const { handleClose, handleOpen, isOpened } = useOpenWindow()

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }))

  function getSelectedDate() {
    const selectedDate = `${
      handlePeriods().getMonthDay().dayAsDate
    }.${handlePeriods().getActiveYear()}`

    return moment(selectedDate, "DD.MM.YYYY").format(SERVER_DATE_FORMAT)
  }

  return (
    <div
      className={`overlay select-worker-and-date-overlay ${
        isOpened() ? "show" : ""
      }`}
    >
      <div className="modal">
        <b>График на работниците</b>
        <Tooltip title="Затваряне">
          <span className="close" onClick={handleClose}></span>
        </Tooltip>

        <Periods handlePeriods={handlePeriods} />
        {isOpened() ? (
          <WorkersSchedule
            getSelectedDate={getSelectedDate}
            handleClose={handleClose}
          />
        ) : (
          <></>
        )}

        <div className="buttons">
          <button className="btn-secondary" onClick={handleClose}>
            Назад
          </button>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(SelectWorkerAndDate)
