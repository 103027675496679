export const DAYS = {
    1: "Понеделник",
    2: 'Вторник',
    3: 'Сряда',
    4: 'Четвъртък',
    5: 'Петък',
    6: 'Събота',
    7: 'Неделя'
}

export const DAYS_SHORTEN = {
    1: "Пн.",
    2: 'Вт.',
    3: 'Ср.',
    4: 'Чт.',
    5: 'Пт.',
    6: 'Сб.',
    7: 'Нд.'
}