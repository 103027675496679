//components
import PeriodRelatedFilters from "./PeriodRelatedFilters"
import Sort from "./Sort"
import TaskRelatedFilters from "./TaskRelatedFilters"

function Filters() {
  return (
    <div className="schedule-filter">
      <div className="row top">
        <TaskRelatedFilters />
        <Sort />
      </div>
      <PeriodRelatedFilters />
    </div>
  )
}

export default Filters
