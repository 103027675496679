//images
import clockIcon from "assets/images/track/icons/clock.svg"

//plugins
import moment from "moment-timezone"

//providers
import { useFilterDataContext } from "../providers/filter"

//components
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"

function Activities({ loading }) {
  const filter = useFilterDataContext()

  const { setHour, getHour, getUser } = filter

  function getParsedHour(uglyHour) {
    const prittyHour = moment(uglyHour, "YYYY-MM-DDTHH:mm:ss").format("HH:mm")

    return prittyHour
  }

  function getCoordinatesByHours() {
    return getUser()?.coordinates || []
  }

  function handleChange(activity) {
    if (getHour()?.id === activity.id) return

    setHour(activity)
  }

  function isActive(activityId) {
    if (activityId === getHour()?.id) {
      return true
    }

    return false
  }

  if (loading) {
    return <Loader />
  }

  if (!loading & !getCoordinatesByHours().length) {
    return <NoResultsFound text="Няма дейности за избраните филтри" />
  }

  if (!getUser()) {
    return <></>
  }

  return (
    <ul>
      {getCoordinatesByHours().map((d) => (
        <li
          key={d.id}
          onClick={() => {
            handleChange(d)
          }}
          className={isActive(d.id) ? "active" : ""}
        >
          <img src={clockIcon} />
          {getParsedHour(d.updated_at)}
        </li>
      ))}
    </ul>
  )
}

export default Activities
