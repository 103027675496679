import { forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import { useNavigate } from "react-router-dom"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useFetchSumsList from "../hooks/useFetchSumsList"

//libraries
import { Tooltip } from "@mui/material"

//components
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"
import Remove from "components/app/components/Remove"

//images
import removeIcon from "assets/images/schedule/icons/remove.svg"
import infoIcon from "assets/images/users/icons/info.svg"

//helpers
import { getUrlData } from "helpers/url"

function SumsList({ userId, successfulyRemovedSumCB }, ref) {
  const sumId = getUrlData().remove_sum_id?.[0] || null

  const navigate = useNavigate()
  const removeSumRef = useRef()

  const { getSumsList, loading, removeSum, refetchData } =
    useFetchSumsList(userId)
  const { handleClose, handleOpen, isOpened } = useOpenWindow()

  useEffect(() => {
    if (sumId) removeSumRef.current.open()
  }, [sumId])

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: closeWindow,
    refetchData,
  }))

  const closeWindow = () => {
    handleClose()
  }

  async function handleRemove() {
    await removeSum(sumId)
    removeSumRef.current.close()
    successfulyRemovedSumCB()
  }

  return (
    <>
      <div className={`overlay sums-list-overlay ${isOpened() ? "show" : ""}`}>
        <div className="modal">
          <b>Списък с плащания</b>
          <span className="close" onClick={closeWindow}></span>
          {loading ? (
            <Loader />
          ) : !getSumsList().length ? (
            <NoResultsFound text="Няма плащания за текущия месец" />
          ) : (
            <ul>
              {getSumsList().map((s) => (
                <li key={s.id} className="row">
                  <h6>{s.advances.toFixed(2)} лв.</h6>
                  <Tooltip title={s.description}>
                    <span className="info">
                      <img src={infoIcon} alt="" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Премахване на плащането">
                    <span
                      className="remove"
                      onClick={() => {
                        navigate({
                          search: `?remove_sum_id=${s.id}`,
                        })
                      }}
                    >
                      <img src={removeIcon} alt="" />
                    </span>
                  </Tooltip>
                </li>
              ))}
            </ul>
          )}
          <div className="buttons">
            <button className="btn-secondary" onClick={closeWindow}>
              Назад
            </button>
          </div>
        </div>
      </div>
      <Remove
        ref={removeSumRef}
        elementsContent={{
          heading: "Изтриване на плащането",
        }}
        accept={handleRemove}
      />
    </>
  )
}

export default forwardRef(SumsList)
