//images
import checkIcon from "assets/images/app/icons/check.svg"

//providers
import { useAppContext } from "providers/app"

const ErrorMessage = () => {
  const app = useAppContext()
  const { show, text } = app.messages.error

  return (
    <div className={`error-message ${show ? "show" : ""}`}>
      <img src={checkIcon} />
      {text || "Възникна грешка!"}
    </div>
  )
}

export default ErrorMessage
