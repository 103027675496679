//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useFormDataContext } from "../providers/form"

function useRemoveExtraWorker(extraId, closeRemoveWindow, refreshUserListCb, taskId) {

    const formData = useFormDataContext()

    const { fetch } = useFetch()

    function handleDelete() {
        if(taskId) _deleteExtraWorker()
        _deleteExtraWorkerLocally()
    }

    function _deleteExtraWorker() {
        fetch({ endpoint: `${ENDPOINTS.ADMIN.REMOVE_EXTRA_WORKER}?extra_id=${extraId}`, method: 'delete' })
            .then(() => {
                refreshUserListCb()
            })
            .finally(()=>{
                closeRemoveWindow()
            })
    }

    function _deleteExtraWorkerLocally() {
        const extraWorkers = formData.getFieldForClient("extraWorkers").filter(e =>  e.id != extraId)
        formData.setFieldsFormats('extraWorkers', extraWorkers)
        closeRemoveWindow()
    }        

    return {
        handleDeleteExtra: handleDelete
    }
}

export default useRemoveExtraWorker