import { useNavigate } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { Tooltip } from "@mui/material"

//images
import incomesIcon from "assets/images/users/icons/incomes.svg"
import expensesIcon from "assets/images/users/icons/expenses.svg"

//hooks
import useFetchFinances from "../hooks/useFetchFinances"
import usePrevParams from "hooks/usePrevParams"
import useRemoveTransactions from "../hooks/useRemoveTransactions"

//components
import Remove from "components/app/components/Remove"

//helpers
import { getUrlData } from "helpers/url"

//constants
import { ENDPOINTS } from "constants/endpoints"

//libraries
import moment from "moment-timezone"
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"
import EachDay from "./EachDay"

function FinancesList() {

  const eachDayRefs = useRef([])

  const { loading, getFinances, getFinancesEntries, setLoading } =
    useFetchFinances()
  const [transactionType, setTransactionType] = useState()
  const [transactionRemoveType, setTransactionRemoveType] = useState()
  const [headingType, setHeadingType] = useState()

  const [visibleSpans, setVisibleSpans] = useState()

  useEffect(() => {
    setVisibleSpans(Array(getFinances().days).fill(false))
  },[getFinances()])

  const removeRef = useRef()

  const incomeId = getUrlData().remove_income_id
  const expenseId = getUrlData().remove_expense_id

  const { handleRemove } = useRemoveTransactions(
    //`${ENDPOINTS.SHARED.REMOVE_INCOME}?revenue_id=${incomeId}`,
    transactionRemoveType,
    removeRef,
    transactionType
    //TRANSACTION_TYPE
  )

  useEffect(() => {
    if (incomeId) removeRef.current.open()
    if (expenseId) removeRef.current.open()
  }, [incomeId, expenseId])

  const navigate = useNavigate()

  const { getPrevParams } = usePrevParams()

  function prepareRemove(id, type, endpoint, headingType) {
    setTransactionType(type)
    setTransactionRemoveType(endpoint)
    setHeadingType(headingType)
    if (type === "INCOMES") {
      navigate({
        search: `${getPrevParams()}remove_income_id=${id}`,
      })
    }
    if (type === "EXPENSES") {
      navigate({
        search: `${getPrevParams()}remove_expense_id=${id}`,
      })
    }
  }
  function renderFinanceList() {
    if (loading) {
      return <Loader />
    }

    if (!getFinancesEntries().length) {
      return <NoResultsFound text="Не бяха открити приходи и разходи" />
    }

    function toggleInfo(index) {
      eachDayRefs.current[index].toggle()
      setVisibleSpans(prevVisibleSpans => {
        const updatedVisibleSpans = [...prevVisibleSpans]
        updatedVisibleSpans[index] = !updatedVisibleSpans[index]
        return updatedVisibleSpans
      })      
    }

    return (
      <ul>
        <div>
          <Remove
            ref={removeRef}
            elementsContent={{
              heading: headingType,
            }}
            accept={handleRemove}
          />
          <ul>
            {getFinances().days.map((f, i) =>
              <li key={`outter-0-${i}`} className={`day-container ${(f.dailyTotal || 0) < 0 ? "red" : ""}`}>
                <div
                  className="row"
                  onClick={() => toggleInfo(i)}
                >
                  <time>
                    {f.tasks ? (
                      moment(f.date).format("Do MMMM YYYY")
                    ) : (
                      <></>
                    )}
                  </time>
                  {!visibleSpans[i] && <span>{(f.dailyTotal || 0).toFixed(2)} лв.</span>}
                </div>
                <EachDay key={`outter-1-${i}`} data={f.tasks} dailyTotal={f.dailyTotal} ref={el => eachDayRefs.current[i] = el} prepareRemove={prepareRemove} />
              </li>
            )}
          </ul>
          <div className="total">
            <b>Обща стойност:</b>
            <span className={getFinances().monthlyTotal.toFixed(2) < 0 ? 'red' : ''}>{getFinances().monthlyTotal.toFixed(2)} лв.</span>
          </div>
        </div>
      </ul>
    )
  }
  return <section>{renderFinanceList()}</section>
}

export default FinancesList

