import { useRef } from "react"
import AddInvoice from "./components/AddInvoice"
import FormDataProvider from "./providers/form"

function Invoices() {
    const invoiceRef = useRef()

    function addInvoice() {
        invoiceRef.current.open()
    }
    return (
        <section id="invoices">
            <div className="screen-top-bar">
                <div className="wrapper row">
                    <h1>
                        Фактури
                    </h1>
                    <div>
                        <FormDataProvider>
                            <AddInvoice
                                ref={invoiceRef}
                            />
                        </FormDataProvider>
                        <button className="btn-add" style={{ backgroundColor: '#3BC870' }} onClick={() => addInvoice()}>
                            Добави фактура
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Invoices