import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"
import { useAwaitingDataContext } from "../providers/awaiting"
import { useCommentsDataContext } from "../providers/comments"


function useFetchAwaitingTasksAmount() {
    const awaitingData = useAwaitingDataContext()
    const commentsData = useCommentsDataContext()

    const { fetch, loading } = useFetch()

    const [awaitingCount, setAwaitingCount] = useState('')

    useEffect(() => {
        fetchAwaitingCount()
    }, [awaitingCount])

    function fetchAwaitingCount() {
        fetch({
            endpoint: `${ENDPOINTS.ADMIN.AWAITING_COUNT}`,
            method: "get",
        })
            .then((res) => {
                setAwaitingCount(res.data)
            })
    }

    function getAwaitingCount() {
        return awaitingCount
    }

    function refetchData() {
        fetchAwaitingCount()
    }


    return {
        getAwaitingCount,
        refetchData,
        awaitingCount
    }
}

export default useFetchAwaitingTasksAmount