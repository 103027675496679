import { forwardRef, useEffect, useImperativeHandle, useState } from "react"

//libraries
import useOpenWindow from "hooks/useOpenWindow"

//libraries
import { Tooltip } from "@mui/material"

const INITIALSTATE = {
  activeIndex: 0,
}
const Gallery = ({ prepareRemoveImage, getImages, viewFromHistory }, ref) => {
  const [state, setState] = useState(INITIALSTATE)
  const { handleClose, handleOpen, isOpened } = useOpenWindow()

  useImperativeHandle(ref, () => ({
    open: (activeIndex) => {
      handleData().set(activeIndex)
      handleOpen()
    },
    close: () => {
      handleClose()
    },
  }))

  useEffect(() => {
    if (getImages().length === 0) handleClose()

    handleData().set(state.activeIndex - 1 < 0 ? 0 : state.activeIndex - 1)
  }, [getImages()])

  function closeWindow() {
    handleClose()
    setState(INITIALSTATE)
  }

  function handleData() {
    function set(activeIndex = 0) {
      setState((prev) => ({
        ...prev,
        activeIndex,
      }))
    }

    function clear() {
      set(null)
    }

    function get() {
      function list() {
        return getImages() || []
      }

      function activeImage() {
        return list()[state.activeIndex]?.url
      }

      return {
        list,
        activeImage,
      }
    }

    return {
      set,
      clear,
      get,
    }
  }

  function handleNext() {
    setState((prev) => ({
      ...prev,
      activeIndex:
        handleData().get().list().length - 1 > Number(prev.activeIndex)
          ? Number(prev.activeIndex) + 1
          : 0,
    }))
  }

  function handlePrev() {
    setState((prev) => ({
      ...prev,
      activeIndex:
        Number(prev.activeIndex) <= 0
          ? handleData().get().list().length - 1
          : Number(prev.activeIndex) - 1,
    }))
  }

  function handleRemove() {
    prepareRemoveImage(state.activeIndex)
  }

  return (
    <div
      className={`overlay gallery-overlay ${isOpened() ? "show" : ""}`}
      key={isOpened()}
    >
      {handleData().get().list().length > 1 ? (
        <>
          <Tooltip title="Предишно изображение">
            <span className="prev" onClick={handlePrev}></span>
          </Tooltip>
          <Tooltip title="Следващо изображение">
            <span className="next" onClick={handleNext}></span>
          </Tooltip>
        </>
      ) : (
        <></>
      )}
      <Tooltip title="Затваряне">
        <span className="close-gallery" onClick={closeWindow}></span>
      </Tooltip>
      <div className="bg">
        <img src={handleData().get().activeImage()} className="main" alt="" />
        {!viewFromHistory ? (
          <Tooltip title="Премахване">
            <span className="remove" onClick={handleRemove}></span>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default forwardRef(Gallery)
