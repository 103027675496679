//providers
import { useAppContext } from "providers/app"
import { useUsersDataContext } from "../providers/users"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints.js"

function useActivateUser(userId, activateUserRef) {
    const { fetch } = useFetch()

    const app = useAppContext()
    const users = useUsersDataContext()

    function handleActivate() {
        fetch({
            endpoint: `user/reactivate?user_id=${userId}`,
            method: "get",
        })
            .then(() => {
                users.refetchAllData()
                app
                    .handleMessage()
                    .success()
                    .show("Потребителят беше Активиран успешно!")
            })
            .catch(() => {
                app
                    .handleMessage()
                    .error()
                    .show("Нещо се обърка! Моля, опитайте отново.")
            })
            .finally(() => {
                activateUserRef.current.close()
            })
    }

    return {
        handleActivate
    }
}

export default useActivateUser