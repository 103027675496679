import { createContext, useContext, useState, useEffect } from "react"

//constants
import { ENDPOINTS } from "constants/endpoints"
import { CATEGORIES_LIST } from "constants/categories"

//hooks
import useFetch from "hooks/useFetch"

export const AppContext = createContext({})

const INITIAL_MESSAGES_STATE = {
    error: {
        show: false,
        text: ''
    },
    success: {
        show: false,
        text: ''
    },
}
let successMessageTimeout
let errorMessageTimeout

const INITIAL_DATA_STATE = {
    user: false,
    categories: false,
    paymentMethods: false,
}

const AppProvider = props => {
    const { fetch } = useFetch()

    const [messages, setMessages] = useState(INITIAL_MESSAGES_STATE)
    const [data, setData] = useState(INITIAL_DATA_STATE)
    const [initialLoading, setInitialLoading] = useState(true)
    const [roles, setRoles] = useState([])
    const [categories, setCategories] = useState(CATEGORIES_LIST)
    const [newNotification, setNewNotification] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState([])

    useEffect(() => {
        // handleRoles().fetchData()
        handleCategories().fetchData()
        handlePaymentMethods().fetchData()
    }, [])

    useEffect(() => {
        let isEverithingLoaded = true
        Object.values(data).map(d => {
            if (isEverithingLoaded) {
                isEverithingLoaded = d
            }
        })

        if (isEverithingLoaded) setInitialLoading(false)
    }, [data])

    function fillInitialData(data) {
        setData(prev => ({
            ...prev,
            ...data
        }))
    }

    function handleRoles() {
        function fetchData() {
            if (getRoles().length) return

            fetch({ endpoint: ENDPOINTS.GET_ROLES, method: 'get' })
                .then(res => {
                    setRoles(res?.data || [])
                })
                .finally(() => {
                    fillInitialData({ roles: true })
                })
        }
        function getRoles() {
            return roles
        }

        function getRoleByRoleId(roleId) {
            const role = getRoles().find(r => r.id === roleId)

            return role
        }

        return {
            getRoles,
            getRoleByRoleId,
            fetchData
        }
    }

    function handleCategories() {
        function fetchData() {
            fetch({ endpoint: ENDPOINTS.SHARED.GET_CATEGORIES, method: 'get' })
                .then(res => {
                    _set(res.data)
                    // handleSubcategories().fetchData(res.data)
                })
                .catch(() => {
                    // fillInitialData({ subcategories: true })
                })
                .finally(() => {
                    fillInitialData({ categories: true })
                })
        }

        function _set(list) {
            let updatedCategoriesList = {}
            list.map(l => {
                updatedCategoriesList[l.tag] = {
                    ...categories[l.tag],
                    ...l
                }
            })
            setCategories(updatedCategoriesList)
        }

        function get() {
            return categories
        }

        return {
            fetchData,
            get
        }
    }

    function handlePaymentMethods() {
        function fetchData() {
            fetch({ endpoint: ENDPOINTS.SHARED.GET_PAYMENT_METHODS, method: 'get' })
                .then(res => {
                    _set(res.data)
                })
                .finally(() => {
                    fillInitialData({ paymentMethods: true })
                })
        }

        function _set(list) {
            setPaymentMethods(list)
        }

        function get() {
            return paymentMethods
        }

        return {
            fetchData,
            get
        }
    }

    function handleMessage() {
        function _toggle(show, message, text) {
            setMessages(prev => ({
                ...prev,
                [message]: {
                    ...prev[message],
                    show,
                    text
                }
            }))
        }

        function error() {
            function show(text) {
                success().hide()
                _toggle(true, 'error', text)
                clearTimeout(errorMessageTimeout)
                errorMessageTimeout = setTimeout(hide, 2000)
            }
            function hide() {
                _toggle(false, 'error')
            }

            return {
                show,
                hide
            }
        }

        function success() {
            function show(text) {
                error().hide()
                _toggle(true, 'success', text)
                clearTimeout(successMessageTimeout)
                successMessageTimeout = setTimeout(() => {
                    hide()
                }, 2000)
            }
            function hide() {
                _toggle(false, 'success')
            }

            return {
                show,
                hide
            }
        }

        return {
            error,
            success
        }
    }

    function handleNewNotification() {
        function set(data) {
            setNewNotification(data)
        }

        function get() {
            return newNotification
        }

        return {
            set,
            get
        }
    }

    const exportedData = {
        handleMessage,
        fillInitialData,
        messages,
        initialLoading,
        handleRoles,
        handleCategories,
        handlePaymentMethods,
        handleNewNotification
    }
    return <AppContext.Provider value={exportedData} {...props} />
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider