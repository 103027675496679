import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"
import useIsLoading from "hooks/useIsLoading"

//constants
import { ENDPOINTS } from "constants/endpoints"

//helpers
import { getGETParam, getUrlData } from "helpers/url"

//providers
import { useFinanceDataContext } from "../providers/finances"

function useFetchFinances() {   

    const financeProvider = useFinanceDataContext()

    const { fetch } = useFetch()
    const { loading, setLoading } = useIsLoading(true)

    const [finances, setFinances] = useState({})

    useEffect(()=> {
        fetchFinances(getGETParam(financeProvider.filters))
    },[financeProvider.handleRefresh().getRefreshed()])    

    function fetchFinances(params) {
        setLoading(true)        

       if(!params) return
       
        fetch({            
            endpoint: `${ENDPOINTS.SHARED.GET_FINANCES}${params}`,            
            method: "get",
        })
            .then((res) => {
                setFinances(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function getFinances() {
        return finances
    }

    function getFinancesEntries() {
        return Object.values(getFinances())
    }

    return {
        getFinances,
        getFinancesEntries,
        fetchFinances,
        loading,
        setLoading
    }
}

export default useFetchFinances