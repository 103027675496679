//components
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"
import DailyTasksList from "components/schedule/components/DailyTasksList"

//hooks
import useFetchHistory from "../hooks/useFetchHistory"

function List({ getSelectedDate }) {
  const { loading, getTasks, hasMore, setCurrentPage, getPage } =
    useFetchHistory(getSelectedDate)

  function handleScrollList(e) {
    if (!hasMore || loading) return

    const { target } = e
    if (
      (target.scrollHeight - target.clientHeight).toFixed(0) - 1 <=
      target.scrollTop.toFixed(0)
    )
      setCurrentPage(getPage() + 1)
  }

  if (loading && !getTasks().length) {
    return <Loader />
  }

  if (!getTasks().length) {
    return <NoResultsFound text="Не бяха открити задачи" />
  }
  return (
    <DailyTasksList
      tasks={getTasks()}
      handleScrollList={handleScrollList}
      loadingMore={loading}
      hideOptions={true}
    />
  )
}

export default List
