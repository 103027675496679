//hooks
import useFetch from "hooks/useFetch"

//providers
import { useAppContext } from "providers/app"
import { useTransactionsDataContext } from "components/schedule/providers/transactions"
import { useFinanceDataContext } from "components/finances/providers/finances"
import { useEffect, useState } from "react"

function useImportFile({ endpoint, closWindowCB, type }) {
    const app = useAppContext()
    const transactionsData = useTransactionsDataContext()
    const financesData = useFinanceDataContext()

    const { fetch, loading, validations, handleValidations } = useFetch()
    function handleSubmit(e) {
        e.preventDefault()
        const data = new FormData(e.target)

        fetch({ endpoint, method: 'post', data })
            .then((res) => {
                closWindowCB()
                app.handleMessage().success().show(res.data)
                if (e.target.date) financesData.handleRefresh().refreshData()
                else transactionsData.refreshData(type)
                window.location.reload()
            })
    }

    return {
        handleSubmit,
        loading,
        validations,
        handleValidations
    }
}

export default useImportFile