import { useEffect, useState } from "react"

//constants
import { ENDPOINTS } from "constants/endpoints"

//hooks
import useFetch from "hooks/useFetch"

function useFetchBalance(userId) {
    const { fetch, loading } = useFetch()

    const [balance, setBalance] = useState({})

    useEffect(() => {
        if (userId) _fetchData()
    }, [])

    function _fetchData() {
        fetch({ endpoint: `${ENDPOINTS.ADMIN.GET_WORKER_BALANCE}/${userId}`, method: 'get' })
            .then(res => {
                setBalance(res.data)
            })
    }

    function getBalance() {
        return balance
    }

    function refetchData() {
        _fetchData()
    }

    return {
        getBalance,
        loading,
        refetchData,
    }
}

export default useFetchBalance