import { useCallback, useEffect, useRef, useState } from "react"

//libraries
import { Tooltip } from "@mui/material"

//images
import bellIcon from "assets/images/app/header/icons/bell.svg"

//providers
import { useAppContext } from "providers/app"

//components
import NotificationsList from "./NotificationsList"
import NotificationPopup from "./NotificationPopup"

//hooks
import useCheckNotifications from "../hooks/useCheckNotifications"

//helpers
import { prod } from "helpers/mode"

const { REACT_APP_URL_DEV, REACT_APP_URL_PROD } = process.env
function Notifications() {
  const { hasUnseenNotifications, getUnseenNotificationsData } =
    useCheckNotifications()

  const audiElRef = useRef()

  const app = useAppContext()

  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    if (app.handleNewNotification().get()) audiElRef.current.play()
  }, [app.handleNewNotification().get()])

  useEffect(() => {
    if (hasUnseenNotifications)
      app.handleNewNotification().set(getUnseenNotificationsData())
  }, [hasUnseenNotifications])

  function handleOpen() {
    setHidden(false)

    if (app.handleNewNotification().get())
      app.handleNewNotification().set(false)
  }

  const renderNotificationPopup = useCallback(() => {
    return (
      <NotificationPopup
        data={app.handleNewNotification().get()}
        handleOpen={handleOpen}
      />
    )
  }, [app.handleNewNotification().get()])

  return (
    <div className="notifications">
      {app.handleNewNotification().get() ? (
        <audio
          src={`${
            prod ? REACT_APP_URL_PROD : REACT_APP_URL_DEV
          }/notifications/notification.mp3`}
          ref={audiElRef}
          autoPlay={true}
          controls
        />
      ) : (
        <></>
      )}
      <Tooltip title="Преглед на известията">
        <div className="bell" onClick={handleOpen}>
          <img src={bellIcon} alt="" />
          {app.handleNewNotification().get() ? <span></span> : <></>}
        </div>
      </Tooltip>
      <NotificationsList hidden={hidden} setHidden={setHidden} />
      {renderNotificationPopup()}
    </div>
  )
}

export default Notifications
