//libraries
import moment from "moment-timezone"

//constants
import { SERVER_DATE_AND_HOUR_FORMAT, SERVER_DATE_FORMAT } from "constants/formats"

export function isEndHourToday(endHour = null, today) {
    if (!endHour) {
        return false
    }

    if (moment(endHour, SERVER_DATE_AND_HOUR_FORMAT).isSame(moment(today, SERVER_DATE_FORMAT), "day")) {
        return true
    }

    return false
}

export function isStartHourToday(startHour = null, today) {
    if (!startHour) {
        return false
    }

    if (moment(startHour, SERVER_DATE_AND_HOUR_FORMAT).isSame(moment(today, SERVER_DATE_FORMAT), "day")) {
        return true
    }

    return false
}