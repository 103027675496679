//components
import Map from "./components/Map"
import FilterByUser from "./components/FilterByUser"
import FilterByDate from "./components/FilterByDate"

function TrackUsers() {
  return (
    <section id="track">
      <div className="screen-top-bar">
        <div className="wrapper row">
          <h1>Хронология на активността</h1>
        </div>
      </div>
      <div className="track-filter">
        <div className="row top">
          <div className="row">
            <FilterByUser />
            <FilterByDate />
          </div>
        </div>
      </div>
      <Map />
    </section>
  )
}

export default TrackUsers
