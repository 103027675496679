//images
import homeIcon from 'assets/images/app/icons/home.svg'
import buildingIcon from 'assets/images/app/icons/building.svg'
import transportIcon from 'assets/images/app/icons/transport.svg'
import otherIcon from 'assets/images/app/icons/other.svg'

export const CATEGORIES_ARR = ['HOME', 'BUILDING', 'TRANSPORT', 'OTHER']

export const CATEGORIES_LIST = {
    [CATEGORIES_ARR[0]]: {
        icon: homeIcon,
        subcategories: []
    },
    [CATEGORIES_ARR[1]]: {
        icon: buildingIcon,
        subcategories: []
    },
    [CATEGORIES_ARR[2]]: {
        icon: transportIcon,
        subcategories: []
    },
    [CATEGORIES_ARR[3]]: {
        icon: otherIcon,
        subcategories: []
    },
}