import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//providers
import { useFilterDataContext } from "../providers/filter"

function useFetchCoords() {
    const { fetch, loading } = useFetch()

    const filter = useFilterDataContext()
    const { getDate, getUser, setHour, getHour, setUser } = filter

    const [data, setData] = useState([])

    useEffect(() => {
        _fetchData()
    }, [getDate(), getUser()?.id])

    function _fetchData() {
        if (getHour()) setHour(null)

        fetch({ endpoint: `get-coords?date=${getDate()}&user_id=${getUser()?.id || null}`, method: 'get' })
            .then(res => {
                setData(res.data.coordinates)
                if (getUser()) {
                    const user = res.data.coordinates[0]
                    
                    setHour(user?.coordinates[0])
                    setUser(user)
                }
            })
    }

    function getData() {
        return data
    }

    return {
        getData,
        loading
    }
}

export default useFetchCoords