//components
import WeeklyPeriod from "./WeeklyPeriod"
import DailyPeriod from "./DailyPeriod"

//providers
import { useScheduleDataContext } from "../providers/schedule"

function Periods() {
  const scheduleData = useScheduleDataContext()
  const sh = scheduleData.handlePeriods()

  function renderCalendar() {
    switch (sh.getCalendarType()) {
      case "weekly":
        return <WeeklyPeriod />

      case "daily":
        return <DailyPeriod />
      default:
        return (
          <div className="no-results">
            <b>Грешка при зареждането на календара.</b>
          </div>
        )
    }
  }

  return (
    <div className="periods">
      <div className="wrapper">{renderCalendar()}</div>
    </div>
  )
}

export default Periods
