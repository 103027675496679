//hooks
import useFetch from "hooks/useFetch"

//providers
import { useAppContext } from "providers/app"
import { useTransactionsDataContext } from "components/schedule/providers/transactions"
import { useFinanceDataContext } from "components/finances/providers/finances"
import { useEffect, useState } from "react"
import { ENDPOINTS } from "constants/endpoints"
import { useFormDataContext } from "../providers/form"

function useSelcetClient() {
    const { fetch, loading, validations, handleValidations } = useFetch()
    const formData = useFormDataContext()

    function selectClient(taskId) {
        fetch({ endpoint: `${ENDPOINTS.ADMIN.GET_CLIENT}?order_id=${taskId}`, method: 'get' })
            .then((res) => {
                formData.setFieldsFormats("client", res.data.clientId)
                formData.setFieldsFormats("clientName", res.data.clientName)
                formData.setFieldsFormats("clientSettlement", res.data.clientSettlement)
                formData.setFieldsFormats("clientMOL", res.data.clientMol)
                formData.setFieldsFormats("clientLocation", res.data.clientAddress)
                formData.setFieldsFormats("clientEmail", res.data.clientEmail)
                formData.setFieldsFormats("clientPhone", res.data.clientPhone)
                formData.setFieldsFormats("clientEIKEGN", res.data.clientEikegn)
                formData.setFieldsFormats("clientVatNum", res.data.clientVatNum)
                formData.setFieldsFormats("task",res.data.taskId)
            })           
    }

    return {
        selectClient,
        loadingData: loading,
        validations,
        handleValidations
    }
}

export default useSelcetClient