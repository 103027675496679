import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"

function useFetchWorkersSchedule(getSelectedDate) {
    const { fetch, loading } = useFetch()

    const [schedule, setSchedule] = useState([])

    useEffect(() => {
        fetchData()
    }, [getSelectedDate()])

    function fetchData() {
        fetch({
            endpoint: `${ENDPOINTS.ADMIN.GET_WORKERS_WITH_TASKS}?date=${getSelectedDate()}`,
            method: "get",
        })
            .then((res) => {
                setSchedule(res.data)
            })
    }

    function getSchedule() {
        return schedule
    }

    return {
        getSchedule,
        loading
    }
}

export default useFetchWorkersSchedule