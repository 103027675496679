import { useLocation } from "react-router-dom"

function usePrevParams() {
    const location = useLocation()

    function getPrevParams() {
        return `${location.search}${location.search.length ? "&" : "?"}`
    }

    return {
        getPrevParams
    }
}

export default usePrevParams