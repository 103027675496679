//hooks
import useFetch from "hooks/useFetch"

//providers
import { useAppContext } from "providers/app"
import { useTransactionsDataContext } from "components/schedule/providers/transactions"
import { useFinanceDataContext } from "components/finances/providers/finances"
import { useEffect, useState } from "react"

function useCreateInvoice({ endpoint, closWindowCB, type }) {
    // const app = useAppContext()
    // const transactionsData = useTransactionsDataContext()
    // const financesData = useFinanceDataContext()

    const { fetch, loading, validations, handleValidations } = useFetch()
    function createInvoice(e) {
        e.preventDefault()
        const data = new FormData(e.target)
        fetch({ endpoint, method: 'post', data })
            .then((res) => {
                closWindowCB()
                window.open(res.data, '_blank')
            })
    }

    return {
        createInvoice,
        loading,
        validations,
        handleValidations
    }
}

export default useCreateInvoice