import { useRef } from "react"

//images
import calendarIcon from "assets/images/schedule/icons/calendar-2.svg"

//libraries
import moment from "moment-timezone"
import { Tooltip } from "@mui/material"

//providers
import { useFormDataContext } from "../providers/form"

//constants
import { SERVER_DATE_FORMAT } from "constants/formats"

function SelectDateAndHour({ handleValidations, viewFromHistory }) {
  const formData = useFormDataContext()
  function getStartDateAndTime() {
    return getDateAndTimeFormatted(formData.getFieldForClient("start"))
  }
  function getEndDateAndTime() {
    return getDateAndTimeFormatted(formData.getFieldForClient("end"))
  }
  const selectedWorkers = formData.getFieldForClient("users")

  const endHHMMRef = useRef(null)
  const startHHMMRef = useRef(null)

  function handleChange(e) {
    const { name, value } = e.target

    setTimeout(() => {
      if (name === "start") startHHMMRef.current.focus()
      if (name === "end") endHHMMRef.current.focus()
    }, 500)

    const serverFormat = moment(value, `${SERVER_DATE_FORMAT}THH:mm`).format(
      `${SERVER_DATE_FORMAT} HH:mm`
    )

    formData.setFieldsFormats(name, value, serverFormat)

    handleBlurStartData(e)
  }

  function handleBlurStartData(e) {
    const { name, value } = e.target

    setEndHourAutomaticly(name, value)
  }

  function setEndHourAutomaticly(name, value) {
    if (!value) {
      formData.setFieldsFormats("end", null, null)

      return
    }

    if (name === "start" && !formData.getFieldForClient("end")) {
      const serverFormat = moment(value, `${SERVER_DATE_FORMAT}THH:mm`)
        .add(1, "hour")
        .format(`${SERVER_DATE_FORMAT} HH:mm`)

      const clientFormat = moment(value, `${SERVER_DATE_FORMAT}THH:mm`)
        .add(1, "hour")
        .format(`${SERVER_DATE_FORMAT}THH:mm`)

      formData.setFieldsFormats("end", clientFormat, serverFormat)
    }
  }

  function getDateAndTimeFormatted(dateAndTime) {
    if (!dateAndTime?.length) {
      return null
    }

    const core = moment(dateAndTime, `${SERVER_DATE_FORMAT} HH:mm`)
    const formattedDay = core.format("DD")
    const formattedMonth = core.format("MMM")

    return `${formattedDay} ${formattedMonth}, `
  }

  function validate(fieldName) {
    return handleValidations().validate(fieldName)
  }

  return (
    <div className="date-and-hour-holder">
      <Tooltip title={!selectedWorkers ? "Моля, първо изберете работник" : ""}>
        <div>
          <div
            className={`start ${!selectedWorkers ? "disabled" : ""} ${
              viewFromHistory ? "cursor-default" : ""
            }`}
            key={getStartDateAndTime()}
          >
            <img src={calendarIcon} alt="" />
            <div className="right">
              <p>Начало (опционално)</p>
              {getStartDateAndTime() ? (
                <b>
                  {getStartDateAndTime()}{" "}
                  <input
                    type="time"
                    name="startHHMM"
                    onChange={handleChange}
                    value={formData.getFieldForClient("startHHMM")}
                    ref={startHHMMRef}
                  />
                  ч.
                </b>
              ) : (
                <></>
              )}
            </div>
            {!viewFromHistory ? (
              <input
                type="date"
                name="start"
                value={moment(
                  formData.getFieldForClient("start"),
                  "YYYY-MM-DDTHH:mm"
                ).format("YYYY-MM-DD")}
                onChange={handleChange}
                // onBlur={handleBlurStartData}
              />
            ) : (
              <></>
            )}
            {getStartDateAndTime() &&
            !getEndDateAndTime() &&
            !viewFromHistory ? (
              <Tooltip title="Премахване на избраните дата и час">
                <span
                  className="remove"
                  onClick={() => {
                    formData.clearField("start")
                  }}
                ></span>
              </Tooltip>
            ) : (
              <></>
            )}
            {validate("start").message ? (
              <p
                className="error-text"
                style={{
                  top: "calc(100% + 8px)",
                }}
              >
                {validate("start").message}
              </p>
            ) : (
              <></>
            )}
            {validate("date").message ? (
              <p
                className="error-text"
                style={{
                  top: "calc(100% + 8px)",
                }}
              >
                {validate("date").message}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Tooltip>
      <Tooltip
        title={
          !getStartDateAndTime() ? "Моля, изберете начална дата и час" : ""
        }
      >
        <div>
          <div
            className={`end ${
              !selectedWorkers || !getStartDateAndTime() ? "disabled" : ""
            } ${viewFromHistory ? "cursor-default" : ""}`}
            key={getEndDateAndTime()}
          >
            <img src={calendarIcon} alt="" />
            <div className="right">
              <p>Край (опционално)</p>
              {getEndDateAndTime() ? (
                <b>
                  {getEndDateAndTime()}
                  <input
                    type="time"
                    name="endHHMM"
                    onChange={handleChange}
                    value={formData.getFieldForClient("endHHMM")}
                    ref={endHHMMRef}
                  />
                  ч.
                </b>
              ) : (
                <></>
              )}
            </div>
            {getStartDateAndTime() && !viewFromHistory ? (
              <input
                type="date"
                name="end"
                value={moment(
                  formData.getFieldForClient("end"),
                  "YYYY-MM-DDTHH:mm"
                ).format("YYYY-MM-DD")}
                onChange={handleChange}
              />
            ) : (
              <></>
            )}
            {getEndDateAndTime() && !viewFromHistory ? (
              <Tooltip title="Премахване на избраните дата и час">
                <span
                  className="remove"
                  onClick={() => {
                    formData.clearField("end")
                  }}
                ></span>
              </Tooltip>
            ) : (
              <></>
            )}
            {validate("end").message ? (
              <p
                className="error-text"
                style={{
                  top: "auto",
                  bottom: "-17px",
                }}
              >
                {validate("end").message}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default SelectDateAndHour
