import { useEffect, useState } from "react"

//constants
import { ENDPOINTS } from "constants/endpoints"

//hooks
import useFetch from "hooks/useFetch"

//providers
import { useAppContext } from "providers/app"

function useFetchSumsList(userId) {
    const app = useAppContext()

    const { fetch, loading } = useFetch()

    const [sums, setSums] = useState([])

    useEffect(() => {
        if (userId) _fetchData()
    }, [userId])

    function _fetchData() {
        fetch({ endpoint: `${ENDPOINTS.ADMIN.GET_WORKER_PAYMENTS}/${userId}`, method: 'get' })
            .then(res => {
                setSums(res.data)
            })
    }

    async function removeSum(sumId) {
        return await fetch({ endpoint: `${ENDPOINTS.ADMIN.REMOVE_WORKER_PAYMENT}/${sumId}`, method: 'delete' })
            .then(() => {
                refetchData()
                app
                    .handleMessage()
                    .success()
                    .show("Плащането беше изтрито успешно!")
            })
            .catch(() => {
                app
                    .handleMessage()
                    .error()
                    .show("Нещо се обърка! Моля, опитайте отново.")
            })
    }

    function getSumsList() {
        return sums
    }

    function refetchData() {
        _fetchData()
    }

    return {
        getSumsList,
        removeSum,
        loading,
        refetchData
    }
}

export default useFetchSumsList