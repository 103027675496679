//components
import Periods from "./components/Periods"
import List from "./components/List"

//hooks
import useHistorySchedule from "./hooks/useHistorySchedule"

//libraries
import moment from "moment-timezone"

//constants
import { SERVER_DATE_FORMAT } from "constants/formats"

function History() {
  const { handlePeriods } = useHistorySchedule()

  function getSelectedDate() {
    const selectedDate = `${
      handlePeriods().getMonthDay().dayAsDate
    }.${handlePeriods().getActiveYear()}`

    return moment(selectedDate, "DD.MM.YYYY").format(SERVER_DATE_FORMAT)
  }

  return (
    <section id="history">
      <div className="screen-top-bar">
        <div className="wrapper row">
          <h1>История на задачите</h1>
        </div>
      </div>
      <Periods
        handlePeriods={handlePeriods}
      />
      <List getSelectedDate={getSelectedDate} />
    </section>
  )
}

export default History
