import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { useNavigate } from "react-router-dom"

//libraries
import { Tooltip } from "@mui/material"
import moment from "moment-timezone"

//images
import removeIcon from "assets/images/schedule/icons/remove-gray.svg"

//hooks
import useFetchComments from "../hooks/useFetchComments"
import useRemoveComment from "../hooks/useRemoveComment"
import usePrevParams from "hooks/usePrevParams"

//components
import CommentReplies from "./CommentReplies"
import CommentField from "./CommentField"
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"
import Remove from "components/app/components/Remove"

//helpers
import { getUrlData } from "helpers/url"
import { parseName } from "helpers/substring"

//constants
import { SERVER_DATE_FORMAT } from "constants/formats"
import { COMMENT_STATUSES } from "constants/statuses"

function FormComments({ viewFromHistory }) {
  const navigate = useNavigate()

  const removeCommentRef = useRef()
  const removeReplyRef = useRef()

  const taskId = getUrlData().edit_task_id
  const cId = getUrlData().remove_comment_id
  const rId = getUrlData().remove_reply_id
  const commentId = cId || rId

  const { getComments, loading } = useFetchComments(taskId)
  const { handleRemoveComment, handleRemoveReply } = useRemoveComment(
    commentId,
    closeRemoveWindow
  )
  const { getPrevParams } = usePrevParams()

  function closeRemoveWindow() {
    removeCommentRef.current.close()
    removeReplyRef.current.close()
  }

  useEffect(() => {
    if (cId) removeCommentRef.current.open()
    if (rId) removeReplyRef.current.open()
  }, [commentId])

  function prepareRemoveComment(commentId) {
    navigate({
      search: `${getPrevParams()}remove_comment_id=${commentId}`,
    })
  }

  function getDateAndTimeFormatted(dateAndTime) {
    const core = moment(dateAndTime, `${SERVER_DATE_FORMAT}THH:mm:ss`)
    const formattedDay = core.format("DD")
    const formattedMonth = core.format("MMM")
    const formattedHour = core.format("HH:mm")

    return `${formattedDay} ${formattedMonth}, ${formattedHour}ч.`
  }

  function renderComments() {
    if (loading) {
      return <Loader />
    }
    if (!getComments().length) {
      return <NoResultsFound text="Не бяха открити коментари." />
    }

    return (
      <ul className="list">
        {getComments().map((c) => (
          <li key={`comment-${c.id}`}>
            <div className="top">
              <Tooltip title={c.status.name}>
                <img src={COMMENT_STATUSES[c.status.tag].icon} alt="" />
              </Tooltip>
              <b>{parseName(c.user.name)}</b>
              <time>{getDateAndTimeFormatted(c.created_at)}</time>
              {c.can_delete && !viewFromHistory ? (
                <Tooltip title="Премахване">
                  <button
                    className="remove"
                    onClick={() => {
                      prepareRemoveComment(c.id)
                    }}
                  >
                    <img src={removeIcon} alt="" />
                  </button>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
            <p>{c.comment}</p>
            <CommentReplies
              replies={c.reply}
              getDateAndTimeFormatted={getDateAndTimeFormatted}
              taskId={taskId}
              commentId={c.id}
              viewFromHistory={viewFromHistory}
            />
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className="tab comments">
      {createPortal(
        <>
          <Remove
            ref={removeCommentRef}
            elementsContent={{
              heading: "Изтриване на коментарът",
            }}
            accept={handleRemoveComment}
          />
          <Remove
            ref={removeReplyRef}
            elementsContent={{
              heading: "Изтриване на отговорът",
            }}
            accept={handleRemoveReply}
          />
        </>,
        document.body
      )}
      {renderComments()}
      {!viewFromHistory ? <CommentField taskId={taskId} /> : <></>}
    </div>
  )
}

export default FormComments
