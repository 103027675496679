import { useCallback, useState } from "react"

//libraries
import {
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Tooltip,
} from "@mui/material"

//providers
import { useFilterDataContext } from "../providers/filter"

//components
import Loader from "components/app/components/Loader"

//hooks
import useFetchUsers from "components/users/hooks/useFetchUsers"

function FilterByUser() {
  const { getUsers, loading } = useFetchUsers("", null)

  const filter = useFilterDataContext()
  const { getUser, setUser } = filter

  const [hidden, setHidden] = useState(true)

  function clearSelected() {
    setUser(null)
  }

  function handleChange(user) {
    if (user.id === getUser()?.id) {
      clearSelected()

      return
    }

    setUser(user)
  }

  const renderList = useCallback(() => {
    if (hidden) {
      return <></>
    }

    return (
      <ClickAwayListener
        onClickAway={() => {
          setHidden(true)
        }}
      >
        <div
          className={`dropdown ${
            !getUsers().length && loading ? "loading" : ""
          }`}
        >
          <ul>
            {getUsers().map((u) => (
              <li key={`d-${u.id}`}>
                <FormControlLabel
                  control={
                    <Checkbox value={u.id} checked={getUser()?.id === u.id} />
                  }
                  onChange={() => {
                    handleChange(u)
                  }}
                  label={u.name}
                />
              </li>
            ))}
            {getUsers().length && loading ? (
              <li>
                <Loader noPadding={true} />
              </li>
            ) : (
              <></>
            )}
          </ul>
          <div className="row footer">
            <button
              className="clear"
              onClick={clearSelected}
              disabled={!getUser()}
            >
              Изчистване на филтъра
            </button>
          </div>
        </div>
      </ClickAwayListener>
    )
  }, [hidden, getUser(), getUsers(), loading])

  return (
    <div className="list-filter">
      <Tooltip title="Филтриране по изпълнител">
        <div
          className={`label ${getUser() ? "with-items" : ""}
          ${loading && getUser()?.length ? "loading" : ""}
          `}
          onClick={() => {
            setHidden(false)
          }}
        >
          {getUser()?.name || "Изпълнител"}
        </div>
      </Tooltip>
      {renderList()}
    </div>
  )
}

export default FilterByUser
