import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

//constants
import { ENDPOINTS } from "constants/endpoints"

//hooks
import useFetch from "hooks/useFetch"

let searchUsersTimeout
function useFetchUsers(search = "", roleId = null) {
    const { fetch, loading } = useFetch()

    const [users, setUsers] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        clearTimeout(searchUsersTimeout)
        searchUsersTimeout = setTimeout(() => {
            _fetchData()
        }, getFetchTimeout())
    }, [search])

    function getFetchTimeout() {
        if (users.length) {
            return 300
        }

        return 0
    }

    function _fetchData() {
        navigate({
            search: search.length ? `?name=${search}` : null,
        })

        fetch({ endpoint: `${ENDPOINTS.ADMIN.GET_WORKERS}?name=${search}&role_id=${roleId}`, method: 'get' })
            .then(res => {
                setUsers(res.data)
            })
    }

    function getUsers() {
        return users
    }

    function refetchData() {
        _fetchData()
    }

    return {
        getUsers,
        loading,
        refetchData
    }
}

export default useFetchUsers