import { useState } from "react"

const INITIAL_STATE = {
    all: false,
    fields: {},
}
const useValidation = () => {
    const [validations, setValidations] = useState(INITIAL_STATE)

    const handleValidations = () => {
        function get() {
            const allKeys = () => Object.keys(validations.fields || {})

            const allValues = () => Object.values(validations.fields || {})

            const all = () => validations.all

            return {
                allKeys,
                allValues,
                all,
            }
        }

        function check(key = "") {
            if (validations.fields[key]) {
                return true
            }

            return false
        }

        function getMessage(key = "") {
            if (check(key)) {
                return validations.fields[key][0]
            }

            return ""
        }

        function clear() {
            setValidations(INITIAL_STATE)
        }

        function set() {
            function fields(fields) {
                setValidations((prev) => ({
                    ...prev,
                    fields,
                }))
            }

            function all() {
                setValidations((prev) => ({
                    ...prev,
                    all: true,
                }))
            }

            return {
                fields,
                all,
            }
        }

        function validate(fieldName) {
            let data = {
                className: "",
                message: null,
            }
            if (check(fieldName)) {
                data = {
                    className: "validate",
                    message: getMessage(fieldName),
                }
            }

            return data
        }

        return {
            get,
            set,
            check,
            clear,
            getMessage,
            validate
        }
    }

    return {
        validations,
        handleValidations,
    }
}

export default useValidation
