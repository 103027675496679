//hooks
import useLogin from "./hooks/useLogin"

//images
import brandImage from "assets/images/login/brand.svg"

function Login() {
  const { handleSubmit, loading, validations } = useLogin()

  return (
    <section id="login">
      <form onSubmit={handleSubmit} autoComplete="off">
        <img src={brandImage} alt="" />
        <h1>Здравейте!</h1>
        <p>Моля, въведете Вашите данни за вход</p>
        <div className="row">
          <div className="col">
            <input
              type="tel"
              id="number"
              name="number"
              placeholder="Въведете Тел. номер..."
              autoComplete="off"
              className={validations.all ? "validate" : ""}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input
              type="password"
              id="password"
              name="password"
              autoComplete="off"
              placeholder="Ввъедете Парола..."
              className={validations.all ? "validate" : ""}
            />
          </div>
        </div>
        <button className={`btn-primary ${loading ? "loading" : ""}`}>
          Вход
        </button>
      </form>
    </section>
  )
}

export default Login
