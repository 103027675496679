//constants
import { ENDPOINTS } from "constants/endpoints"

//helpers
import api from "helpers/api"

//providers
import { useAuthDataContext } from "providers/auth"

function useFetchProtocol() {
    const auth = useAuthDataContext()

    function fetchProtocol(taskId) {
        window.open(
            `${api.getUri()}${ENDPOINTS.ADMIN.GET_PROTOCOL
            }?order_id=${taskId}&token=${auth.handleAuth().get().token()}`,
            "_blank"
        )
    }

    return {
        fetchProtocol
    }
}

export default useFetchProtocol