//components
import ListTypeFilter from "./ListTypeFilter"

//providers
import { useAppContext } from "providers/app"

function FilterByTaskType() {
  const app = useAppContext()

  function getData() {
    const data = {}
    Object.values(app.handleCategories().get()).map((c) => {
      c.subcategories.map((s) => {
        s["name"] = s.service_name
        s["id"] = s.service_id

        return s
      })
      data[c.tag] = c.subcategories
    })

    return data
  }

  function getMergedArrays() {
    const rawArr = Object.values(getData())
    let mergedArr = []

    for (let i = 0; i < rawArr.length; i++) {
      mergedArr = [...mergedArr, ...rawArr[i]]
    }

    return mergedArr
  }

  return (
    <ListTypeFilter
      label="Тип задача"
      name="task_type"
      data={getMergedArrays()}
    />
  )
}

export default FilterByTaskType
