import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//providers
import { useTransactionsDataContext } from "../providers/transactions"

function useFetchTransactions(endpoint, taskId, type) {
    const transactionsData = useTransactionsDataContext()

    const { fetch, loading } = useFetch()

    const [data, setData] = useState({
        revenue: {},
        expenses: {}
    })

    useEffect(() => {
        _fetchData()
    }, [transactionsData.getRefreshed(type)])

    function _fetchData() {
        fetch({ endpoint: `${endpoint}?order_id=${taskId}`, method: 'get' })
            .then(res => {
                setData(res.data)
            })
    }

    function getData() {
        return Object.values(data)[0]
    }

    return {
        getData,
        loading
    }
}

export default useFetchTransactions