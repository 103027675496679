import { forwardRef, useEffect, useImperativeHandle, useRef } from "react"

//libraries
import { Tooltip } from "@mui/material"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useSelcetClient from "../hooks/useSelectClient"
import useCreateInvoice from "../hooks/useCreateInvoice"

//constants
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useFormDataContext } from "../providers/form"



function AddInvoice({ viewFromHistory }, ref) {
    const formRef = useRef()
    const formData = useFormDataContext()
    const { loadingData, selectClient } = useSelcetClient()


    const selectedClient = {
        id: formData.getFieldForClient("client"),
        name: formData.getFieldForClient("clientName"),
        email: formData.getFieldForClient("clientEmail"),
        phone: formData.getFieldForClient("clientPhone"),
        location: formData.getFieldForClient("clientLocation"),
        locationId: formData.getFieldForClient("clientLocationId"),
        locationsList: formData.getFieldForClient("clientLocationsList"),
        mol: formData.getFieldForClient("clientMOL"),
        eikegn: formData.getFieldForClient("clientEIKEGN"),
        vatNum: formData.getFieldForClient("clientVatNum"),
        settlement: formData.getFieldForClient("clientSettlement"),
        task_id: formData.getFieldForClient("task"),
    }

    function handleChange(e) {
        const { name, value } = e.target

        formData.setFieldsFormats(name, value)
    }

    const { handleClose, handleOpen, isOpened } = useOpenWindow()
    const { createInvoice, loading, handleValidations } = useCreateInvoice({
        endpoint:
            ENDPOINTS.ADMIN.CREATE_INVOICE,
        closWindowCB: closeWindow,
    })

    function triggerOpen(taskId) {
        selectClient(taskId)
        handleOpen()
    }
    useImperativeHandle(ref, () => ({
        open: triggerOpen,
        close: handleClose,
    }))

    function validate(fieldName) {
        return handleValidations().validate(fieldName)
    }

    function closeWindow() {
        handleClose()
        formData.resetFields()
        resetForm()
    }

    function resetForm() {
        formRef.current.reset()
        handleValidations().clear()
    }

    return (
        <div
            className={`overlay add-edit-user-overlay ${isOpened() ? "show" : ""}`}
            key={isOpened()}
        >
            <form className={loadingData ? 'loading' : ''} action="#" autoComplete="off" onSubmit={createInvoice} ref={formRef} enctype="multipart/form-data">
                <b>Издаване на фактура</b>
                <Tooltip title="Затваряне">
                    <span className="close" onClick={closeWindow}></span>
                </Tooltip>
                <div className="fields">
                    <b>Данни за клиент</b>
                    <div style={{ marginTop: '10px' }} className="row">
                        <div className="col">
                            <label htmlFor="client-name">Име<b style={{color:'red'}}>*</b></label>
                            <input
                                type="text"
                                name="clientName"
                                id="client-name"
                                placeholder="Въведете Име и Фамилия..."
                                value={selectedClient.name}
                                onChange={handleChange}
                                readOnly={viewFromHistory}
                                autoComplete="off"
                            />
                            {validate("clientName").message ? (
                                <p className="error-text">{validate("clientName").message}</p>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="col">
                            <label htmlFor="client-mol">МОЛ</label>
                            <input
                                type="text"
                                name="clientMOL"
                                id="client-mol"
                                placeholder="Въведете МОЛ..."
                                value={selectedClient.mol}
                                onChange={handleChange}
                                readOnly={viewFromHistory}
                                autoComplete="off"
                            />
                            {validate("clientMOL").message ? (
                                <p className="error-text">{validate("clientMOL").message}</p>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="eikegn">ЕИК/ЕГН<b style={{color:'red'}}>*</b></label>
                            <input
                                type="text"
                                name="clientEIKEGN"
                                id="client-eikegn"
                                placeholder="Въведете ЕИК/ЕГН..."
                                value={selectedClient.eikegn}
                                onChange={handleChange}
                                readOnly={viewFromHistory}
                                autoComplete="off"
                            />
                            {validate("clientEIKEGN").message ? (
                                <p className="error-text">{validate("clientEIKEGN").message}</p>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="col">
                            <label htmlFor="client-vatNum">ЗДДС №</label>
                            <input
                                type="text"
                                name="clientVatNum"
                                id="client-vatNum"
                                placeholder="Въведете ЗДДС №..."
                                value={selectedClient.vatNum}
                                onChange={handleChange}
                                readOnly={viewFromHistory}
                                autoComplete="off"
                            />
                            {validate("clientVatNum").message ? (
                                <p className="error-text">{validate("clientVatNum").message}</p>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="client-location">Адрес<b style={{color:'red'}}>*</b></label>
                            <input
                                type="text"
                                name="clientLocation"
                                id="client-location"
                                placeholder="Въведете адрес..."
                                value={selectedClient.location}
                                onChange={handleChange}
                                readOnly={viewFromHistory}
                                autoComplete="off"
                            />
                            {validate("clientLocation").message ? (
                                <p className="error-text">{validate("clientLocation").message}</p>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="col">
                            <label htmlFor="client-settlement">Населено място</label>
                            <input
                                type="text"
                                name="clientSettlement"
                                id="client-settlement"
                                placeholder="Въведете населено място"
                                value={selectedClient.settlement}
                                onChange={handleChange}
                                readOnly={viewFromHistory}
                                autoComplete="off"
                            />
                            {validate("clientSettlement").message ? (
                                <p className="error-text">{validate("clientSettlement").message}</p>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="client-email">Е-Поща</label>
                            <input
                                type="text"
                                name="clientEmail"
                                id="client-email"
                                placeholder="Въведете Е-поща..."
                                value={selectedClient.email}
                                onChange={handleChange}
                                readOnly={viewFromHistory}
                                autoComplete="off"
                            />
                            {validate("clientEmail").message ? (
                                <p className="error-text">{validate("clientEmail").message}</p>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className="col">
                            <label htmlFor="client-phone">Телефонен номер</label>
                            <input
                                type="number"
                                name="clientPhone"
                                id="client-phone"
                                placeholder="Въведете Тел. номер..."
                                value={selectedClient.phone}
                                onChange={handleChange}
                                readOnly={viewFromHistory}
                                autoComplete="off"
                            />
                            {validate("clientPhone").message ? (
                                <p className="error-text">{validate("clientPhone").message}</p>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <br />
                    <input
                        type="hidden"
                        name="task"
                        id="task-id"
                        value={selectedClient.task_id}
                    />
                    <input
                        type="hidden"
                        name="client"
                        id="client-id"
                        value={selectedClient.id}
                    />
                </div>
                <div className="buttons">
                    <button className="btn-secondary" onClick={closeWindow} type="button">
                        Отказ
                    </button>
                    <button className={`btn-primary ${loading ? "loading" : ""}`}>
                        Създай
                    </button>
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddInvoice)