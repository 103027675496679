import { forwardRef, useImperativeHandle, useState } from "react"

//hooks
import useOpenWindow from "hooks/useOpenWindow"

//libraries
import { Tooltip } from "@mui/material"
import moment from "moment-timezone"

//providers
import { useFormDataContext } from "../providers/form"

//constants
import { SERVER_DATE_FORMAT } from "constants/formats"

const INITIAL_MAIN_STATE = {
  user: null,
  startHour: null,
  selectedDate: null,
}
const INITIAL_HOURS_STATE = {
  start: null,
  end: null,
}
function AddHour({ added }, ref) {
  const formData = useFormDataContext()

  const [state, setState] = useState(INITIAL_MAIN_STATE)
  const [hours, setHours] = useState(INITIAL_HOURS_STATE)
  const { user, startHour, selectedDate } = state

  const { handleClose, handleOpen, isOpened } = useOpenWindow()

  useImperativeHandle(ref, () => ({
    open: openWindow,
    close: closeWindow,
  }))

  function openWindow(data) {
    handleOpen()
    setState(data)

    setEndHourAutomaticly("start", data.startHour)
  }

  function closeWindow() {
    setState(INITIAL_MAIN_STATE)
    setHours(INITIAL_HOURS_STATE)
    handleClose()
  }

  function addHour(name, hour) {
    if (!name) return

    const hourServerFormat = !hour
      ? null
      : moment(`${selectedDate} ${hour}`, `${SERVER_DATE_FORMAT} HH:mm`).format(
          `${SERVER_DATE_FORMAT} HH:mm`
        )

    const hourClientFormat = !hour
      ? null
      : moment(`${selectedDate} ${hour}`, `${SERVER_DATE_FORMAT} HH:mm`).format(
          `${SERVER_DATE_FORMAT}THH:mm`
        )

    formData.setFieldsFormats(name, hourClientFormat, hourServerFormat)

    if (name === "start") {
      formData.setFieldsFormats("startHHMM", hour)
    }

    if (name === "end") {
      formData.setFieldsFormats("endHHMM", hour)
    }
  }

  function handleBlurStartData(e) {
    const { name, value } = e.target

    setEndHourAutomaticly(name, value)
  }

  function setEndHourAutomaticly(name, value) {
    if (name === "start" && !hours.end) {
      //Формата от полето е 12 (часов) AM/PM
      const end = moment(value, "hh:mm").add(1, "hour").format("hh:mm")

      setHours((prev) => ({
        ...prev,
        end,
      }))
    }
  }

  function handleAdd() {
    addHour("start", hours.start || startHour)
    addHour("end", hours.end)

    formData.setFieldsFormats("users", [user], [user.id])

    added()
    closeWindow()
  }

  function handleChange(e) {
    const { name, value } = e.target

    setHours((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <div className={`overlay add-hour-overlay ${isOpened() ? "show" : ""}`}>
      <div className="modal">
        <b>Добавяне на час</b>
        <Tooltip title="Затваряне">
          <span className="close" onClick={closeWindow}></span>
        </Tooltip>
        <div className="fields">
          <div className="row">
            <div className="col">
              <label htmlFor="selected-date">Дата</label>
              <h6 id="selected-date">{selectedDate}</h6>
            </div>
            <div className="col">
              <label htmlFor="selected-date">Изпълнител</label>
              <h6 id="selected-date">{user?.shortName}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="start-hour">Начало</label>
              <input
                type="time"
                id="start-hour"
                name="start"
                onChange={handleChange}
                value={hours.start || startHour || ""}
                onBlur={handleBlurStartData}
              />
            </div>
            <div className="col">
              <label htmlFor="end-hour">Край (опционално)</label>
              <input
                type="time"
                id="end-hour"
                onChange={handleChange}
                name="end"
                value={hours.end || ""}
              />
            </div>
          </div>
        </div>
        <div className="buttons">
          <button className="btn-secondary" onClick={closeWindow}>
            Назад
          </button>
          <button className="btn-primary" onClick={handleAdd}>
            Добави
          </button>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(AddHour)
