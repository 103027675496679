import { forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import { useNavigate } from "react-router-dom"

//libraries
import { Tooltip } from "@mui/material"

//helpers
import { getUrlData, getUrlExeptPassedParam } from "helpers/url"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useCreateEditUser from "../hooks/useCreateEditUser"
import useFetchUser from "../hooks/useFetchUser"
import useFetchRoles from "../hooks/useFetchRoles"

//providers
import { useAppContext } from "providers/app"

//components
import Loader from "components/app/components/Loader"
import ChangePassword from "./ChangePassword"

//images
import passwordIcon from "assets/images/users/icons/password.svg"

function AddEdit(_, ref) {
  const formRef = useRef()
  const changePasswordRef = useRef()

  const app = useAppContext()
  useFetchRoles()
  const roles = app.handleRoles().getRoles()

  const userId = getUrlData().edit_user_id

  const navigate = useNavigate()

  const { handleClose, handleOpen, isOpened } = useOpenWindow()
  const { handleSubmit, loading, handleValidations } = useCreateEditUser(
    userId,
    closeWindow
  )
  const { getUser, resetUser, loadingUser } = useFetchUser(userId, isOpened)
  const { name, number, role_id, salary } = getUser()

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }))

  function closeWindow() {
    navigate({
      search: getUrlExeptPassedParam("edit"),
    })
    handleClose()
    resetForm()
  }

  function resetForm() {
    formRef.current.reset()
    handleValidations().clear()
    resetUser()
  }

  useEffect(() => {
    if (userId) handleOpen()
  }, [userId])

  function validate(fieldName) {
    return handleValidations().validate(fieldName)
  }

  return (
    <>
      <div
        className={`overlay add-edit-user-overlay ${isOpened() ? "show" : ""}`}
        key={isOpened()}
      >
        <form
          action="#"
          autoComplete="off"
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <b>{userId ? "Редакция" : "Добавяне"} на потребител</b>
          <Tooltip title="Затваряне">
            <span className="close" onClick={closeWindow}></span>
          </Tooltip>
          {loadingUser ? (
            <Loader />
          ) : (
            <div className="fields">
              <div className="row">
                <div className="col">
                  <label htmlFor="type">Тип потребител</label>
                  <select
                    id="type"
                    name="role_id"
                    className={validate("role_id").className}
                    defaultValue={role_id || 0}
                  >
                    <option value="0" disabled>
                      Избор
                    </option>
                    {roles.map((r) => (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ))}
                  </select>
                  {validate("role_id").message ? (
                    <p className="error-text">{validate("role_id").message}</p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col small">
                  <label htmlFor="salary">Заплата</label>
                  <input
                    type="number"
                    id="salary"
                    autoComplete="off"
                    placeholder="Сума"
                    min="0"
                    step="1"
                    name="salary"
                    className={validate("salary").className}
                    defaultValue={salary?.salary}
                  />
                  {validate("salary").message ? (
                    <p className="error-text">{validate("salary").message}</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col">
                <label htmlFor="name">Име и Фамилия</label>
                <input
                  type="text"
                  id="name"
                  autoComplete="off"
                  placeholder="Въведете Име и Фамилия..."
                  className={validate("name").className}
                  name="name"
                  defaultValue={name}
                />
                {validate("name").message ? (
                  <p className="error-text">{validate("name").message}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="col">
                <label htmlFor="phone">Тел. номер</label>
                <input
                  type="tel"
                  id="phone"
                  autoComplete="off"
                  placeholder="Въведете Тел. номер..."
                  name="number"
                  className={validate("number").className}
                  defaultValue={number}
                />
                {validate("number").message ? (
                  <p className="error-text">{validate("number").message}</p>
                ) : (
                  <></>
                )}
              </div>
              {!userId ? (
                <div className="row">
                  <div className="col">
                    <label htmlFor="password">Парола</label>
                    <input
                      type="password"
                      id="password"
                      autoComplete="off"
                      placeholder="Въведете Парола..."
                      name="password"
                      className={
                        handleValidations().check("password") ? "validate" : ""
                      }
                    />
                    {handleValidations().check("password") ? (
                      <p className="error-text">
                        {handleValidations().getMessage("password")}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col">
                    <label htmlFor="repeatPassword">Повтори паролата</label>
                    <input
                      type="password"
                      id="repeatPassword"
                      autoComplete="off"
                      placeholder="Повторете паролата..."
                      name="confirm_password"
                      className={
                        handleValidations().check("confirm_password")
                          ? "validate"
                          : ""
                      }
                    />
                    {handleValidations().check("confirm_password") ? (
                      <p className="error-text">
                        {handleValidations().getMessage("confirm_password")}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <span
                  className="change-password"
                  onClick={() => {
                    changePasswordRef.current.open()
                  }}
                >
                  <img src={passwordIcon} />
                  Сняма на паролата
                </span>
              )}
            </div>
          )}
          <div className="buttons">
            <button
              className="btn-secondary"
              onClick={closeWindow}
              type="button"
            >
              Отказ
            </button>
            <button className={`btn-primary ${loading ? "loading" : ""}`}>
              {userId ? "Редактирай" : "Добави"}
            </button>
          </div>
        </form>
      </div>
      <ChangePassword ref={changePasswordRef} handleCloseParent={closeWindow} />
    </>
  )
}

export default forwardRef(AddEdit)
