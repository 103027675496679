//images
import noResultsIcon from "assets/images/app/icons/no-results.svg"

const NoResultsFound = ({ text = null }) => {
  return (
    <div className="no-results">
      <img src={noResultsIcon} />
      <b>{text || "Не бяха открити резултати."}</b>
    </div>
  )
}

export default NoResultsFound
