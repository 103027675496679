import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"

//libraries
import { Tooltip } from "@mui/material"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useImportFile from "../hooks/useImportFile"

//constants
import { ENDPOINTS } from "constants/endpoints"

function Import({}, ref) {
  const formRef = useRef()
  const fileRef = useRef()
  const [buttonName, setButtonName] = useState('Изберете файл')

  const { handleClose, handleOpen, isOpened } = useOpenWindow()
  const { handleSubmit, loading, handleValidations } = useImportFile({
    endpoint:
      ENDPOINTS.ADMIN.IMPORT_FILE,
    closWindowCB: closeWindow,
  })

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }))

  function closeWindow() {
    handleClose()
    setButtonName('Изберете файл')
    resetForm()
  }

  function resetForm() {
    formRef.current.reset()
    handleValidations().clear()
  }

  function fileChange(event) {
    setButtonName(event.target.files[0].name)
  }

  return (
    <div
      className={`overlay add-edit-user-overlay ${isOpened() ? "show" : ""}`}
      key={isOpened()}
    >
      <form action="#" autoComplete="off" onSubmit={handleSubmit} ref={formRef} enctype="multipart/form-data">
        <b>Импорт на XML файл</b>
        <Tooltip title="Затваряне">
          <span className="close" onClick={closeWindow}></span>
        </Tooltip>
        <div className="fields">
          <div className="row">
            <div className="col">
              <label htmlFor="XML">Файл</label>
              <button className="btn-primary" style={{position:'relative'}}>
              <input
                style={{ opacity:'0', position: 'absolute',left:'0',top:'0',width:'100%',height:'100%'}}
                accept="text/xml"
                type="file"
                id="XML"
                name="XML"
                ref={fileRef}
                onChange={fileChange}
              />
             {buttonName}
              </button>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button className="btn-secondary" onClick={closeWindow} type="button">
            Отказ
          </button>
          <button className={`btn-primary ${loading ? "loading" : ""}`}>
            Добави
          </button>
        </div>
      </form>
    </div>
  )
}

export default forwardRef(Import)
