//hooks
import useFetch from "hooks/useFetch"

//constatns
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useAppContext } from "providers/app"
import { useUsersDataContext } from "../providers/users"

function useUpdateSalary(salaryId, successCB) {
    const app = useAppContext()
    const users = useUsersDataContext()

    const { fetch, loading, validations, handleValidations } = useFetch()
    function handleSubmit(e) {
        e.preventDefault()
        const data = new FormData(e.target)

        fetch({ endpoint: `${ENDPOINTS.ADMIN.UPDATE_WORKER_SALARY}/${salaryId}`, method: 'post', data })
            .then(() => {
                users.refetchData()
                app
                    .handleMessage()
                    .success()
                    .show('Заплатата беше актуализиране успешно!')
                successCB()
            })
    }

    return {
        handleSubmit,
        loading,
        validations,
        handleValidations
    }
}

export default useUpdateSalary