//providers
import { useFilterDataContext } from "../providers/filter"

function FilterByDate() {
  const filter = useFilterDataContext()
  const { getDateFormatted, setDate } = filter

  function handleChange(e) {
    const { value } = e.target

    setDate(value)
  }
  
  return (
    <div className="date-filter">
      <div className="label">{getDateFormatted()} г.</div>
      <input type="date" onChange={handleChange} required />
    </div>
  )
}

export default FilterByDate
