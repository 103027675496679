import { useCallback, useState } from "react"

//libraries
import { Tooltip } from "@mui/material"
import { Column } from "@ant-design/plots"
import moment from "moment-timezone"

//constants
import { SERVER_DATE_FORMAT } from "constants/formats"

//hooks
import useCategoryChange from "components/users/hooks/useCategoryChange"
import useSetPeriod from "components/users/hooks/useSetPeriod"
import useFetchWorkersColumnTransactions from "../hooks/useFetchWorkersColumnTransactions"
import useUserChange from "../hooks/useUserChange"

//images
import incomesIcon from "assets/images/users/icons/incomes.svg"
import expensesIcon from "assets/images/users/icons/expenses.svg"

//providers
import { useAppContext } from "providers/app"

function WorkersTransactionsColumn({ getUsers }) {
  const app = useAppContext()

  const { setPeriodType, handleChanegeDate, getSelectedPeriod } = useSetPeriod()
  const { handleCategoryChange, getSelectedCategoryId } = useCategoryChange()
  const { handleUserChange, getSelectedUserId } = useUserChange()
  const [startDate, setStartDate] = useState(moment().startOf('month').format(SERVER_DATE_FORMAT))
  const [endDate, setEndDate] = useState(moment().endOf('month').format(SERVER_DATE_FORMAT))
  const { getTransactions, loading } = useFetchWorkersColumnTransactions(
    getSelectedCategoryId(),
    getSelectedUserId(),
    startDate,
    endDate,
  )
  const config = {
    data: getTransactions().data,
    xField: "initials",
    yField: "value",
    seriesField: "type",
    group: true,
    groupBy: ['initials', 'type'],
    color: ["#3ED19D", "#FF7079"],
    legend: true,
    column: {
      pointWidth: 10
    },
    legend: {
      position: 'top-right',
      itemName: {
        style: {
          fill: '#000',
        },

        formatter: (seriesField) => {
          if (seriesField === "expense") {
            return 'Разход';
          }
          if (seriesField === "income") {
            return 'Приход';
          }
          return seriesField;
        },
        //formatter: (name) => name,

      },
    },
    tooltip: {
      title: "user",
      formatter: (data) => {
        return {
          name: data.type === "income" ? "Приход" : "Разход",
          value: `${(data.value).toFixed(2)}  лв.`,
        }
      },
    },
    style: {
      fillOpacity: 0.1,
      inset: 5,
    },
    height: 200,
  }

  const getColumnChart = useCallback(() => {
    if (!getTransactions().data) return

    return <Column {...config} />
  }, [getTransactions()])

  function getCategories() {
    const categories = app.handleCategories().get()

    return Object.values(categories)
  }

  return (
    <div className={`transactions col ${loading ? "loading" : ""}`}>
      <div className="top row">
        <b>Приходи и Разходи по служители</b>
        <div className="legend row">
          <div style={{ marginRight: "15px" }}>
            <label htmlFor="start-date">Начало:</label>
            <input
              name="start-date"
              id="start-date"
              type="date"
              defaultValue={moment().startOf('month').format(SERVER_DATE_FORMAT)} onChange={(sd) => setStartDate(sd.target.value)} />
          </div>
          <div>
            <label htmlFor="end-date">Край:</label>
            <input
              name="end-date"
              id="end-date"
              type="date"
              defaultValue={moment().endOf('month').format(SERVER_DATE_FORMAT)} onChange={(ed) => setEndDate(ed.target.value)} />
          </div>

          {/* <ul className="by-period row">
            <Tooltip title={moment(startDate).format('LL')}>
              <li>
                От <input name="start-date" id="start-date" type="date" defaultValue={moment().format(SERVER_DATE_FORMAT)} onChange={(sd) => setStartDate(sd.target.value)} />
              </li>              
            </Tooltip>
            <b>
                {moment(startDate).format('LL')}
              </b>
            <Tooltip title={moment(endDate).format('LL')}>
              <li>
                До <input name="end-date" id="end-date" type="date" defaultValue={moment().add(1,"week").format(SERVER_DATE_FORMAT)} onChange={(ed) => setEndDate(ed.target.value)} />
              </li>
            </Tooltip>
            <b>
              {moment(endDate).format('LL')}
              </b>
          </ul> */}
        </div>
      </div>
      <div className="bottom row">
        <div className="left col">
          <div className="col">
            <select
              name="task_type"
              value={getSelectedUserId()}
              onChange={handleUserChange}
            >
              <option value="0">Всички потребители</option>
              {getUsers().map((u) => (
                <option key={`user-${u.id}`} value={u.id}>
                  {u.name}
                </option>
              ))}
            </select>
            <select
              name="task_type"
              value={getSelectedCategoryId()}
              onChange={handleCategoryChange}
            >
              <option value="0">Всички категории</option>
              {getCategories().map((c) => (
                <optgroup label={c.name} key={`category-${c.id}`}>
                  {c.subcategories.map((s) => (
                    <option
                      key={`subcategory-${s.service_id}`}
                      value={s.service_id}
                    >
                      {s.service_name}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>
          <div className="col">
            <div className="row">
              <img src={incomesIcon} alt="" />
              <div className="r col">
                <span>Приходи</span>
                <b>{(getTransactions().total_revenues || 0).toFixed(2)} лв.</b>
              </div>
            </div>
            <div className="row">
              <img src={expensesIcon} alt="" />
              <div className="r col">
                <span>Разходи</span>
                <b>{(getTransactions().total_expenses || 0).toFixed(2)} лв.</b>
              </div>
            </div>
          </div>
        </div>
        <div className="right">{getColumnChart()}</div>
      </div>
    </div>
  )
}

export default WorkersTransactionsColumn
