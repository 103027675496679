import { useState } from "react"

function useUserChange() {
    const [selectedId, setSelectedId] = useState(0)

    function handleUserChange(e) {
        const { value } = e.target

        setSelectedId(value)
    }

    function getSelectedUserId() {
        return selectedId
    }

    return {
        handleUserChange,
        getSelectedUserId
    }
}

export default useUserChange