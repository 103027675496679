//helpers
import { getNameFirstDigits } from "../helpers/name"

//images
import phoneIcon from "assets/images/users/icons/phone.svg"

//libraries
import { Tooltip } from "@mui/material"

//providers
import { useAppContext } from "providers/app"

//components
import Loader from "components/app/components/Loader"

//hooks
import useFetchRoles from "../hooks/useFetchRoles"

function PersonalData({ userData, loading }) {
  const app = useAppContext()

  useFetchRoles()

  const { name, number, role_id } = userData
  const role = app.handleRoles().getRoleByRoleId(role_id)

  if (loading || !name) return <Loader noPadding={true} />

  return (
    <div className="top">
      <div className="picture">{getNameFirstDigits(name || "")}</div>
      <div className="right">
        <span>{role?.name || "-"}</span>
        <b>{name}</b>
        <Tooltip title="Свържете се с работникът">
          <a href={`tel:${number}`} className="link">
            <img src={phoneIcon} alt="" />
            {number}
          </a>
        </Tooltip>
      </div>
    </div>
  )
}

export default PersonalData
