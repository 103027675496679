//components
import DescriptionFields from "./DescriptionFields"
import ExtraDescription from "./ExtraDescription"
import SelectCategory from "./SelectCategory"
import SelectDateAndHour from "./SelectDateAndHour"
import SelectWorker from "./SelectWorker"
import TaskPayment from "./TaskPayment"
import UploadedFiles from "./UploadedFiles"

function FormTaskData({ handleValidations, viewFromHistory }) {
  return (
    <div className="tab task-data">
      <SelectCategory viewFromHistory={viewFromHistory} />
      <div className="row worker-data">
        <SelectWorker viewFromHistory={viewFromHistory} />
        <SelectDateAndHour
          handleValidations={handleValidations}
          viewFromHistory={viewFromHistory}
        />
      </div>
      <div className="row extra-data">
        {/* <DescriptionFields viewFromHistory={viewFromHistory} /> */}
        <ExtraDescription
          handleValidations={handleValidations}
          viewFromHistory={viewFromHistory}
        />
        <UploadedFiles viewFromHistory={viewFromHistory} />
      </div>
      <hr />
      <TaskPayment viewFromHistory={viewFromHistory} />
      {/* <ExtraDescription
        handleValidations={handleValidations}
        viewFromHistory={viewFromHistory}
      /> */}
    </div>
  )
}

export default FormTaskData
