import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { useNavigate } from "react-router-dom"

//libraries
import { Tooltip } from "@mui/material"

//hooks
import useFetchTransactions from "../hooks/useFetchTransactions"
import usePrevParams from "hooks/usePrevParams"
import useRemoveTransactions from "../hooks/useRemoveTransactions"

//components
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"
import Remove from "components/app/components/Remove"
import AddTransaction from "./AddTransaction"

//images
import descriptionIcon from "assets/images/schedule/icons/description.svg"
import addIncomeIcon from "assets/images/schedule/icons/add-income.svg"

//constants
import { ENDPOINTS } from "constants/endpoints"

//helpers
import { getUrlData } from "helpers/url"

const TRANSACTION_TYPE = "INCOMES"
function IncomesList({ taskId, viewFromHistory }) {
  const removeRef = useRef()
  const addTransactionRef = useRef()

  const navigate = useNavigate()

  const { getData, loading } = useFetchTransactions(
    ENDPOINTS.SHARED.GET_INCOMES,
    taskId,
    TRANSACTION_TYPE
  )
  const { getPrevParams } = usePrevParams()
  const incomeId = getUrlData().remove_income_id
  const { handleRemove } = useRemoveTransactions(
    `${ENDPOINTS.SHARED.REMOVE_INCOME}?revenue_id=${incomeId}`,
    removeRef,
    TRANSACTION_TYPE
  )

  useEffect(() => {
    if (incomeId) removeRef.current.open()
  }, [incomeId])

  function renderList() {
    if (loading) {
      return <Loader />
    }

    if (!loading && !getData().length) {
      return <NoResultsFound text="Няма добавени приходи" />
    }

    return (
      <ul className="list">
        {getData().map((i) => (
          <li key={`income-${i.id}`}>
            <div className="row">
              <b>{i.revenue.toFixed(2)} лв.</b>
              {i.can_delete && !viewFromHistory ? (
                <Tooltip title="Премахване">
                  <span
                    className="remove"
                    onClick={() => {
                      prepareRemove(i.id)
                    }}
                  ></span>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
            <p>{i.description}</p>
            <i>Приходът е направен от {i.user?.name || "-"}</i>
          </li>
        ))}
      </ul>
    )
  }

  function prepareRemove(incomeId) {
    navigate({
      search: `${getPrevParams()}remove_income_id=${incomeId}`,
    })
  }

  function addIncome() {
    addTransactionRef.current.open()
  }

  return (
    <>
      {createPortal(
        <>
          <Remove
            ref={removeRef}
            elementsContent={{
              heading: "Премахване на прихода",
            }}
            accept={handleRemove}
          />
          <AddTransaction
            ref={addTransactionRef}
            taskId={taskId}
            type={TRANSACTION_TYPE}
          />
        </>,
        document.body
      )}
      <div className="col">
        <div className="row">
          <h6>
            <img src={descriptionIcon} alt="" />
            Приходи
          </h6>
          {!viewFromHistory ? (
            <button className="add" onClick={addIncome}>
              <img src={addIncomeIcon} alt="" />
              Приход
            </button>
          ) : (
            <></>
          )}
        </div>
        {renderList()}
      </div>
    </>
  )
}

export default IncomesList
