import { useEffect, useState } from "react"

//constants
import { ENDPOINTS } from "constants/endpoints"

//hooks
import useFetch from "hooks/useFetch"

function useFetchUser(userId, isOpened = null) {
    const { fetch, loading } = useFetch()

    const [user, setUser] = useState({})

    useEffect(() => {
        if (userId && isOpened?.() || (isOpened === null)) _fetchData()
    }, [isOpened?.()])

    function _fetchData() {
        fetch({ endpoint: `${ENDPOINTS.ADMIN.GET_USER}?user_id=${userId}`, method: 'get' })
            .then(res => {
                console.log(res.data)
                setUser(res.data)
            })
    }

    function getUser() {
        return user
    }

    function resetUser() {
        setUser({})
    }

    function refetchData() {
        _fetchData()
    }

    return {
        getUser,
        loadingUser: loading,
        refetchData,
        resetUser
    }
}

export default useFetchUser