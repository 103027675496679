//components
import ExpensesList from "./ExpensesList"
import IncomesList from "./IncomesList"

//helpers
import { getUrlData } from "helpers/url"

function FormTransactions({ viewFromHistory }) {
  const taskId = getUrlData().edit_task_id

  return (
    <>
      <div className="tab transactions">
        <div className="row">
          <IncomesList taskId={taskId} viewFromHistory={viewFromHistory} />
          <ExpensesList taskId={taskId} viewFromHistory={viewFromHistory} />
        </div>
      </div>
    </>
  )
}

export default FormTransactions
