import { createContext, useContext, useMemo, useState } from "react"

export const CommentsDataContext = createContext({})

const CommentsDataProvider = props => {
    const [refresh, setRefresh] = useState(null)

    function refreshData() {
        setRefresh(new Date().getTime())
    }

    function getRefreshed() {
        return refresh
    }

    const exportedData = useMemo(() => {
        return {
            refreshData,
            getRefreshed
        }
    }, [refresh])

    return <CommentsDataContext.Provider value={exportedData} {...props} />
}

export const useCommentsDataContext = () => useContext(CommentsDataContext)

export default CommentsDataProvider