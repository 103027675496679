import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

//images
import yellowBellIcon from "assets/images/app/header/icons/yellow-bell.svg"
import closeIcon from "assets/images/schedule/icons/close-dark.svg"

//constants
import { NOTIFICATION_TYPES } from "constants/notifications"

//providers
import { useAppContext } from "providers/app"

function NotificationPopup({ data, handleOpen }) {
  const app = useAppContext()
  const { workorder_id, custom_type, tasks_count, message } = data

  const navigate = useNavigate()

  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    if (data) setHidden(false)
  }, [data])

  function handleView() {
    if (tasks_count > 1) {
      handleOpen()
    } else {
      switch (custom_type) {
        case "FINISHED_TASK":
          navigate(`?edit_task_id=${workorder_id}&task_preview=1`)
          break
        case "COMMENTED":
          navigate(`?edit_task_id=${workorder_id}&task_tab_index=3`)
          break
        default:
          navigate(`?edit_task_id=${workorder_id}`)
          break
      }
    }
    setHidden(true)
    app.handleNewNotification().set(false)
  }

  function getHeading() {
    if (message) {
      return message
    }

    if (tasks_count > 1) {
      return `Имате ${tasks_count} нови известия.`
    }

    return NOTIFICATION_TYPES[custom_type]?.name || ""
  }

  return data && !hidden ? (
    <div
      className="new-notification"
      onClick={(e) => {
        if (e.target.classList.contains("close")) return

        handleView()
      }}
    >
      <b>
        <img src={yellowBellIcon} alt="" /> {getHeading()}
      </b>
      <span
        className="close"
        onClick={() => {
          setHidden(true)
        }}
      >
        <img src={closeIcon} alt="" />
      </span>
      <p>
        Прегледайте извеситието, избирайки върху този прозорец и вижте новите
        дейности.
      </p>
    </div>
  ) : (
    <></>
  )
}

export default NotificationPopup
