import { useRef } from "react"

//images
import playIcon from "assets/images/schedule/icons/play.svg"
import pauseIcon from "assets/images/schedule/icons/pause.svg"

//libraries
import { Tooltip } from "@mui/material"
import useControlAudioRecord from "../hooks/useControlAudioRecord"

function AudioRecord({ url, removeRecord, viewFromHistory }) {
  const audioRef = useRef()
  const { isPlaying, handleAudioEnded, handleControlBtn, getDurationAsString } =
    useControlAudioRecord(audioRef)

  return (
    <li>
      <Tooltip title={isPlaying ? "Пауза" : "Пускане"}>
        <span className="control" onClick={handleControlBtn}>
          <img src={isPlaying ? pauseIcon : playIcon} alt="" />
        </span>
      </Tooltip>
      <time>{getDurationAsString()}</time>
      {!viewFromHistory ? (
        <Tooltip title="Премахване">
          <span className="remove" onClick={removeRecord}></span>
        </Tooltip>
      ) : (
        <></>
      )}
      <audio src={url} ref={audioRef} onEnded={handleAudioEnded}></audio>
    </li>
  )
}

export default AudioRecord
