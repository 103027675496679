//components
import FilterByUsers from "./FilterByUsers"
import FilterByTaskType from "./FilterByTaskType"
// import FilterByClient from "./FilterByClient"

//libraries
import { Tooltip } from "@mui/material"

//providers
import { useScheduleDataContext } from "../providers/schedule"

function TaskRelatedFilters() {
  const scheduleData = useScheduleDataContext()
  const sh = scheduleData.handleFilters()

  function searchByName(e) {
    const { name, value } = e.target

    sh.set(name, value)
  }

  return (
    <div className="row">
      <input
        type="text"
        placeholder="Търсене"
        className="search-by-name"
        onChange={searchByName}
        name="name"
        value={sh.get("name") || ""}
        autoComplete="off"
      />
      <FilterByUsers />
      <FilterByTaskType />
      {/* Когато е избран резултате от филтра с клиенти, 
      който не е от страница 1, след презареждане 
      избраните клиенти няма да се визуализират като избрани в полето, 
      защото се зарежда само първа страница. 
      Ще се визуализират чак след появяването им в списъка */}
      {/* <FilterByClient /> */}
      <Tooltip title="Изчисти всички филтри">
        <button
          className="clear-all"
          onClick={() => {
            scheduleData.handleFilters().clearAll()
          }}
        ></button>
      </Tooltip>
    </div>
  )
}

export default TaskRelatedFilters
