import { useEffect, useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useCommentsDataContext } from "../providers/comments"

function useFetchComments(taskId) {
    const commentsData = useCommentsDataContext()

    const { fetch, loading } = useFetch()

    const [comments, setComments] = useState([])

    useEffect(() => {
        fetchComments()
    }, [commentsData.getRefreshed()])

    function fetchComments() {
        fetch({
            endpoint: `${ENDPOINTS.SHARED.GET_COMMENTS}?order_id=${taskId}`,
            method: "get",
        })
            .then((res) => {
                setComments(res.data.comments || [])
            })
    }

    function getComments() {
        return comments
    }

    return {
        getComments,
        loading,
    }
}

export default useFetchComments