import { useState } from "react"

//hooks
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useFormDataContext } from "../providers/form"

function useFetchFreeWorkers() {
    const { fetch, loading } = useFetch()

    const formData = useFormDataContext()

    const [workers, setWorkers] = useState([])

    function fetchData(startTime, endTime, taskId) {
        fetch({
            endpoint: `${ENDPOINTS.ADMIN.GET_FREE_WORKERS}?start=${startTime || 0}&end=${endTime || 0}&task_id=${taskId || 0}`,
            method: "get",
        })
            .then((res) => {
                const { workersData, extraWorkersData } = res.data
                setWorkers(workersData)
                if(taskId) formData.setFieldsFormats('extraWorkers', extraWorkersData)
                
            })            
    }

    function getWorkers() {
        return workers
    }

    function getExtraWorkers() {
        return formData.getFieldForClient("extraWorkers")
    }

    return {
        getWorkers,
        getExtraWorkers,
        loading,
        fetchData
    }
}

export default useFetchFreeWorkers