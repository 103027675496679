import { createContext, useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

//constants
import { ENDPOINTS } from "constants/endpoints"

//providers
import { useAppContext } from "./app"

//hooks
import useFetch from "hooks/useFetch"

export const AuthDataContext = createContext({})

const initialAuthData = {
    isLogged: false,
    data: {},
}

const AuthDataProvider = props => {
    const { fetch } = useFetch()
    const app = useAppContext()
    const navigate = useNavigate()
    const [authData, setAuthData] = useState(initialAuthData)
    const currentAuthData = JSON.parse(localStorage.getItem('auth')) || initialAuthData

    useEffect(() => {
        handleAuth().init()
    }, [])

    useEffect(() => {
        if (handleAuth().get().isLogged()) handleAuth().loadUser()
        else finishedUserCheck()
    }, [handleAuth().get().isLogged()])

    function handleAuth() {
        function login(data) {
            set(true, data)
            navigate('/')
        }

        function set(isLogged, data) {
            setAuthData(prev => ({
                ...prev,
                isLogged,
                data
            }))

            if (isLogged) localStorage.setItem('auth', JSON.stringify({ isLogged, data }))
            else localStorage.removeItem('auth')
        }

        function init() {
            set(currentAuthData.isLogged, currentAuthData.data)
        }

        function get() {
            const data = () => Object.keys(authData?.data).length ? authData?.data : JSON.parse(localStorage.getItem('auth'))?.data || {}
            const user = () => data().user
            const isLogged = () => authData.isLogged || currentAuthData.isLogged
            const token = () => data()?.access_token || null

            return {
                data,
                isLogged,
                user,
                token
            }
        }

        function isHeadAdmin() {
            if (get().user().role_id === 1) {
                return true
            }

            return false
        }

        function logout() {
            set(initialAuthData.isLogged, initialAuthData.data)
            navigate('/')
        }

        function loadUser() {
            fetch({ endpoint: ENDPOINTS.AUTH.GET_USER, method: 'post' })
                .then(res => {
                    set(true, { ...currentAuthData.data, user: res.data })
                    finishedUserCheck()
                })
                .catch(() => {
                    logout()
                })
                .finally(() => {
                    finishedUserCheck()
                })
        }

        return {
            set,
            get,
            logout,
            init,
            login,
            loadUser,
            isHeadAdmin
        }
    }

    function finishedUserCheck() {
        app.fillInitialData({ user: true })
    }

    const auth = {
        handleAuth,
    }

    return <AuthDataContext.Provider value={auth} {...props} />
}

export const useAuthDataContext = () => useContext(AuthDataContext)

export default AuthDataProvider
