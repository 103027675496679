import { useEffect } from "react"

//hooks
import useFetchAwaitingTasksAmount from "./useFetchAwaitingTasksAmount"
import useFetch from "hooks/useFetch"

//constants
import { ENDPOINTS } from "constants/endpoints"
import { SERVER_DATE_FORMAT } from "constants/formats"

//providers
import { useAppContext } from "providers/app"
import { useFormDataContext } from "../providers/form"
import { useScheduleDataContext } from "../providers/schedule"

//libraries
import moment from "moment-timezone"

//helpers
import { parseName } from "helpers/substring"

function useCreateEditTask(taskId, isOpened) {
    const app = useAppContext()
    const scheduleData = useScheduleDataContext()
    const formData = useFormDataContext()

    const { refetchData } = useFetchAwaitingTasksAmount()


    const { fetch, loading, validations, handleValidations } = useFetch()

    useEffect(() => {
        if (taskId && isOpened()) getTask()
    }, [isOpened()])

    function handleSave(e, handleClose) {
        if (loading) return
        handleValidations().clear()

        const data = new FormData(e.target)

        fetch({
            endpoint: `${ENDPOINTS.ADMIN[taskId ? "UPDATE_TASK" : "CREATE_TASK"]}`,
            method: "post",
            data
        })
            .then(() => {
                app
                    .handleMessage()
                    .success()
                    .show('Задачата беше създадена успешно!')
                handleClose()

                scheduleData.handleRefresh().refreshData()
                refetchData()
            })
    }

    function _formatDateAndTimeForClient(dateAndTime) {
        const formattedDateAndTime = moment(dateAndTime, `${SERVER_DATE_FORMAT} HH:mm`).format(
            `${SERVER_DATE_FORMAT}THH:mm`
        )

        return formattedDateAndTime
    }

    function _formatTimeForClient(time) {
        const formattedTime = moment(time, `${SERVER_DATE_FORMAT} HH:mm`).format('HH:mm')

        return formattedTime
    }

    function getTask() {
        if (loading) return
        fetch({ endpoint: `${ENDPOINTS.ADMIN.GET_TASK}/${taskId}`, method: 'get' })
            .then(res => {
                const { task, photos, audio_clips } = res.data
                const {
                    part_time_workers,
                    worker_task,
                    category,
                    service_id,
                    service_order,
                    description,
                    start,
                    end,
                    client,
                    location_id,
                    cost,
                    method_id
                } = task

                formData.setFieldsFormats('client', client.id)
                formData.setFieldsFormats('clientName', client.name)
                formData.setFieldsFormats('clientPhone', client.number)
                formData.setFieldsFormats('clientLocation', (client.locations.find(l => l.id === location_id) || {})?.location)
                formData.setFieldsFormats('clientLocationsList', client.locations)
                formData.setFieldsFormats('clientLocationId', location_id)
                formData.setFieldsFormats('clientEmail', client.email)
                formData.setFieldsFormats('description', description)
                formData.setFieldsFormats('sum', cost)
                formData.setFieldsFormats('paymentMethodId', method_id)

                let userIds = []
                let users = []

                worker_task.map(w => {
                    if (w.workers) {
                        userIds = [
                            ...userIds,
                            w.workers.id
                        ]
                        users = [
                            ...users,
                            {
                                ...w.workers,
                                shortName: parseName(w.workers.name)
                            }
                        ]
                    }
                })

                formData.setFieldsFormats('users', users, userIds)

                formData.setFieldsFormats('extraWorkers', part_time_workers)

                formData.setFieldsFormats('category', category.id)

                formData.setFieldsFormats('subcategory', service_id)

                formData.setFieldsFormats('start', start ? _formatDateAndTimeForClient(start) : null, start)
                formData.setFieldsFormats('end', end ? _formatDateAndTimeForClient(end) : null, end)

                formData.setFieldsFormats('startHHMM', start ? _formatTimeForClient(start) : null)
                formData.setFieldsFormats('endHHMM', end ? _formatTimeForClient(end) : null)

                let fields = {}
                service_order.map(s => {
                    fields = {
                        ...fields,
                        [s.service_note.id]: s.text
                    }
                })
                formData.setFieldsFormats("subcategoryFields", fields)

                photos.map(p => {
                    p['url'] = p.path
                    delete p.path

                    return p
                })
                formData.setFieldsFormats('images', photos)

                audio_clips.map(p => {
                    p['url'] = p.path
                    delete p.path

                    return p
                })
                formData.setFieldsFormats('records', audio_clips)
            })
    }

    return {
        loading,
        handleSave,
        validations,
        handleValidations
    }
}

export default useCreateEditTask