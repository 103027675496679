//components
import ClientFields from "./ClientFields"
import ClientsList from "./ClientsList"

function FormClientData({ handleValidations, viewFromHistory }) {
  return (
    <div className="tab client-data">
      <div className="row">
        <ClientFields
          handleValidations={handleValidations}
          viewFromHistory={viewFromHistory}
        />
        {!viewFromHistory ? <ClientsList /> : <></>}
      </div>
    </div>
  )
}

export default FormClientData
