import { useEffect } from "react"

//components
import Loader from "components/app/components/Loader"
import NoResultsFound from "components/app/components/NoResultsFound"

//hooks
import useFetchClients from "../hooks/useFetchClients"

//providers
import { useFormDataContext } from "../providers/form"

function ClientsList() {
  const formData = useFormDataContext()
  const selectedClient = formData.getFieldForClient("client")

  const {
    loading,
    hasMore,
    setCurrentPage,
    getClients,
    handleSearchByName,
    getPage,
  } = useFetchClients()

  useEffect(() => {
    handleSearchByName(formData.getFieldForClient("clientName"))
  }, [formData.getFieldForClient("clientName")])

  function handleScrollList(e) {
    if (!hasMore || loading) return

    const { target } = e
    if (
      (target.scrollHeight - target.clientHeight).toFixed(0) - 1 <=
      target.scrollTop.toFixed(0)
    ) {
      setCurrentPage(getPage() + 1)
    }
  }

  function selectClient(client) {
    let { email, locations, name, number, id } = client
    if (id === selectedClient) {
      email = ""
      name = ""
      number = ""
      id = ""
      locations = []
    }

    formData.setFieldsFormats("client", id)
    formData.setFieldsFormats("clientName", name)
    formData.setFieldsFormats("clientLocation", locations[0]?.location || '')
    formData.setFieldsFormats("clientLocationId", locations[0]?.id || null)
    formData.setFieldsFormats("clientLocationsList", locations)
    formData.setFieldsFormats("clientPhone", number)
    formData.setFieldsFormats("clientEmail", email)
  }

  return (
    <div className="col list-col">
      <b>Клиенти</b>
      {/* <input
        type="text"
        placeholder="Въведете име..."
        onChange={handleSearch}
      /> */}
      {getPage() === 1 && loading ? (
        <Loader />
      ) : getClients().length === 0 ? (
        <NoResultsFound text="Не бяха открити клиенти в списъка" />
      ) : (
        <ul onScroll={handleScrollList}>
          {getClients().map((c) => (
            <li
              onClick={() => {
                selectClient(c)
              }}
              className={selectedClient === c.id ? "active" : ""}
              key={`c-${c.id}`}
            >
              {c.name}
              <span>
                {c.number}
              </span>
            </li>
          ))}
          {loading ? (
            <li>
              <Loader noPadding={true} />
            </li>
          ) : (
            <></>
          )}
        </ul>
      )}
    </div>
  )
}

export default ClientsList
