import { useCallback, useState } from "react"

//libraries
import {
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Tooltip,
} from "@mui/material"

//helpers
import { substring } from "helpers/substring"

//providers
import { useScheduleDataContext } from "../providers/schedule"

//components
import Loader from "components/app/components/Loader"

function ListTypeFilter({ label, name, data, loading, pages }) {
  const scheduleData = useScheduleDataContext()
  const getCurrentFilterSelectedData = () =>
    scheduleData.handleFilters().get(name) || []

  const [hidden, setHidden] = useState(true)

  function handleChange(e) {
    const { value } = e.target

    if (getCurrentFilterSelectedData().includes(String(value))) {
      setData(
        name,
        getCurrentFilterSelectedData().filter((f) => f !== String(value))
      )
    } else {
      setData(name, [...getCurrentFilterSelectedData(), String(value)])
    }
  }

  function clearSelected() {
    setData(name, [])
  }

  function handleScrollList(e) {
    if (!pages?.hasMore || loading) return

    const { target } = e
    if (
      (target.scrollHeight - target.clientHeight).toFixed(0) - 1 <=
      target.scrollTop.toFixed(0)
    ) {
      pages?.setCurrentPage(pages?.getPage() + 1)
    }
  }

  const getSelectedItems = useCallback(() => {
    return data.filter((d) =>
      getCurrentFilterSelectedData().includes(String(d.id))
    )
  }, [getCurrentFilterSelectedData(), data])

  const getLabel = useCallback(
    (full = false) => {
      if (getCurrentFilterSelectedData().length) {
        let generatedLabel = ""

        getSelectedItems().map((i, index) => {
          generatedLabel += `${i.name}${
            index + 1 !== getSelectedItems().length ? "," : ""
          } `
        })

        if (!full) {
          if (generatedLabel.length > 20) {
            generatedLabel = substring(generatedLabel, 20)
          }
        }

        return generatedLabel
      }

      return label
    },
    [getCurrentFilterSelectedData(), data]
  )

  const renderList = useCallback(() => {
    if (hidden) {
      return <></>
    }

    return (
      <ClickAwayListener
        onClickAway={() => {
          setHidden(true)
        }}
      >
        <div className={`dropdown ${!data.length && loading ? "loading" : ""}`}>
          <ul onScroll={handleScrollList}>
            {data.map((d) => (
              <li key={`d-${d.id}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={d.id}
                      checked={getCurrentFilterSelectedData().includes(
                        String(d.id)
                      )}
                    />
                  }
                  onChange={handleChange}
                  label={d.name}
                />
              </li>
            ))}
            {data.length && loading ? (
              <li>
                <Loader noPadding={true} />
              </li>
            ) : (
              <></>
            )}
          </ul>
          <div className="row footer">
            <button
              className="clear"
              onClick={clearSelected}
              disabled={!getSelectedItems().length}
            >
              Изчистване на филтъра
            </button>
          </div>
        </div>
      </ClickAwayListener>
    )
  }, [hidden, getCurrentFilterSelectedData(), data, loading])

  function setData(key, value) {
    scheduleData.handleFilters().set(key, value)
  }

  return (
    <div className="list-filter">
      <Tooltip
        title={
          getCurrentFilterSelectedData().length
            ? `Избрани филтри: ${getLabel(true).toUpperCase()}`
            : `Филтриране на разултатите по: ${label.toUpperCase()}`
        }
      >
        <div
          className={`label ${
            getCurrentFilterSelectedData().length ? "with-items" : ""
          }
          ${loading && getCurrentFilterSelectedData().length ? "loading" : ""}
          `}
          onClick={() => {
            setHidden(false)
          }}
        >
          {getLabel()}
        </div>
      </Tooltip>
      {renderList()}
    </div>
  )
}

export default ListTypeFilter
