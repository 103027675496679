const REQUIRED_CLIENT_FIELDS = [
    // "client",
    "clientName",
    "clientPhone",
    "clientLocation",
]
const REQUIRED_TASK_FIELDS = [
    "category", "subcategory"
]
const requiredFields = [...REQUIRED_CLIENT_FIELDS, ...REQUIRED_TASK_FIELDS]

const REQUIRED_CLIENT_FIELDS_SERVER = [
    'client_id',
    'name',
    'number',
    'location'
]
const REQUIRED_TASK_FIELDS_SERVER = [
    'end',
    'start',
    'date',
    "category_id", "service_id", "user_id"
]

function useRequiredFields() {

    function getValidationsForTab(validationKeys = []) {
        let validationsForTabIndex = 1

        if (validationKeys.includes('description')) {
            validationsForTabIndex = 0
        }

        for (let i = 0; i < REQUIRED_TASK_FIELDS_SERVER.length; i++) {
            if (validationKeys.includes(REQUIRED_TASK_FIELDS_SERVER[i])) {
                validationsForTabIndex = 0
            }
        }

        if (validationsForTabIndex === 0) {
            return validationsForTabIndex
        }

        for (let i = 0; i < REQUIRED_CLIENT_FIELDS_SERVER.length; i++) {
            if (validationKeys.includes(REQUIRED_CLIENT_FIELDS_SERVER[i])) {
                validationsForTabIndex = 1
            }
        }

        return validationsForTabIndex
    }

    return {
        requiredFields,
        getValidationsForTab
    }
}

export default useRequiredFields