import { useEffect, useState } from "react"

//constants
import { ENDPOINTS } from "constants/endpoints"

//hooks
import useFetch from "hooks/useFetch"

//helpers
import { getGETParam, getUrlData } from "helpers/url"

//providers
import { useFinanceDataContext } from "../providers/finances"

function useFetchWorkersTransactions() {
    const { fetch, loading } = useFetch()

    const financeProvider = useFinanceDataContext()

    const [transactions, setTransactions] = useState({})

    useEffect(() => {
        _fetchData(getGETParam(financeProvider.filters))
    }, [financeProvider.handleRefresh().getRefreshed()])

    function _fetchData(params) {
        //const params = `?worker=0&task_type=0&start_date=${startDate}&end_date=${endDate || 0}`

        if (!params) return

        fetch({
            endpoint: `${ENDPOINTS.ADMIN.GET_FINANCE_TRANSACTIONS}${params}`,
            method: 'get'
        })
            .then(res => {
                setTransactions(res.data)
            })
    }

    function getTransactions() {
        return transactions
    }

    function refetchData() {
        _fetchData()
    }

    return {
        getTransactions,
        loading,
        refetchData,
    }
}

export default useFetchWorkersTransactions