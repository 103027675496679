import { forwardRef, useImperativeHandle } from "react"
import { useNavigate } from "react-router-dom"

//hooks
import useOpenWindow from "hooks/useOpenWindow"
import useIsLoading from "hooks/useIsLoading"

//images
import removeIcon from "assets/images/app/icons/remove.svg"

//helpers
import { getUrlExeptPassedParam } from "helpers/url"

function RemoveModal({ accept, elementsContent, closeEditWhenFinish }, ref) {
  const { setLoading, loading } = useIsLoading()
  const { buttons, heading, text } = {
    buttons: {
      cancel: null,
      accept: null,
    },
    heading: null,
    text: null,
    ...elementsContent,
  }

  const { handleClose, handleOpen, isOpened } = useOpenWindow()

  const navigate = useNavigate()

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: () => {
      closeWindow()
      setLoading(false)
      if (closeEditWhenFinish) {
        navigate({
          search: getUrlExeptPassedParam("edit"),
        })
      }
    },
  }))

  function handleAccept() {
    setLoading(true)
    accept()
  }

  function closeWindow() {
    handleClose()
    navigate({
      search: getUrlExeptPassedParam("remove"),
    })
  }

  return (
    <div className={`overlay remove-overlay ${isOpened() ? "show" : ""}`}>
      <div className="modal">
        <div className="body">
          <img src={removeIcon} className="main" alt="" />
          <b>{heading || "Изтриване"}</b>
          <p>{text || "Предприетите от Вас действия може да са необратими."}</p>
        </div>
        <div className="buttons">
          <button className="btn-secondary" onClick={closeWindow}>
            {buttons.cancel || "Отказ"}
          </button>
          <button
            onClick={handleAccept}
            className={`btn-primary ${loading ? "loading" : ""}`}
          >
            {buttons.accept || "Изтриване"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(RemoveModal)
