//providers
import { useSocketContext } from 'providers/socket'

function notification(listener, channel, type, onEvent) {
    const instance = listener.echo.private(channel)
    const callback = payload => {
        if (type) {
            if (payload.type === type) onEvent(payload)
        } else onEvent(payload)
    }

    instance.notification(callback)

    return function cleanUp() {
        instance.stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', callback)
    }
}

let connected = false
export function useSocketNotification({ channel, channelId, type = null, callback }) {
    const socket = useSocketContext()
   
    const { listener, channels } = socket
    const channelName = channel + '.' + channelId

    if (!socket?.listener || connected) return
    if (typeof channelId === 'undefined') return

    connected = true
    return notification(listener, channelName, type, callback)
}