//libraries
import { Tooltip } from "@mui/material"

//images
import calendarIcon from "assets/images/schedule/icons/calendar.svg"

//providers
import { useScheduleDataContext } from "../providers/schedule"

function DailyPeriod() {
  const scheduleData = useScheduleDataContext()
  const sh = scheduleData.handlePeriods()

  return (
    <div className="day row">
      <div className="indicator">
        <b>{sh.getMonthDay().dayAsWord}</b>
        <span>{sh.getMonthDay().dayAsDate}</span>
      </div>
      <div className="right">
        <div className="top row">
          <div className="year">
            <Tooltip title="Предишна година">
              <button
                className="prev"
                onClick={sh.changeYear().toPrev}
              ></button>
            </Tooltip>
            <span>{sh.getActiveYear()} г.</span>
            <Tooltip title="Следваща година">
              <button
                className="next"
                onClick={sh.changeYear().toNext}
              ></button>
            </Tooltip>
          </div>

          <ul className="months">
            {sh.getMonthsList().map((m) => (
              <li
                key={`month-${m.monthAsNumber}`}
                className={
                  m.monthAsNumber === sh.getActiveMonthInDailyCalendar()
                    ? "active"
                    : ""
                }
                onClick={() => {
                  sh.setActiveMonthInDailyCalendar(m.monthAsNumber)
                }}
              >
                {m.monthAsWord}
              </li>
            ))}
          </ul>
          <Tooltip title="Смяна на типа филтър">
            <button
              className={`change-filter row ${
                scheduleData.loading ? "disabled" : ""
              }`}
              onClick={() => {
                if (scheduleData.loading) return

                sh.setCalendarType("weekly")
              }}
            >
              <img src={calendarIcon} alt="" />
              Един ден
            </button>
          </Tooltip>
        </div>
        <ul className="bottom days">
          {sh
            .getMonthsObj()
            [sh.getActiveMonthInDailyCalendar()].days.map((d) => (
              <li
                className={
                  d.dayOfYear === sh.getActiveDayInDailyCalendar()
                    ? "active"
                    : ""
                }
                onClick={() => {
                  sh.setActiveDayInDailyCalendar(d.dayOfYear)
                }}
                key={`day-${d.day}`}
              >
                {d.day}
                <br />
                <span>{d.dayOfWeekShorten}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default DailyPeriod
